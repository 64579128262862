import {Component, OnInit} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
import * as events from "events";

@Component({
  selector: 'app-generic-description-list',
  templateUrl: './generic-description-list.component.html',
  styleUrls: ['./generic-description-list.component.css']
})
//getDescriptions,
export class GenericDescriptionListComponent implements OnInit {
  nbDescriptions=0;
  firstPage=0;
  nextPage=0;
  lastPage=0;
  page: number = 1;
  oclub: any = [];
  genericDescriptionList: any = [];
  listOfCountry :any = [];
  listOfCityForCountry :any = [];
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  currentPage=1;
  ElementId:number =0;
  maxItem=Constantes.maxItemsPerPage;
  constructor(private globalService: GlobalService,private notificationService :NotificationService) {
  }
  ngOnInit(): void
  {
    this.getAllGenericDescription();
    this.getOclubList();
    this.getCountry();
  }
  getAllGenericDescription()
  {
    this.globalService.showSpinner();
    this.globalService.getDescriptions(null,null,'generic_description').subscribe(
      genericDescriptionList => {
        this.genericDescriptionList = genericDescriptionList[0]['data'];
        this.nbDescriptions=0;
        this.lastPage=0;
        this.nbDescriptions =genericDescriptionList[0]['info'][0]['totalItems'];
        this.lastPage=Math.ceil(this.nbDescriptions/this.maxItem);
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  displayFn: any;
  getCountry() {
    this.globalService.getCountry()
      .subscribe(
        Localities => { console.log('==> Country ==>  ',Localities);
          for (let countryItem in Localities[0]['data'])
          {
            if (Localities[0]['data'][countryItem]['type']['code']== 'country')
            {
              this.listOfCountry.push({'id':Localities[0]['data'][countryItem]['code'],'text':Localities[0]['data'][countryItem]['nameFr']+' ('+Localities[0]['data'][countryItem]['code']+')'});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getCityOfCountry(mainParentCode:any)
  {
    $('.hideThis').show();
    $('#citys').prop("disabled", false);
    this.globalService.getCityOfCountryByCode(mainParentCode)
      .subscribe(
        CityOfCountry => {
          this.listOfCityForCountry=[];
          for (let cityItem in CityOfCountry[0]['data'])
          {
            if (CityOfCountry[0]['data'][cityItem]['type']['code']== 'city')
            {
              this.listOfCityForCountry.push({'id':CityOfCountry[0]['data'][cityItem]['code'],'text':CityOfCountry[0]['data'][cityItem]['nameFr']});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getOclubList() {
    this.globalService.getParameterValue('oclub_group', '')
      .subscribe(
        oclub => {
          for (let item in oclub[0]['data']) {
            this.oclub.push(oclub[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  searchDescriptions(form: NgForm,page=1)
  {
    this.globalService.showSpinner();
    let locality='';
    let countrys=form.value.countrys;
    let citys=form.value.citys;
    console.log('citys = ',citys)
    console.log('countrys = ',countrys)
    if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length>0))
    {
      locality=citys;
    }
    else if ((countrys !== null && countrys.length>0) && (citys !== null && citys.length==0) )
    {
      locality=countrys;
    }

    else if ((countrys !== null && countrys.length>0) && (citys == null) )
    {
      locality=countrys;
    }
    else
    {
      locality='';
    }

  console.log('selected locality for search= ',locality)
    let oclub=form.value.oclub;
    let titre=form.value.titre;
    let type='generic_description';
    this.genericDescriptionList = [];
    this.globalService.getDescriptions(null,null,type,null,oclub,titre,true,locality).subscribe(
      searchResponse =>
      { console.log('searchResponse => ', searchResponse);
        this.genericDescriptionList=searchResponse[0]['data'];
        this.lastPage=0;
        this.nbDescriptions=0;
        this.nbDescriptions =searchResponse[0]['info'][0]['totalItems'];
        this.lastPage=Math.ceil(this.nbDescriptions/Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.genericDescriptionList.slice();
    if (!sort.active || sort.direction === '') {
      this.genericDescriptionList= data;
      return;
    }

    this.genericDescriptionList= data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'titleFr':
          return this.compare(a.titleFr, b.titleFr, isAsc);
        case 'objectIdentity':
          return this.compare(a.objectIdentity, b.objectIdentity, isAsc);
        case 'textFr':
          return this.compare(a.textFr, b.textFr, isAsc);
        case 'createdAt':
          return this.compare(a.createdAt, b.createdAt, isAsc);
        case 'oclub':
          return this.compare(a.oclub, b.oclub, isAsc);
        default:
          return 0;
      }
    });
  }
  pagesCounter(i: number) {
    return new Array(i);
  }
  loadNextPage(form: NgForm,pageNumber:number)
  {
    this.currentPage=pageNumber;
    if (pageNumber<=this.lastPage && pageNumber >0)
    {
      this.searchDescriptions(form,pageNumber);
    }
  }
  Delete(item:any)
  {
    let globalService=this.globalService;
    let genericDescriptionList=this.genericDescriptionList;
    let notification=this.notificationService;
    this.notificationService.ConfirmAction("Êtes-vous sûr de vouloir supprimer",item.titleFr,"Valider","Supprimer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        globalService.deleteDetail('generic_details',item.id).subscribe(
          deletedResponse => {
            let index = genericDescriptionList.indexOf(item);
            if (index > -1)
            {
              genericDescriptionList.splice(index, 1);
              notification.showSuccess("Le détail ' "+item.titleFr+" ' a été supprimé avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            globalService.checkApiError(error);
            notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
}


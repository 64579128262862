<section id="login">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img" style="padding: 0;">
            <div class="row m-0">
              <div class="col-lg-12 auth-img-bg">
                <img  src="../assets/img/logo.png" alt="Thalasso n°1" class="imgTopLogo">
              </div>
              <div class="col-lg-12 col-12 px-4 py-3">
                <div class="col-lg-12 col-12 px-4 py-3 linkCss"><a href="{{MidOfficeLink}}">Mid-office</a></div>
                <div class="col-lg-12 col-12 px-4 py-3 linkCss"><a href="/">Edito</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->

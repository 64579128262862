<div class="row">
  <div class="col-lg-2 blocCanal">
    <label class="form-label" for="channels"><b>Canal</b></label>
    <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
    <select ngModel *ngIf="channels?.length != 0" (ngModelChange)="getPackagesAllTypeOfDetails();"  [(ngModel)]="defaultChannel" aria-label="Floating label select example" class="form-control" id="channels" name="channels">
      <option *ngFor="let channel of channels" class="textCapitalise" [attr.code]="channel.code" value="{{channel.id}}">{{channel.nameFr}}</option>
    </select>
  </div>
  <div class="col-lg-1 blocImportedDetails">
    <button class="btn btn-primary waves-effect btn-label waves-light float-end mr-2 float-right importedProductCode zIndex99" type="button" (click)="genererPDF()">
      <i class="fa-light fa-file-pdf mr-1 iconeDetails"></i>PDF
    </button>
  </div>
  <div class="col-lg-4 blocImportedDetails">
    <input class="form-control importedProductCode" id="importedProductCode" placeholder="Code forfait" name="importedProductCode" type="text" value="">
    <button class="btn btn-primary waves-effect btn-label waves-light float-end mr-2 float-right importedProductCode zIndex99" type="button" (click)="importDetailFromOtherProduct()">
      <i class="fa-light fa-file-import mr-1 iconeDetails"></i>Importer description
    </button>
  </div>
</div>
<div class="row">
  <div class="col-xl-12">
    <div class="card" style="padding-bottom: 44px;">
      <div class="card-header">
        <h4>&nbsp;</h4>
        <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right AddPackage" routerLink="/description/new/package/{{packageId}}/{{channelId}}" type="button">
          <i class="ft-plus mr-1"></i>Nouveau descriptif
        </button>
      </div>
      <div class="row" style="margin: -52px 0px 8px 13px;" *ngIf="loadingProductsDesciptions">
        <img style="height: 23px; top: 23px; width: 23px;" src="../assets/img/load.gif" >
      </div>
      <div class="panel-group" id="accordion">
      <div class="card-body PackageDescriptionTable" *ngIf="productDescriptions?.length != 0">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title accordion-opened1">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                <span class="badge bg-info boxShadow2 collapseTitle">{{productDescriptions.length}} descriptif<span *ngIf="productDescriptions?.length >1">s</span> lié<span *ngIf="productDescriptions?.length >1">s</span><span *ngIf="PackageProductsID.length==1"> à</span> <span *ngIf="PackageProductsID.length>1"> aux</span> {{PackageProductsID.length}} hôtel<span *ngIf="PackageProductsID.length >1">s</span></span></a>
            </h4>
          </div>
          <div id="collapse1" class="panel-collapse collapse show in">
            <div class="panel-body"><table class="table  mb-0">
              <thead class="table-light">
              <tr>
                <th class="col-xl-1" style="width: 15% !important;">Famille</th>
                <th class="col-xl-3" style="width: 15% !important;">Titre</th>
                <th class="col-xl-8" style="width: auto !important;">Description</th>
                <th class="col-xl-1"  style="width: 5% !important;">Ordre</th>
                <!---th class="col-xl-1"  style="width: 10% !important;">Actions</th--->
              </tr>
              </thead>
              <tbody class="packageProductsOwner">
              <tr *ngFor="let description of productDescriptions">
                <td style="width: 15% !important;">
                  {{description?.type_name_fr && description.type_name_fr}}
                </td>
                <td>
                  {{description.title_fr}}
                </td>
                <td>
                  <div class="bodyDetail" [innerHTML]="description.text_fr|safeHtml"></div>
                </td>
                <td align="center"><span class="ordre">{{description.rank}}</span></td>
                <!---td>
                  <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(description.id);" data-toggle="modal" data-target="#editLogModal"></i>
                </td--->
              </tr>
              </tbody>
            </table></div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="packageDescriptions?.length != 0" style="height: 15px; margin-top: 3px;">&nbsp;</div>
      <div class="card-body PackageDescriptionTable" *ngIf="packageDescriptions?.length != 0">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title accordion-opened">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                <span class="badge bg-light boxShadow2 collapseTitle" style="color: #000;">{{packageDescriptions.length}} descriptif<span *ngIf="packageDescriptions?.length >1">s</span> du forfait</span></a>
            </h4>
          </div>
          <div id="collapse2" class="panel-collapse collapse">
            <div class="panel-body"><table class="table table-striped mb-0">
              <thead class="table-light">
              <tr>
                <th class="col-xl-1" style="width: 15% !important;">Famille</th>
                <th class="col-xl-2" style="width: 15% !important;">Titre</th>
                <th class="col-xl-8" style="width: auto !important;">Description</th>
                <th class="col-xl-1"  style="width: 5% !important;">Ordre</th>
                <th class="col-xl-1"  style="width: 10% !important;">Actions</th>
              </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="GetNewItemListAfterSortWithDragDrop($event)">
              <tr *ngFor="let description of packageDescriptions" cdkDrag>
                <td style="width: 15% !important;">
                  {{description?.type_name_fr && description.type_name_fr}}
                </td>
                <td>
                  {{description.title_fr}}
                </td>
                <td>
                  <div [innerHTML]="description.text_fr|safeHtml"></div>
                </td>
                <td align="center"><span class="ordre">{{description.rank}}</span></td>
                <td>
                  <a routerLink="/description/edit/package/{{description.id}}/{{channelId}}/{{packageId}}"><i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin-right:10px; " title="Modifier"></i></a>
                  <i class="ft-x ml-1 cursor-pointer ft-delete-2 btnActionDelete" (click)="Delete(description);" data-descriptif="4"  style="color:red;" title="Supprimer"></i>
                  <!--- class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(description.id);" data-toggle="modal" data-target="#editLogModal"></i---->
                </td>
              </tr>
              </tbody>
            </table></div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="ProductGenericDescriptions?.length != 0" style="height: 15px; margin-top: 3px;">&nbsp;</div>
      <div class="card-body PackageDescriptionTable" *ngIf="ProductGenericDescriptions?.length != 0">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4 class="panel-title accordion-opened">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                <span class="badge bg-info boxShadow2 packageGenericOwner collapseTitle">{{ProductGenericDescriptions.length}} descriptif<span *ngIf="ProductGenericDescriptions?.length >1">s</span> générique<span *ngIf="ProductGenericDescriptions?.length >1">s</span></span>
              </a>
            </h4>
          </div>
          <div id="collapse3" class="panel-collapse collapse">
            <div class="panel-body">
              <table class="table mb-0">
              <thead class="table-light">
              <tr>
                <th class="col-xl-1" style="width: 15% !important;">Localité</th>
                <th class="col-xl-2" style="width: 15% !important;">Titre</th>
                <th class="col-xl-8" style="width: auto !important;">Description</th>
                <th class="col-xl-1"  style="width: 5% !important;">Ordre</th>
                <th class="col-xl-1"  style="width: 10% !important;">Actions</th>
              </tr>
              </thead>
              <tbody class="packageGenericOwner">
              <tr *ngFor="let description of ProductGenericDescriptions">
                <td style="width: 15% !important;">
                  {{description.locality_name_fr}}
                </td>
                <td>
                  {{description.title_fr}}
                </td>
                <td>
                  <div [innerHTML]="description.text_fr|safeHtml"></div>
                </td>
                <td align="center"><span class="ordre">{{description.rank}}</span></td>
                <td>
                  <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(description.id);" data-toggle="modal" data-target="#editLogModal"></i>
                </td>
              </tr>
              </tbody>
            </table></div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal log -->
<div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <app-log-list [ElementId]="ElementId" [entityType]="'ProductDetail'"></app-log-list>
</div>
<div id="pdfTable" #pdfTable style="margin: auto; padding: 35px 20px 35px 20px ; width: 100%; overflow: hidden; height: 100%;">
    <h1 style="padding: 0px 0px 25px 0px ; text-align: center;color: #01BFC8; font-family: Arial, sans-serif !important; font-style: italic !important; font-weight: bold !important; text-decoration: none; font-size: 42px !important; height: max-content !important; width: 100%; display: block; overflow: hidden;  margin: auto !important; ">{{Package.nameFr}}</h1>
    <div *ngIf="productDescriptions?.length != 0" style="margin:0px;padding:0px; display: block;">
      <div class ="" *ngFor="let description of productDescriptions" style="margin:0px;padding:0px; display: block;">
        <h2 style="padding-top: 14px;text-indent: 0px;text-align: left;color: #FB9577; font-family: Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 23px; ">{{description.title_fr}}</h2>
        <p style="text-indent: 0px;line-height: inherit;color: black; font-family: Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 18px; margin: auto; padding: initial; width: 100%; display: block; overflow: hidden; height: max-content; text-align: justify;" [innerHTML]="description.text_fr|safeHtml"></p>

      </div>
    </div>
    <div *ngIf="packageDescriptions?.length != 0" style="margin:0px;padding:0px; display: block;">
      <div class ="" *ngFor="let description of packageDescriptions" style="margin:0px;padding:0px; display: block;">
        <h2 style="padding-top: 14px;text-indent: 0px;text-align: left;color: #FB9577; font-family: Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 23px; ">{{description.title_fr}}</h2>
        <p style="text-indent: 0px;line-height: inherit;color: black; font-family: Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 18px; margin: auto; padding: initial; width: 100%; display: block; overflow: hidden; height: max-content; text-align: justify;" [innerHTML]="description.text_fr|safeHtml"></p>

      </div>
    </div>
    <div *ngIf="ProductGenericDescriptions?.length != 0" style="margin:0px;padding:0px; display: block;">
      <div class ="" *ngFor="let description of ProductGenericDescriptions" style="margin:0px;padding:0px; display: block;">
        <h2 style="padding-top: 14px;text-indent: 0px;text-align: left;color: #FB9577; font-family: Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 23px; ">{{description.title_fr}}</h2>
        <p style="text-indent: 0px;line-height: inherit;color: black; font-family: Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 18px; margin: auto; padding: initial; width: 100%; display: block; overflow: hidden; height: max-content; text-align: justify;" [innerHTML]="description.text_fr|safeHtml"></p>
      </div>
    </div>
</div>

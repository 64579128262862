import {Component, OnInit} from '@angular/core';
import {Router, Event, NavigationEnd} from '@angular/router';
import {GlobalService} from "./shared/services/global.service";
import {ProductsService} from "./shared/services/products.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Mid_Tn1';
  currentRoute: string = '';
  token:any|null="";
  //tokenApi:any|null="";
  constructor(private router: Router,private productsService: ProductsService,private globalService: GlobalService)
  {}
  ngOnInit(): void
  {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd)
      {
        this.currentRoute = event.url;
        console.log('this.currentRoute => ',this.currentRoute);
      }
    });
    this.InitAppToken();
  }

  InitAppToken()
  {
    /*let urlParams = window.location.search;
    let searchParams = new URLSearchParams(urlParams);
    this.tokenApi=searchParams.get("token");
    if (searchParams.get("token") && this.tokenApi.length>0)
    {
      localStorage.setItem('token',this.tokenApi);
    }*/
    console.log('localStorage => ',localStorage);
    if (localStorage !== null)
    {
      this.globalService.initToken();
      this.productsService.initToken();
      this.setParamInLocalStorage();
    }
  }
  setParamInLocalStorage()
  {
   this.globalService.getParameterValue('','product_type',true)
      .subscribe(
        response => {
          for (let item in response[0]['data']) {
            localStorage.setItem(response[0]['data'][item]['code'], response[0]['data'][item]['id']);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
      this.globalService.getParameterValue('','entity_type',true)
        .subscribe(
          response => {
            for (let item in response[0]['data']) {
              localStorage.setItem(response[0]['data'][item]['code'], response[0]['data'][item]['id']);
            }
          },
          error => {
            this.globalService.checkApiError(error);
          }
        );
      this.globalService.getParameterValue('','',true,'generic_description')
        .subscribe(
          response => {
            for (let item in response[0]['data']) {
              localStorage.setItem(response[0]['data'][item]['code'], response[0]['data'][item]['id']);
            }
          },
          error => {
            this.globalService.checkApiError(error);
          }
        );
      this.globalService.getParameterValue('','language_type',true,'')
      .subscribe(
        response => {
          for (let item in response[0]['data']) {
            localStorage.setItem(response[0]['data'][item]['code'], response[0]['data'][item]['id']);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
      this.globalService.getParameterValue('','',true,'generic')
      .subscribe(
        response => {
          for (let item in response[0]['data']) {
            localStorage.setItem(response[0]['data'][item]['code'], response[0]['data'][item]['id']);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
 }

}

<div class="modal-dialog editParameterModalDialog" role="document">
  <div class="modal-content editParameterModalContent">
    <form  method="post" name="parameter" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);" *ngIf="parameterValue?.length != 0">
      <div class="modal-body seoCard">
        <!------------------------------------------------------------------------------------------------>
        <div class="card-body mt-0 pt-0">
          <div class="invoice-title mr-0 pr-0 mb-0">
            <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle" *ngIf="name.value?.length>0" [innerHTML]="name.value"></h4>
          </div>
          <div class="row mb-1">
            <div class="row mb-3">
              <!-- code -->
              <div class="col-lg-4 mb-2 pr-2">
                <label for="code">Code <span class="etoile">*</span></label>
                <input style="min-width: 255px;" [ngClass]="{'is-invalid' : !code.valid && !code.pristine}" class="form-control" id="code" maxlength="30" name="code" ngModel placeholder="Code" #code="ngModel" [(ngModel)]="(parameterValue && parameterValue.code)|| defaultCodeValue"  required minlength="3" type="text">
              </div>
              <!-- Nom -->
              <div class="col-lg-4 mb-2 pr-2">
                <label for="name">Nom <span class="etoile">*</span></label>
                <input style="min-width: 255px;" [ngClass]="{'is-invalid' : !name.valid && !name.pristine}" class="form-control" id="name" maxlength="100" name="name" ngModel placeholder="Nom" #name="ngModel" [(ngModel)]="(parameterValue && parameterValue.nameFr)|| defaultNameValue"  required minlength="3" type="text">
              </div>
              <!-- ordre -->
              <div class="col-lg-2 mb-2 pr-2">
                <label for="rank">Ordre</label>
                <input type="number" [ngClass]="{'is-invalid' : !rank.valid && !rank.pristine}"  class="form-control" name="rank" id="rank" #rank="ngModel" [(ngModel)]="parameterValue && parameterValue.rank" inputmode="numeric" pattern="\d*" [min]="1">
              </div>
              <!-- isActive -->
              <div class="col-lg-2 mb-2 pr-2">
                <br>
                <div class="custom-switch">
                  <input  type="checkbox" id="isActive" name="isActive" #isActive="ngModel" ngModel [(ngModel)]="parameterValue && parameterValue.isActive" class="custom-control-input form-control">
                  <label for="isActive" class="custom-control-label mr-1">
                    <span>Etat</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row" >
            <!-- Data1 -->
            <div class="col-lg-4 mb-2 pr-2">
              <label for="code">Data 1</label>
              <input  class="form-control" id="data1" maxlength="30" name="data1" ngModel placeholder="Data 1" #data1="ngModel" [(ngModel)]="(parameterValue && parameterValue.data1)|| defaultData1Value"   minlength="1" type="text">
            </div>
            <!-- Data2 -->
            <div class="col-lg-4 mb-2 pr-2" >
              <label for="code">Data 2</label>
              <input  class="form-control" id="data2" maxlength="30" name="data2" ngModel placeholder="Data 2" #data2="ngModel" [(ngModel)]="(parameterValue && parameterValue.data2)|| defaultData2Value"   minlength="1" type="text">
            </div>
            <!-- Data3 -->
            <div class="col-lg-4 mb-2 pr-2">
              <label for="code">Data 3</label>
              <input  class="form-control" id="data3" maxlength="30" name="data3" ngModel placeholder="Data 3" #data3="ngModel" [(ngModel)]="(parameterValue && parameterValue.data3)|| defaultData3Value"   minlength="1" type="text">
            </div>
            </div>
            <div class="row" >
              <!-- Canal : si detail produit = faille de détail -->
              <div *ngIf="parameterCode && parameterCode =='product_detail'" class="col-lg-4 mb-2 pr-2">
                <label class="form-label" for="channels">Canal</label>
                <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                <select ngModel *ngIf="channels?.length != 0" #channelID="ngModel" [(ngModel)]="(parameterValue?.parent && parameterValue.parent.id) && parameterValue.parent.id||defaultChannel" aria-label="Floating label select example" class="form-control" id="channels" name="channels">
                  <option *ngFor="let channel of channels" class="textCapitalise"  value="{{channel.id}}">{{channel.nameFr}}</option>
                </select>
              </div>
              <!-- type produit : si detail produit = faille de détail -->
              <div *ngIf="parameterCode && parameterCode =='product_subtype'" class="col-lg-4 mb-2 pr-2">
                <label class="form-label" for="channels">Type hôtel</label>
                <img *ngIf="productTypes?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                <select ngModel *ngIf="productTypes?.length != 0" #productTypeId="ngModel" [(ngModel)]="(parameterValue?.parent && parameterValue.parent.id) && parameterValue.parent.id||defaultChannel" aria-label="Floating label select example" class="form-control" id="productTypes" name="productTypes">
                  <option *ngFor="let item of productTypes" class="textCapitalise"  value="{{item.id}}">{{item.nameFr}}</option>
                </select>
              </div>
              <!-- choix parent  : si product_tag -->
              <div *ngIf="parameterCode && parameterCode =='product_tag'" class="col-lg-4 mb-2 pr-2">
                <label class="form-label" for="channels">Groupes</label>
                <img *ngIf="tagGroupe?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                <select ngModel *ngIf="tagGroupe?.length != 0" #tagGroupes="ngModel" [(ngModel)]="(parameterValue?.parent && parameterValue.parent.id) && parameterValue.parent.id||defaultChannel" aria-label="Floating label select example" class="form-control" id="tagGroupes" name="tagGroupes">
                  <option *ngFor="let item of tagGroupe" class="textCapitalise"  value="{{item.id}}">{{item.nameFr}}</option>
                </select>
              </div>
          </div>
          </div>
        </div>
        <!------------------------------------------------------------------------------------------------>
      </div>
      <div class="modal-footer pt-3 w-100">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Fermer</button>
        <button class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit" [disabled]="!AddEditForm.form.valid">
          <i class="ft-save"></i> Enregistrer
        </button>
      </div>
      <input type="hidden" name="id" id="id" ngModel #id="ngModel" [(ngModel)]="parameterValue && parameterValue.id">
    </form>
  </div>
</div>

    <div class="card seoCard">
    <!---h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">SEO</h4---->
      <form method="post" name="objectSeoForm" #objectSeoForm="ngForm" (ngSubmit)="onSubmit(objectSeoForm);">
      <div class="row">
        <div class="col-sm-2">
          <div class="mb-3">
            <label for="metasType">SEO<span class="etoile">*</span></label>
            <img *ngIf="seoType?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
            <select [ngClass]="{'is-invalid' : metasType.invalid  && (metasType.dirty || metasType.touched)}" #metasType="ngModel"  required  *ngIf="seoType?.length != 0" ngModel [(ngModel)]="seoId && productSeo.type.id && productSeo?.type.id"  aria-label="Floating label select example" class="form-control" id="metasType" name="metasType">
              <option value=""></option>
              <option *ngFor="let type of seoType" class="textCapitalise" value="{{type.id}}">{{type.nameFr}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="mb-3">
            <label for="text">Contenu SEO<span class="etoile">*</span></label>
            <input [ngClass]="{'is-invalid' : text.invalid  && (text.dirty || text.touched)}" #text="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  class="form-control" id="text"   name="text"  [(ngModel)]="productSeo.text && productSeo.text" >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="mb-3">
            <label>&nbsp;</label>
            <div>
              <button  class="btn btn-primary waves-effect btn-label waves-light modal-wait" type="submit" [disabled]="objectSeoForm.invalid" >
                <i class="ft-save"></i> Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
    <div class="card seoCard" *ngIf="productSeoList?.length != 0">
      <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle">Liste des Meta Data</h4>
    <div class="row">
      <div class="table-responsive" style="padding: 15px;">
        <table class="table table-bordered table-striped mb-0">
          <thead>
          <tr>
            <th style="width: 5% !important;">#</th>
            <th style="width: 12% !important;">Meta</th>
            <th>Meta Content</th>
            <th style="width: 13% !important;">Date de création</th>
            <th style="width: 13% !important;">Date de modification</th>
            <th style="width: 9% !important;">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let seo of productSeoList">
            <td style="width: 5% !important;" routerLink="/product/{{productId}}/{{productCode}}/seo/{{seo.id}}">{{seo.id}}</td>
            <td style="width: 12% !important;">{{seo.type.nameFr}}</td>
            <td>{{seo.text}}</td>
            <td style="width: 13% !important;">{{seo.createdAt| date :  "dd/MM/yyyy à HH:mm"}} </td>
            <td style="width: 13% !important;">{{seo.updatedAt| date :  "dd/MM/yyyy à HH:mm"}} </td>
            <td style="width: 9% !important;">
              <a routerLink="/product/{{productId}}/{{productCode}}/seo/{{seo.id}}" target="_parent">
                <i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin-right:10px; " title="Editer"></i>
              </a>
              <a style="cursor: pointer !important;">
                <i class="fas fa-trash-alt cursor-pointer" style="margin-right:10px; color:red;" (click)="Delete(seo,objectSeoForm);" title="Supprimer"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

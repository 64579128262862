import {Component, Input, OnChanges} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {ProductsService} from "../../shared/services/products.service";

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.css']
})
export class LogListComponent implements OnChanges {
  nbLogs = 0;
  firstPage = 0;
  nextPage = 0;
  lastPage = 0;
  page: number = 1;
  logsLists: any = [];
  log: any = [];
  filteredLogs: any;
  isLoading = false;
  errorMsg: string = '';
  value = 'resolve';
  allowClear = true;
  required = true;
  disabled = false;
  currentPage = 1;
  maxItem = Constantes.maxItemsPerPage;
  @Input() ElementId: number=0;
  @Input() entityType: string='';
  constructor(
    private globalService: GlobalService,
    private productsService : ProductsService) {}

  ngOnChanges (): void
  {
    if (this.ElementId && this.ElementId >0)
    {
      this.searchLogs(1);
    }
  }
  displayFn: any;
  searchLogs(page = 1) {
    this.globalService.showSpinner();
    this.logsLists = [];
    this.globalService.getLog(this.ElementId,page,this.entityType).subscribe(
      (searchResponse: any) =>
      {
        console.log('all log ' , searchResponse['data']);
        if (this.entityType && this.entityType.length > 0)
        {
          console.log('log entityType n est pas vide,on affiche tout le log de ', this.entityType);
          Object.keys(searchResponse['data']).forEach(key =>
          {
            if (searchResponse['data'][key]['entity_type'] == this.entityType)
            {
              this.logsLists.push(searchResponse['data'][key]);
            }
          });
        }
        else
        {
          console.log('log entityType vide , on affiche tout le log  ');
          this.logsLists = searchResponse['data'];
        }
        //entity_type
        console.log('this.logsLists =====> ', this.logsLists);
        this.lastPage = 0;
        this.nbLogs = 0;
        this.nbLogs = searchResponse['total']; console.log('this.nbLogs =', this.nbLogs);
        this.lastPage = Math.ceil(this.nbLogs / Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      (error: any) =>
      {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    const data = this.logsLists.slice();
    if (!sort.active || sort.direction === '') {
      this.logsLists = data;
      return;
    }

    this.logsLists = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'createdAt':
          return this.compare(a.createdAt, b.createdAt, isAsc);
        case 'updatedAt':
          return this.compare(a.updatedAt, b.updatedAt, isAsc);
        case 'active':
          return this.compare(a.isActive, b.isActive, isAsc);
        case 'master':
          return this.compare(a.isMaster, b.isMaster, isAsc);
        default:
          return 0;
      }
    });
  }

  pagesCounter(i: number) {
    return new Array(i);
  }

  loadNextPage(pageNumber: number) {
    this.currentPage = pageNumber;
    if (pageNumber <= this.lastPage && pageNumber > 0) {
      this.searchLogs(pageNumber);
    }
  }

  transformJson(str:any) {
    str = JSON.parse(str);
    let keys = [];
    for (let key in str) {
      keys.push({key: key, value: str[key]});
    }
    console.log('keys ==>',keys);
    return keys;
  }

  }


import {Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable,of} from "rxjs";
import {environment} from "../../../environments/environment";
import {map} from 'rxjs/operators';
import {GlobalService} from "./global.service";
import {Constantes} from "../constantes";
import {ProduitsManquantsComponent} from "../../produits-manquants/produits-manquants.component";
@Injectable({providedIn: 'root'})
export class ProductsService {
  requestOptions:any;
  AddHeaders:any
  constructor(private httpService: HttpClient, private globalService: GlobalService) {}
  initToken()
  {
    let apiToken=localStorage.getItem('token');
    if (apiToken && apiToken.length>0)
    {
      this.AddHeaders =new HttpHeaders({'Content-Type':'application/ld+json; charset=utf-8','accept': 'application/ld+json','api-token':apiToken});
    }
    else
    {
      this.AddHeaders =new HttpHeaders({'Content-Type':'application/ld+json; charset=utf-8','accept': 'application/ld+json','api-token':'0'});
    }
    this.requestOptions = {headers: this.AddHeaders};
  }
  getProduct(id: number): Observable<any> {
    return this.httpService.get<any>(environment.ApiUrl + '/products/'+id, this.requestOptions);
  }
  getAllProducts(type:string): Observable<any> {
    let requesUrl=environment.ApiUrl + '/products?page=1&pagination=true&type.code='+type;
    console.log('this.requestOptions ===>',this.requestOptions);
    return this.httpService.get<any>(requesUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  getPackageSegments(page: number|null=1,pagination:boolean|null=false, name: string, productID: number|null,locality: string|null,packageID: number|null,packageType:string|null,productType:string|null): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';

    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    if (packageID)
    {
      let cond2 = "&package.id="+packageID;
      Conditions = Conditions.concat(cond2);
    }
    if (name.length>0)
    {
      let cond2 = "&nameFr="+name;
      Conditions = Conditions.concat(cond2);
    }
    if (productID)
    {
      let cond2 = "&product.id="+productID;
      Conditions = Conditions.concat(cond2);
    }
    if (locality)
    {
      let cond2 = "&locality="+locality;
      Conditions = Conditions.concat(cond2);
    }
    if (packageType)
    {
      let cond2 = "&package.type.code="+packageType;
      Conditions = Conditions.concat(cond2);
    }
    if (productType)
    {
      let cond2 = "&product.type.code="+productType;
      Conditions = Conditions.concat(cond2);
    }


    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/package_segments?pagination=false'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  searchProducts(page: number,code: string, name: string, etat: string, locality: string, sub_type: string|null, type: string): Observable<any>
  {
  /************************* Construction de l'url aver les parameters de recherche *******************/
  let Conditions='';
    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    if (name.length>0)
    {
      let _name= name.replace("+", "%2B");
      console.log('name ===>',_name);
      let cond2 = "&nameFr="+_name;
      Conditions = Conditions.concat(cond2);
    }
    if (code.length>0)
    {
      let cond2 = "&code="+code;
      Conditions = Conditions.concat(cond2);
    }
    if (type.length>0)
    {
      let cond2 = "&type.code="+type;
      Conditions = Conditions.concat(cond2);
    }
    if((sub_type) &&(sub_type.length>0))
    {
      let cond2 = "&subtype.code="+sub_type;
      Conditions = Conditions.concat(cond2);
    }
    if (etat.length>0)
    {
      let cond2 = "&isActive="+etat;
      Conditions = Conditions.concat(cond2);
    }
    if (locality)
    {
      let cond2 = "&productLocalities.locality.code="+locality;
      Conditions = Conditions.concat(cond2);
    }


    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/products?pagination=true'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  searchPackages(page: number|null=1,pagination:boolean|null=false, packageNameFr: string,packageCode: string,productCode: string|null,locality: string|null,etat: string): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';

    if (pagination)
    {
      let cond2 = "&pagination="+pagination;
      Conditions = Conditions.concat(cond2);
    }
    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    if (packageNameFr.length>0)
    {
      let _name= packageNameFr.replace("+", "%2B");
      console.log('name ===>',_name);
      let cond2 = "&package_name="+_name;
      Conditions = Conditions.concat(cond2);
    }
    if (packageCode.length>2)
    {
      let cond2 = "&package_code="+packageCode;
      Conditions = Conditions.concat(cond2);
    }
    if (productCode)
    {
      let cond2 = "&hotel_code="+productCode;
      Conditions = Conditions.concat(cond2);
    }
    if (locality)
    {
      let cond2 = "&locality_code="+locality;
      Conditions = Conditions.concat(cond2);
    }
    if (etat.length>0)
    {
      let cond2 = "&etat="+etat;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/packages?type.code=package'+Conditions+'';
    console.log('requestUrl package search',requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {    console.log('responses package search',responses);
          return responses;
        })
      );
  }
  getProductLocalities(): Observable<any> {
    let requestUrl=environment.ApiUrl +'/products_localities?pagination=false&type_code=country';
    console.log(' requestUrl products_localities',requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses;
        })
      );
  }
  searchProductsName(name: string,type:string|null): Observable<any> {
    if (name.length <3) {
      return of([]);
    }
    let requestUrl=environment.ApiUrl + '/products?pagination=true&nameFr='+name+'&type.code='+type;
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(false, responses);
        })
      );
  }
  searchProductsCode(code: string,type:string|null): Observable<any> {
    if (code.length <3) {
      return of([]);
    }
    let requestUrl=environment.ApiUrl + '/products?pagination=true&code='+code+'&type.code='+type;
    console.log('search product par coe == ', requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(false, responses);
        })
      );
  }
  getProductSeoContentById(id:number): Observable<any>
  {
    let requestUrl=environment.ApiUrl +'/product_seo_contents/'+id+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses;
        })
      );
  }
  getProductSeoContentsById(id:number): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (id)
    {
      let cond2 = "&product.id="+id;
      Conditions = Conditions.concat(cond2);
    }

    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/product_seo_contents?language.code=fr&pagination=false'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  getProductMedia(id:number|undefined,channels:string|null): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (id)
    {
      let cond2 = "&product.id="+id;
      Conditions = Conditions.concat(cond2);
    }
    if (channels && channels.length>0)
    {
      console.log('api  product media==> channels ',channels);
      let cond2 = "&channel.code="+channels;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/product_media?pagination=false'+Conditions+'';
    console.log('requestUrl product media',requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {console.log('responses product media',responses);
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  getProductTags(id:number|null): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (id)
    {
      let cond2 = "&product.id="+id;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/product_tags?pagination=false'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.globalService.constructResponse(true, responses);
        })
      );
  }
  addEditProductSeoContent(text:string|null,typeId:number|null,productId:number|null,ID:number|null): Observable<any>
  {
    console.log('addEditProductSeoContent, id ==',ID);
    let bodyOfPost:any;
    let languageId=localStorage.getItem('fr');
      bodyOfPost =
        {
          "@type": "ProductSeoContent",
          "product": "/api/products/"+productId,
          "type":"/api/parameter_values/"+typeId,
          "text": text,
          "language":
            {
              "@id": "/api/parameter_values/"+languageId,
              "@type": "ParameterValue",
              "code": "fr"
            }
        }
    console.log('bodyOfPost',bodyOfPost);
    //update
    if (ID && !isNaN(ID) && ID >0)
    {
      return this.httpService.put<any>(environment.ApiUrl + '/product_seo_contents/'+ID,bodyOfPost, this.requestOptions);
    }
    // Add
    else
    {
      return this.httpService.post<any>(environment.ApiUrl + '/product_seo_contents',bodyOfPost, this.requestOptions);
    }

  }
  deleteProductSeoContent(Id:number|null): Observable<any>
  {
    console.log('url delete detail =',environment.ApiUrl + '/product_seo_contents/'+Id)
    return this.httpService.delete<any>(environment.ApiUrl + '/product_seo_contents/'+Id,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted api response ',responses);
          return responses;
        })
      );

  }
  addProductTag(typeId:number|null,productId:number|null): Observable<any>
  {
    console.log('typeId==>',typeId)
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@type": "ProductTag",
        "product": "/api/products/"+productId,
        "tag": "/api/parameter_values/"+typeId
      }
      console.log('bodyOfPost',bodyOfPost);
      console.log('url Add detail =',environment.ApiUrl + '/product_tags/')
      return this.httpService.post<any>(environment.ApiUrl + '/product_tags',bodyOfPost, this.requestOptions);
  }
  deleteProductTag(Id:number|null): Observable<any>
  {
    console.log('url delete detail =',environment.ApiUrl + '/product_tags/'+Id)
    return this.httpService.delete<any>(environment.ApiUrl + '/product_tags/'+Id,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted api response ',responses);
          return responses;
        })
      );

  }
  addEditProductMedia(base64:any|null,name:string|null,width:string|null,height:string|null,weight:string|null,rank:number|null,productId:number|undefined,isPanoramic:boolean|null,isGallery:boolean|null,isList:boolean|null,photoId:number|null,channelId:any|null,keywords:string|null): Observable<any>
  {
    console.log('add api photo :::: rank == ',rank);
      let bodyOfPost:any;
    //update
   if (photoId && !isNaN(photoId) && photoId >0)
    {
      bodyOfPost =
        {
          "product": productId,
          "isPanoramic": isPanoramic,
          "isGallery": isGallery,
          "channel" : "/api/parameter_values/"+channelId
        }
        if (isList)
        {
          bodyOfPost.isList=isList;
        }
       if (rank && rank>0)
        {
          bodyOfPost.rank=rank*1;
        }

      if (keywords && keywords.length>0)
      {
        bodyOfPost.keyWords=keywords;
      }
        console.log('bodyOfPost',bodyOfPost);
        console.log('url update product_media =',environment.ApiUrl + '/product_media/'+photoId)
        return this.httpService.put<any>(environment.ApiUrl + '/product_media/'+photoId,bodyOfPost, this.requestOptions);
      }
      // Add
      else
      {
        console.log('add api photo :::: keywords ============> ',keywords);
        bodyOfPost =
          {
            "product": productId,
            "isList": false,
            "isPanoramic": isPanoramic,
            "isGallery": isGallery,
            "isExternal": false,
            "channel" : channelId
          }
        if (weight)
        {
          bodyOfPost.weight=weight;
        }
        if (height)
        {
          bodyOfPost.height=height;
        }
        if (width)
        {
          bodyOfPost.width=width;
        }
        if (rank && rank>0)
        {
          bodyOfPost.rank=rank*1;
        }
        if (keywords && keywords.length>0)
        {
          console.log('bodyOfPost.keyWords ============> ',keywords);
          bodyOfPost.keyWords=keywords;
        }
        if (name)
        {
          bodyOfPost.mediaName=name.split(' ').join('-');
        }
        if (base64)
        {
          bodyOfPost.url=base64;
        }
        console.log('bodyOfPost',bodyOfPost);
        console.log('url Add product_media =',environment.ApiUrl + '/product_media/')
        return this.httpService.post<any>(environment.ApiUrl + '/product_media',bodyOfPost, this.requestOptions);
      }
  }
  deleteProductMedia(photoId:number|null): Observable<any>
  {
    let entityName='product_media';
    console.log('url delete detail =',environment.ApiUrl + '/'+entityName+'/'+photoId)
    return this.httpService.delete<any>(environment.ApiUrl + '/'+entityName+'/'+photoId,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted api response ',responses);
          return responses;
        })
      );

  }
  addEditProductGeneralities(nameFr:string|null,code:string,tripAdvisorCode:string|null,minAge:number|null,longitude:string|null,latitude:string|null,typeId:any|null,subTypeId:any|null,mealPlanId:any|null,productId:number|null,isActive:boolean|null,URLVideo:string|null,URLMap:string|null,localities:any|null=[],introduction:string|null,durationDays:number|null,durationNights:number|null): Observable<any>
  {
    console.log('localities =================> ',localities);
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/Product",
        "@type": "Product",
        "code": code.toUpperCase(),
        "type": "/api/parameter_values/"+typeId,
        "isActive": isActive,
        "nameFr": nameFr
      }
    if (subTypeId!=null && subTypeId>0)
    {
      bodyOfPost.subtype="/api/parameter_values/"+subTypeId;
    }
    if (mealPlanId!=null && mealPlanId>0)
    {
      bodyOfPost.mealPlan="/api/parameter_values/"+mealPlanId;
    }
    if (tripAdvisorCode!=null  && tripAdvisorCode.length>0)
    {
      bodyOfPost.tripAdvisorCode=tripAdvisorCode;
    }
    if (longitude!=null && longitude.length>0)
    {
      bodyOfPost.longitude=longitude;
    }
    if (latitude!=null && latitude.length>0)
    {
      bodyOfPost.latitude=latitude;
    }
    if (minAge!=null && minAge>0)
    {
      bodyOfPost.minAge=minAge;
    }

    if (URLVideo!=null && URLVideo.length>0)
    {
      bodyOfPost.videoUrl=URLVideo;
    }

    if (URLMap!=null && URLMap.length>0)
    {
      bodyOfPost.mapUrl=URLMap;
    }
    if (introduction!=null)
    {
      bodyOfPost.introduction=introduction;
    }
    if (durationDays!=null && durationDays>0)
    {
      bodyOfPost.durationDays=durationDays;
    }
    if (durationNights!=null && durationNights>0)
    {
      bodyOfPost.durationNights=durationNights;
    }
    //update
    if (productId && !isNaN(productId) && productId >0)
    {
      console.log('update Product/package Generalities  ::::::::  bodyOfPost',bodyOfPost);
      return this.httpService.put<any>(environment.ApiUrl + '/products/'+productId,bodyOfPost, this.requestOptions);
    }
    // Add
    else
    {
      let productLocalities:any=[];
      if(localities && localities.length>0)
      {
        for (let item in localities)
        {
          productLocalities.push({"locality":"/api/localities/"+localities[item]['id']});
        }
        bodyOfPost.productLocalities=productLocalities;
      }
      console.log('Add Product/package Generalities  ::::::::  bodyOfPost',bodyOfPost);
      return this.httpService.post<any>(environment.ApiUrl + '/products',bodyOfPost, this.requestOptions);
    }
  }
  addProductLocality(LocalityId:number|null,productId:number|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/ProductLocality",
        "@type": "ProductLocality",
        "locality": "/api/localities/"+LocalityId,
        "product": "/api/products/"+productId
      }
    console.log('bodyOfPost',bodyOfPost);
    console.log('url Add product_localities =',environment.ApiUrl + '/product_localities/')
    return this.httpService.post<any>(environment.ApiUrl + '/product_localities',bodyOfPost, this.requestOptions);
  }
  deleteProductLocality(productId:number|null): Observable<any>
  {
    console.log('url delete product_localities =',environment.ApiUrl + '/product/'+productId+'/localities')
    return this.httpService.delete<any>(environment.ApiUrl + '/product/'+productId+'/localities',this.requestOptions)
  }
  duplicateProduct(productId:number|null,code:string): Observable<any>
  {
    console.log('url duplicate=',environment.ApiUrl + '/product/'+productId+'/duplicate/'+code)
    return this.httpService.get<any>(environment.ApiUrl + '/product/'+productId+'/duplicate/'+code,this.requestOptions)
  }
  addPackageSegment(productId:number|null,packageId:number|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/PackageSegment",
        "@type": "PackageSegment",
        "package": "/api/products/"+packageId,
        "product": "/api/products/"+productId,
        "isActive": true
      }
    console.log('bodyOfPost package_segments',bodyOfPost);
    console.log('url Add package_segments =',environment.ApiUrl + '/package_segments/')
    return this.httpService.post<any>(environment.ApiUrl + '/package_segments',bodyOfPost, this.requestOptions);
  }
  deletePackageSegment(packageId:number|null): Observable<any>
  {
    console.log('url delete PackageSegment =',environment.ApiUrl + '/package/'+packageId+'/hotels')
    return this.httpService.delete<any>(environment.ApiUrl + '/package/'+packageId+'/hotels',this.requestOptions)
  }
  getPackagesAllTypeOfDetails(channelId:number|null,packageId:number|null): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (channelId && channelId>0)
    {
      let cond2 = "&channel_id="+channelId;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/package/'+packageId+'/details?pagination=false'+Conditions+'';
    console.log('requestUrl getPackagesAllTypeOfDetails',requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('responses getPackagesAllTypeOfDetails',responses);
          return responses;
        })
      );
  }
  EditProductArchived(productId:number|null,isArchived:boolean|null=true): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/Product",
        "@type": "Product",
        "isArchived": isArchived,
        "isActive": false
      }
    //update
      console.log('update Product Archivate  ::::::::  bodyOfPost',bodyOfPost);
      return this.httpService.put<any>(environment.ApiUrl + '/products/'+productId,bodyOfPost, this.requestOptions);
  }
  searchArchivedProductsName(name: string): Observable<any> {
    if (name.length <3) {
      return of([]);
    }
    let requestUrl=environment.ApiUrl + '/products_archived?pagination=true&nameFr='+name;
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return  responses;
        })
      );
  }
  searchArchivedProduct(page: number,code: string|null, name: string|null,type: string|null): Observable<any>
  {

    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    if (name && name.length>0)
    {
      let cond2 = "&product_name="+name;
      Conditions = Conditions.concat(cond2);
    }
    if (code && code.length>0)
    {
      let cond2 = "&product_code="+code;
      Conditions = Conditions.concat(cond2);
    }
    if (type && type.length>0)
    {
      let cond2 = "&product_type="+type;
      Conditions = Conditions.concat(cond2);
    }

    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/products_archived?pagination=true'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses;
        })
      );
  }
  editProductLocality(localities:any|null=[],productId:number|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@type": "ProductLocality",
        "localities": localities,
        "product": "/api/products/"+productId
      }
    console.log('bodyOfPost',bodyOfPost);
    console.log('url editProductLocalitys =',environment.ApiUrl + '/product/'+productId+'/localities')
    return this.httpService.put<any>(environment.ApiUrl + '/product/'+productId+'/localities',bodyOfPost, this.requestOptions);
  }

  editPackageSegment(localities:any|null=[],packageId:number|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@type": "ProductLocality",
        "accommodations": localities
      }
    console.log('bodyOfPost editPackageSegment',bodyOfPost);
    console.log('url editPackageSegment =',environment.ApiUrl + '/package/'+packageId+'/accommodations')
    return this.httpService.put<any>(environment.ApiUrl + '/package/'+packageId+'/accommodations',bodyOfPost, this.requestOptions);
  }
  updateProductMediasDetailRank(entity:string,verbe:string,ItemsUpdatedRankList:any|null=[])
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/" + entity,
        "@type": entity
      }
      if (verbe && verbe =='product_detail')
      {
        bodyOfPost.details=ItemsUpdatedRankList
      }
      if (verbe && verbe =='product_medias')
      {
        bodyOfPost.medias=ItemsUpdatedRankList

      }
    console.log('bodyOfPost editPackageSegment',bodyOfPost);
    console.log('url editPackageSegment =',environment.ApiUrl + '/'+verbe+'/update_rank')
    return this.httpService.post<any>(environment.ApiUrl + '/'+verbe+'/update_rank',bodyOfPost, this.requestOptions);
  }
  searchProductsCodeName(text: string,type:string|null): Observable<any>
  {
    //https://127.0.0.1:8000/api/products_autosuggest?search=FEV
    if (text.length <3) {
      return of([]);
    }
    let requestUrl=environment.ApiUrl + '/products_autosuggest?search='+text+'&type.code='+type;
    //console.log('search Products by Code or Name== ', requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses['data'];
        })
      );
  }
  getProduitsManquants(page: number): Observable<any>
  {
    let Conditions='';
    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    let requestUrl=environment.ApiUrl + '/bo/product_package/get_new';
    console.log('getProduitsManquants == ', requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses;
        })
      );
  }
  importerProduitManquant(productCode:string|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/Product",
        "@type": "Product",
        "code": productCode
      }
    console.log('bodyOfPost importerProduitManquant',bodyOfPost);
    console.log('url Add importerProduitManquant =',environment.ApiUrl + '/bo/import_product')
    return this.httpService.post<any>(environment.ApiUrl + '/bo/import_product',bodyOfPost, this.requestOptions);
  }
  importerPackageManquant(packageCode:string|null): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@context": "/api/contexts/Product",
        "@type": "Product",
        "code": packageCode
      }
    console.log('bodyOfPost importerPackageManquant',bodyOfPost);
    console.log('url Add importerPackageManquant =',environment.ApiUrl + '/bo/import_package')
    return this.httpService.post<any>(environment.ApiUrl + '/bo/import_package',bodyOfPost, this.requestOptions);
  }

  getCountProduitsManquants(type:string|null): Observable<any>
  {
    let Conditions='';
    if (type)
    {
      let cond2 = "&type.code="+type;
      Conditions = Conditions.concat(cond2);
    }
    let requestUrl=environment.ApiUrl + '/new_products?page=1'+Conditions+'';
    console.log('getCountProduitsManquants == ', requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {console.log('count = ====',responses);
          return this.globalService.constructResponse(true, responses);
        })
      );
  }

}

<div class="modal-dialog editParameterModalDialog" role="document">
  <div class="modal-content editParameterModalContent">
    <form  method="post" name="parameter" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);" *ngIf="parameter?.length != 0">
      <div class="modal-body seoCard">
        <!------------------------------------------------------------------------------------------------>
        <div class="card-body mt-0 pt-0">
          <div class="invoice-title mr-0 pr-0 mb-0">
              <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle" *ngIf="name.value?.length>0" [innerHTML]="name.value"></h4>
          </div>
          <div class="row">
            <!-- code -->
            <div class="col-lg-4 mb-0 pr-4">
                <label for="code">Code <span class="etoile">*</span></label>
                <input style="min-width: 255px;" [ngClass]="{'is-invalid' : !code.valid && !code.pristine}" class="form-control" id="code" maxlength="30" name="code" ngModel placeholder="Code" #code="ngModel" [(ngModel)]="(parameter && parameter.code)|| defaultCodeValue"  required minlength="3" type="text">
            </div>
            <!-- Nom -->
            <div class="col-lg-4 mb-0 pr-4">
                <label for="name">Nom <span class="etoile">*</span></label>
                <input style="min-width: 255px;" [ngClass]="{'is-invalid' : !name.valid && !name.pristine}" class="form-control" id="name" maxlength="100" name="name" ngModel placeholder="Nom" #name="ngModel" [(ngModel)]="(parameter && parameter.name)|| defaultNameValue"  required minlength="3" type="text">
            </div>
            <!-- isActive -->
            <div class="col-lg-2 mb-0 pr-4">
              <br>
              <div class="custom-switch">
                <input  type="checkbox" id="isActive" name="isActive" #isActive="ngModel" ngModel [(ngModel)]="parameter && parameter.isActive" class="custom-control-input form-control">
                <label for="isActive" class="custom-control-label mr-1">
                  <span>Etat</span>
                </label>
              </div>
            </div>
            <!-- isMaster -->
            <div class="col-lg-2  mb-0 pr-4">
              <br>
              <div class="custom-switch">
                <input  type="checkbox" id="isMaster" name="isMaster" #isMaster="ngModel" ngModel  [(ngModel)]="parameter && parameter.isMaster" class="custom-control-input form-control">
                <label for="isMaster" class="custom-control-label mr-1">
                  <span>Master</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!------------------------------------------------------------------------------------------------>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Fermer</button>
        <button class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit" [disabled]="!AddEditForm.form.valid">
          <i class="ft-save"></i> Enregistrer
        </button>
      </div>
      <input type="hidden" name="id" id="id" ngModel #id="ngModel" [(ngModel)]="parameter && parameter.id">
    </form>
  </div>
</div>

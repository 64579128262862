import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value:any) {
    let response=value.toString().trim();
        response=response.replace("\\r\\n", '');
    return this.sanitized.bypassSecurityTrustHtml(response);
  }
}

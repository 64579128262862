import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {map} from 'rxjs/operators';
import {Constantes} from "../constantes";
import {NgxSpinnerService } from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
@Injectable({ providedIn: 'root'})
export class GlobalService
  {
  requestOptions:any;
  AddHeaders:any
  // Upload photo
  maxFileSize=Constantes.maxFileSize;
  src:string='';
  imgSrc:string='';
  href: string = "";
  NewAddPhotos: any = [];
  // Upload photo
  constructor(
    private httpService: HttpClient,
    private spinner: NgxSpinnerService,
    private location: Location,
    private router:Router,
    // Upload photo
    private sanitizer: DomSanitizer,
  )
  {}
  initToken()
  {
      let apiToken=localStorage.getItem('token');
      if (apiToken && apiToken.length>0)
      {
        this.AddHeaders =new HttpHeaders({'Content-Type':'application/ld+json; charset=utf-8','accept': 'application/ld+json','api-token':apiToken});
      }
      else
      {
        this.AddHeaders =new HttpHeaders({'Content-Type':'application/ld+json; charset=utf-8','accept': 'application/ld+json','api-token':'0'});
      }
      this.requestOptions = {headers: this.AddHeaders};
  }
  getCountry(): Observable<any> {
    return this.httpService.get<any>(environment.ApiUrl + '/localities?pagination=false&type.code=country', this.requestOptions)
      .pipe(
        map((responses) => {
          return this.constructResponse(false, responses);
        })
      );
  }
    getCityOfCountryByCode(mainParentCode:any): Observable<any> {
      return this.httpService.get<any>(environment.ApiUrl + '/localities?pagination=false&mainParent.code='+mainParentCode, this.requestOptions)
        .pipe(
          map((responses) => {
            return this.constructResponse(false, responses);
          })
        );
    }

    getCityOfCountryByID(mainParentCode:any): Observable<any> {
      return this.httpService.get<any>(environment.ApiUrl + '/localities?pagination=false&mainParent.id='+mainParentCode, this.requestOptions)
        .pipe(
          map((responses) => {console.log('getCityOfCountryByID ==> ',responses);
            return this.constructResponse(false, responses);
          })
        );
    }

  getCountryAndCity(): Observable<any>
  {
    let Conditions='';
    let typeCode=['country','city'];
    for (let item = 0; item < typeCode.length; item++)
    {
      let cond2 = "&type.code[]="+typeCode[item];
      Conditions = Conditions.concat(cond2);
    }
    let requestUrl=environment.ApiUrl + '/localities?pagination=false'+Conditions+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.constructResponse(false, responses);
        })
      );
  }
  getlocalitiesByName(name: string): Observable<any> {
    if (name.length <3) {
      return of([]);
    }
    return this.httpService.get<any>(environment.ApiUrl + '/localities?pagination=false&nameFr='+name, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.constructResponse(false, responses);
        })
      );
  }
  getParameterValue(parentCode:string, parameterCode:string,isActive:boolean|null=true,code:string|null='',parentId:number|null=0,nameFr:string|null="",page: number|null = 1,pagination:boolean=false): Observable<any>
  {
   /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }

    if (parentCode && parentCode.length>0)
    {
      let cond2 = "&parent.code="+parentCode;
      Conditions = Conditions.concat(cond2);
    }

    if (nameFr && nameFr.length>0)
    {
      let cond2 = "&nameFr="+nameFr;
      Conditions = Conditions.concat(cond2);
    }

    if (isActive)
    {
      let cond2 = "&isActive="+isActive;
      Conditions = Conditions.concat(cond2);
    }

    if (parameterCode && parameterCode.length>0)
    {
      let cond2 = "&parameter.code="+parameterCode;
      Conditions = Conditions.concat(cond2);
    }

    if (code && code.length>0)
    {
      let cond2 = "&code="+code;
      Conditions = Conditions.concat(cond2);
    }

    if (parentId && parentId>0)
    {
      let cond2 = "&parent.id="+parentId;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/parameter_values?pagination='+pagination+Conditions;
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.constructResponse(true, responses);
        })
      );
  }
  getDescriptions(channels:string|null,id:number|null,type:string|null='',objectIdentityArray: any|null='', oclub:string|null='', titleFr:string|null='',pagination:boolean|null=false,localityCode:string|null="",packagelocalitiesCode: any|null=''): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    console.log('packagelocalitiesCode == ',packagelocalitiesCode);
    let Conditions='';
    let entityName='product_details';
    if (id)
    {
      let cond2 = "&product="+id;
      Conditions = Conditions.concat(cond2);
    }
    if (channels && channels.length>0 && entityName=='product_details')
    {
      let cond2 = "&channel.code="+channels;
      Conditions = Conditions.concat(cond2);
    }
    if (type)
    {
      if (type=='generic_description')
      {
        entityName='generic_details';
        if (localityCode && localityCode.length>0)
        {
          let cond2 = "&locality.code="+localityCode;
          Conditions = Conditions.concat(cond2);
        }
        if (packagelocalitiesCode && packagelocalitiesCode.length>0)
        {
          for (let item = 0; item < packagelocalitiesCode.length; item++)
          {
            let cond2 = "&locality.code[]="+packagelocalitiesCode[item];
            Conditions = Conditions.concat(cond2);
          }
        }
      }
      let cond2 = "&type.code="+type;
      Conditions = Conditions.concat(cond2);
    }

    if (oclub)
    {
      let cond2 = "&oclub.code="+oclub;
      Conditions = Conditions.concat(cond2);
    }

    if (titleFr)
    {
      let cond2 = "&titleFr="+titleFr;
      Conditions = Conditions.concat(cond2);
    }

    if (objectIdentityArray && objectIdentityArray.length)
    {
      for (let item = 0; item < objectIdentityArray.length; item++)
      {
        let cond2 = "&product[]="+objectIdentityArray[item];
        Conditions = Conditions.concat(cond2);
      }

    }
      /************************* Construction de l'url aver les parameters de recherche *******************/

    let requestUrl=environment.ApiUrl +'/'+entityName+'?pagination='+pagination+Conditions+'';
    console.log('detail requestUrl channel =****=>',requestUrl)
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {     console.log('responses  détail  =****=>',responses);
          return this.constructResponse(true, responses);
        })
      );
  }
  importDetailFromOtherProduct(channelId:number|null,toProductCode:any|null,fromProductCode:any|null): Observable<any>
  {
    console.log('importDetailFromOtherProduct channelId =****=>',channelId);
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let entityName='product_detail';
    let requestUrl=environment.ApiUrl +'/'+entityName+'/'+fromProductCode+'/'+toProductCode+'/duplicate';
    if (channelId && channelId>0)
    {
      requestUrl = requestUrl+'/'+channelId;
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    console.log('url importDetailFromOtherProduct =****=>',requestUrl)
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {     console.log('responses  importDetailFromOtherProduct  =****=>',responses);
          return this.constructResponse(true, responses);
        })
      );
  }
  getOneDescriptionById(id:number,type:string|null=''): Observable<any>
  {
    let entityName='product_details';
    if (type && type=='generic-descriptions')
    {
      entityName='generic_details';
    }
    let requestUrl=environment.ApiUrl +'/'+entityName+'/'+id+'';
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return responses;
        })
      );
  }
  formatBytes(bytes:number, decimals:number|null)
  {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  showSpinner()
  {
      this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  }
  hideSpinner()
  {
    let spinner=this.spinner;
    spinner.hide();
  }
  addEditDetails(entity:string,objectIdentity:any|undefined,titleFr:string|null,textFr:string|null,typeId:number|null,rank:number|null,oclubId:string|null,detailId:number|null,channelId:number|null ): Observable<any>
  {
    let entityName='generic_details';
    let bodyOfPost:any;
    let entityId=localStorage.getItem(entity);
    let genericDescriptionId=localStorage.getItem('generic_description');
    let oclub=null;
    let product=null;
    if (entity && entity=='product')
    {
      entityName='product_details';
      bodyOfPost =
        {
        "@type": "ProductDetail",
        "titleFr": titleFr,
        "textFr": textFr,
        //"type": "/api/parameter_values/"+typeId,
        "channel" : "/api/parameter_values/"+channelId
        }

      if (typeId && typeId > 0)
      {
        bodyOfPost.type="/api/parameter_values/"+typeId;
      }
      else
      {
        bodyOfPost.type=null;
      }


      if (rank)
      {
        bodyOfPost.rank=rank;
      }

      if (objectIdentity)
      {
        product ="/api/products/"+objectIdentity;
        bodyOfPost.product=product;
      }

    }
    else
    {
      if (oclubId)
      {
       oclub="/api/parameter_values/"+oclubId;
      }
      bodyOfPost =
      {
        "@context": "/api/contexts/GenericDetail",
        "@type": "GenericDetail",
        "entity": {
          "@id": "/api/parameter_values/"+entityId,
          "@type": "ParameterValue"
        },
        "titleFr": titleFr,
        "textFr": textFr,
        "oclub": oclub,
        "rank": rank,
        "type": {
          "@id": "/api/parameter_values/"+genericDescriptionId,
          "@type": "ParameterValue"
        },
        "channel" : "/api/parameter_values/"+channelId
      }
      if (objectIdentity && objectIdentity>0)
      {
        bodyOfPost.locality="/api/localities/"+objectIdentity;
      }

    }
    console.log('=====bodyOfPost addEditDetails======',bodyOfPost);
    //update
    if (detailId && !isNaN(detailId) && detailId>0)
    {
      console.log('url update detail =',environment.ApiUrl + '/'+entityName+'/'+detailId)
      return this.httpService.put<any>(environment.ApiUrl + '/'+entityName+'/'+detailId,bodyOfPost, this.requestOptions);
    }
    // Add
    else
    {
      console.log('url ADD detail =',environment.ApiUrl + '/'+entityName)
      return this.httpService.post<any>(environment.ApiUrl + '/'+entityName,bodyOfPost, this.requestOptions);
    }

  }
  deleteDetail(entity:string,detailId:number|null): Observable<any>
  {
    let entityName='generic_details';
    if (entity && entity=='product')
    {
      entityName='product_details';
    }
    console.log('url delete detail =',environment.ApiUrl + '/'+entityName+'/'+detailId)
    return this.httpService.delete<any>(environment.ApiUrl + '/'+entityName+'/'+detailId,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted api response ',responses);
          return responses;
        })
      );

  }
  // Upload photo
  onFileSelected(event: any|EventEmitter<File[]>)
  {
    this.NewAddPhotos=[];
    this.NewAddPhotos.length=0;
    for (let index in event)
    {
      const file: File = event[index];
      if (file.size <= this.maxFileSize)
      {
        const Img = new Image();
        Img.src = URL.createObjectURL(file);
        Img.onload = () =>
        {
          let sanitizer=this.sanitizer;
          let reader = new FileReader();
          let src=this.src;
          let NewAddPhotos=[];
          NewAddPhotos.length=0;
          NewAddPhotos=this.NewAddPhotos;
          reader.readAsDataURL(file);
          reader.onload = function ()
          {
            let previewPath = sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
            src = Img.src;
            NewAddPhotos.push({'name':file.name,'Base64':reader.result,'width':Img.width,'height':Img.height,'size':file.size,'url':previewPath});
          };
          reader.onerror = function (error) {
            console.log('Convert Base 64 Error: ', error);
          };

        };
      }
    }
    return this.NewAddPhotos;
  }
  goBack()
  {
    this.location.back();
  }
  redirectToURl(uri:string)
  {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }
  constructResponse(pagination:boolean, responses:any)
  {

    let CleanResponse: any[] = [];
    let CleanResponseInfo: any[] = [];
    let totalProducts=responses['hydra:totalItems'];
    if (pagination && totalProducts >= Constantes.maxItemsPerPage)
    {
      CleanResponseInfo.push(
        {
          'totalItems': totalProducts,
          'firstPage': null,
          'nextPage': null,
          'lastPage': null
          /* 'firstPage':this.getLastNumericFormString(responses['hydra:view']['hydra:first']),
           'nextPage':this.getLastNumericFormString(responses['hydra:view']['hydra:next']),
           'lastPage':this.getLastNumericFormString(responses['hydra:view']['hydra:last']),*/
        }
      );
    }
    else
    {
      CleanResponseInfo.push(
        {
          'totalItems': totalProducts,
          'firstPage': null,
          'nextPage': null,
          'lastPage': null
        }
      );
    }
    CleanResponse.push(
      {
        'data': responses['hydra:member'],
        'info': CleanResponseInfo
      }
    );
    return CleanResponse;
  }
  deleteParameter(parameterCode: string): Observable<any>
  {
    return this.httpService.delete<any>(environment.ApiUrl + '/parameters/'+parameterCode,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted api response ',responses);
          return responses;
        })
      );
  }
  searchParameters(code: string|null = '',page: number|null = 1,name: string|null = '', status: boolean|null = false, master: boolean|null = false): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';

    if (page)
    {
      let cond2 = "&page="+page;
      Conditions = Conditions.concat(cond2);
    }
    if (name && name.length>0)
    {
      let cond2 = "&name="+name;
      Conditions = Conditions.concat(cond2);
    }
    if (code && code.length>0)
    {
      let cond2 = "&code="+code;
      Conditions = Conditions.concat(cond2);
    }
    if (status)
    {
      let cond2 = "&isActive="+status;
      Conditions = Conditions.concat(cond2);
    }
    if (master)
    {
      let cond2 = "&isMaster="+master;
      Conditions = Conditions.concat(cond2);
    }
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let requestUrl=environment.ApiUrl +'/parameters?pagination=true'+Conditions+'';
    console.log('requestUrl : getParameters',requestUrl);
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {    console.log('responses : getParameters',responses);
          return this.constructResponse(true, responses);
        })
      );
  }
  searchParametersName(name: string): Observable<any> {
    if (name.length <3) {
      return of([]);
    }
    let requestUrl=environment.ApiUrl + '/parameters?pagination=true&name='+name;
    return this.httpService.get<any>(requestUrl, this.requestOptions)
      .pipe(
        map((responses) => {
          return this.constructResponse(false, responses);
        })
      );
  }
  addEditParameters(id:number|null, code:string|null, name:string|null, isActive:boolean|null, isMaster:boolean|null): Observable<any>
  {
    console.log('addEditParameters ==> id',id);
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@type": "parameters",
        "code": code,
        "name": name,
        "isMaster": isMaster,
        "isActive": isActive
      }
    console.log('bodyOfPost',bodyOfPost);
    //update
    if (id && !isNaN(id) && id>0)
    {
      console.log('url update parameter_values =',environment.ApiUrl + '/parameters/'+id)
      return this.httpService.put<any>(environment.ApiUrl + '/parameters/'+id,bodyOfPost, this.requestOptions);
    }
    // Add
    else
    {
      console.log('url ADD parameters =',environment.ApiUrl + '/parameters')
      return this.httpService.post<any>(environment.ApiUrl + '/parameters',bodyOfPost, this.requestOptions);
    }

  }
  deleteParameters(id:number|null): Observable<any>
  {
    let entityName='parameters';
    console.log('url delete parameters =',environment.ApiUrl + '/'+entityName+'/'+id)
    return this.httpService.delete<any>(environment.ApiUrl + '/'+entityName+'/'+id,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted parameters api response ',responses);
          return responses;
        })
      );

  }
  addEditParameterValue(parameterId:number|null,id:number|null, code:string|null, nameFr:string|null, isActive:boolean|null, rank:number|null, data1:string|null, data2:string|null, data3:string|null,parentId:number|null ): Observable<any>
  {
    let bodyOfPost:any;
    bodyOfPost =
      {
        "@type": "parameter_values",
        "code": code,
        "nameFr": nameFr,
        "data1": data1,
        "data2": data2,
        "data3": data3,
        "parameter": "/api/parameters/"+parameterId,
        "isActive": isActive
      }
    if (rank && rank>0)
    {
      bodyOfPost.rank=rank;
    }
    console.log('channels on save == ',parentId);
    if (parentId && parentId>0)
    {
      bodyOfPost.parent="/api/parameter_values/"+parentId;
    }

    console.log('bodyOfPost',bodyOfPost);
    //update
    if (id && !isNaN(id) && id>0)
    {
      console.log('url update parameter_values =',environment.ApiUrl + '/parameter_values/'+id)
      return this.httpService.put<any>(environment.ApiUrl + '/parameter_values/'+id,bodyOfPost, this.requestOptions);
    }
    // Add
    else
    {
      console.log('url ADD parameter_values =',environment.ApiUrl + '/parameter_values')
      return this.httpService.post<any>(environment.ApiUrl + '/parameter_values',bodyOfPost, this.requestOptions);
    }

  }
  deleteParameterValues(id:number|null): Observable<any>
  {
    let entityName='parameter_values';
    console.log('url delete parameter_values =',environment.ApiUrl + '/'+entityName+'/'+id)
    return this.httpService.delete<any>(environment.ApiUrl + '/'+entityName+'/'+id,this.requestOptions)
      .pipe(
        map((responses) => {
          console.log('deleted parameter_values api response ',responses);
          return responses;
        })
      );

  }
  checkApiError(error:any)
  {
    console.log(error);
    if (error.status==401)
    {
      localStorage.clear();
      this.href = this.router.url;
      console.log('url= ',this.href);
      if (this.href =='/login')
      {
        console.log('login page ');
      }
      else
      {
       document.location.href='/login';
      }

    }
  }
  getLog(id:number=0,page: number|null=1,entityType:string|null=''): Observable<any>
  {
    /************************* Construction de l'url aver les parameters de recherche *******************/
    let Conditions='';
    let requestUrl='';
    /************************* Construction de l'url aver les parameters de recherche *******************/
      if (page)
      {
        let cond2 = "page="+page;
        Conditions = Conditions.concat(cond2);
      }
      requestUrl=environment.ApiUrl +'/audit_logs/product/'+id+'?'+Conditions+'';
      console.log('url log2 =', requestUrl);
      return this.httpService.get<any>(requestUrl, this.requestOptions)
        .pipe(
          map((responses) => {console.log('responses LOG==>',responses);
            return responses;
          })
        );
  }
  login_check(email:string, password:string)
    {
      let bodyOfPost:any;
      bodyOfPost =
        {
          "email": email,
          "password": password
        }
      console.log('login_check : bodyOfPost',bodyOfPost);
      console.log('url update parameter_values =',environment.ApiUrl + '/login_check')
      return this.httpService.post<any>(environment.ApiUrl + '/login_check',bodyOfPost, this.requestOptions);
    }
}



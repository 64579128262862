import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {ProduitsManquantsComponent} from "./produits-manquants/produits-manquants.component";
const routes: Routes = [
  {path: '', component:DashboardComponent,pathMatch:"full"},
  {path: 'produits-manquants', component:ProduitsManquantsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

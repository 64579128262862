import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParameterDetailsComponent } from './parameter-details/parameter-details.component';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import { ParameterValueDetailsComponent } from './parameter-value-details/parameter-value-details.component';
import { ParameterValueListComponent } from './parameter-value-list/parameter-value-list.component';
import { ParameterValueNewComponent } from './parameter-value-new/parameter-value-new.component';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxPaginationModule} from "ngx-pagination";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSortModule} from "@angular/material/sort";
import {NgSelect2Module} from "ng-select2";
import {SharedModule} from "../shared/shared.module";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerModule} from "ngx-spinner";
import {LogModule} from "../log/log.module";
const routes: Routes = [
  {path: 'parameter/:parameterCode/values', component:ParameterValueListComponent, pathMatch:"full"},
  {path: 'parameters', component:ParameterListComponent}
];

@NgModule({
  declarations: [
    ParameterDetailsComponent,
    ParameterListComponent,
    ParameterValueDetailsComponent,
    ParameterValueListComponent,
    ParameterValueNewComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatInputModule,
    [RouterModule.forChild(routes)],
    MatSortModule,
    NgSelect2Module,
    SharedModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    LogModule
  ]
})
export class ParameterModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string,substring1:string='',substring2:string='',type:string='ALL'): string {
    if(!type || type.toUpperCase() != "ALL"){
      type = "";
    } else {
      type ="g";
    }
    let re = new RegExp(substring1,type);
    return value.replace(re,substring2);
  }
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../shared/services/products.service";
import {GlobalService} from "../../shared/services/global.service";
@Component({
  selector: 'app-Package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.css']
})
export class PackageDetailsComponent implements OnInit {
  id:number=0;
  action:string='';
  packageCode='';
  tab:string='generalites';
  Package: any = [];
  PackageProducts: any = [];
  constructor
  (
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private globalService: GlobalService,
  ) { }
  ngOnInit(): void {
    this.id = this.activatedRouter.snapshot.params['id'];
    this.action= this.activatedRouter.snapshot.params['action'];
    this.packageCode = this.activatedRouter.snapshot.params['code'];
    this.tab = this.activatedRouter.snapshot.params['tab'];

    if (!this.id || isNaN(this.id))
    {
      this.router.navigate(['/packages']);
    }
    if (!this.tab)
    {
      this.tab='generalites';
    }
    if (!this.action)
    {
      this.action='Création';
    }
    else if (this.action=='edit')
    {
      this.action='Modification';
    }
    this.getPackage();
    this.getPackageProducts();
  }
  getPackage()
  {this.globalService.showSpinner();
    this.productService.getProduct(this.id).subscribe(
      Package => {
        this.globalService.hideSpinner();
        this.Package = Package; console.log('this.Package   ===> ',this.Package );
      },
      error => {
        this.globalService.hideSpinner();
        this.globalService.checkApiError(error);
      }
    )
  }
  getPackageProducts() {
    this.productService.getPackageSegments(null,false,'', null,null,this.id,null,null)
      .subscribe(
        products => {
          for (let item in products[0]['data'])
          {
            this.PackageProducts.push(products[0]['data'][item]['product']);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );

  }
  goToUrl(id:number|null,code:string|null,tab:string|null,type:string|null)
  {
    let Link="/"+type+"/"+id+"/"+code+"/"+tab;
    this.globalService.redirectToURl(Link);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-parameter-value-new',
  templateUrl: './parameter-value-new.component.html',
  styleUrls: ['./parameter-value-new.component.css']
})
export class ParameterValueNewComponent {

}

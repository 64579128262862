<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-sm-flex align-items-center justify-content-between infoBar">
            <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
              <span class="badge badge-soft-dark">{{this.action}} {{Package.code}} {{Package.nameFr}}</span>
            </h6>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a routerLink="/packages" class="mr-1">
                    <button class="btn btn-secondary waves-effect btn-label waves-light float-start modal-wait blocRightBottom"  type="button">
                      <i class="ft-arrow-left mr-1"></i> Liste des forfaits
                    </button>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section id="justified-tabs">
        <div class="row match-height">
          <div class="col-lg-12">
            <div class="card" style="height: auto;">
              <div class="card-content mb-3">
                <div class="card-body card-body-generalite">
                  <ul class="nav nav-tabs nav-justified" *ngIf="Package?.length != 0 && id && packageCode">
                    <li class="nav-item">
                      <a (click)="goToUrl(id,packageCode,'','package')" aria-controls="generalites" [attr.aria-expanded]="tab=='generalites'" [ngClass]="{'active': tab=='generalites'}"  class="nav-link" data-toggle="tab" href="#generalites"   id="generalites-tab">Généralités</a>
                    </li>
                    <li class="nav-item">
                      <a (click)="goToUrl(id,packageCode,'descriptions','package')" aria-controls="descriptions" [attr.aria-expanded]="tab=='descriptions'" [ngClass]="{'active': tab=='descriptions'}"  class="nav-link" data-toggle="tab" href="#descriptions"  id="descriptions-tab">Descriptions</a>
                    </li>
                    <li class="nav-item">
                      <a  (click)="goToUrl(id,packageCode,'photos','package')" aria-controls="photos" [attr.aria-expanded]="tab=='photos'" [ngClass]="{'active': tab=='photos'}"  class="nav-link" data-toggle="tab" href="#photos"  id="photos-tab">Photos</a>
                    </li>
                    <li class="nav-item">
                      <a  (click)="goToUrl(id,packageCode,'tags','package')"ngbDropdownAnchor aria-controls="tags" [attr.aria-expanded]="tab=='tags'" [ngClass]="{'active': tab=='tags'}"  class="nav-link" data-toggle="tab" href="#tags"  id="tags-tab">Tags</a>
                    </li>
                  </ul>
                  <div class="tab-content"  *ngIf="Package?.length != 0 && id && packageCode">
                    <div [attr.aria-expanded]="tab=='generalites'" aria-labelledby="generalites-tab" [ngClass]="{'active show': tab=='generalites'}"  class="tab-pane" id="generalites"  role="tabpanel">
                      <app-PackageGeneralites [packageId]="id" [existedPackageProducts]="PackageProducts" [packageCode]="packageCode" [Package]="Package"></app-PackageGeneralites>
                    </div>
                    <div [attr.aria-expanded]="tab=='descriptions'" aria-labelledby="descriptions-tab" [ngClass]="{'active show': tab=='descriptions'}" class="tab-pane" id="descriptions" role="tabpanel">
                      <app-PackageDescriptions  [Package]="Package" [packageCode]="packageCode" [existedPackageProducts]="PackageProducts"></app-PackageDescriptions>
                    </div>
                    <div [attr.aria-expanded]="tab=='photos'" aria-labelledby="photos-tab" [ngClass]="{'active show': tab=='photos'}" class="tab-pane" id="photos" role="tabpanel">
                      <app-PackagePhotos [packageId]="id" [Package]="Package" ></app-PackagePhotos>
                    </div>
                    <div [attr.aria-expanded]="tab=='tags'" aria-labelledby="tags-tab" [ngClass]="{'active show': tab=='tags'}" class="tab-pane" id="tags" role="tabpanel">
                      <app-PackageTags [packageId]="id"  [packageCode]="packageCode" [Package]="Package"></app-PackageTags>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="Package?.length == 0">
                    <img  class="LoaderImages" src="../assets/img/load.gif" alt="Loading...">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

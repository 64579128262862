import {Component, OnInit} from '@angular/core';
import {NgForm,FormControl} from '@angular/forms';
import {debounceTime, tap, map } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.css']
})
export class ParameterListComponent implements OnInit {
  nbParameters = 0;
  firstPage = 0;
  nextPage = 0;
  lastPage = 0;
  page: number = 1;
  ElementId:number =0;
  parametersLists: any = [];
  parameter: any = [];
  parametersNameLists: any = [];
  searchParameterName = new FormControl();
  filteredParameters: any;
  isLoading = false;
  errorMsg: string = '';
  value = 'resolve';
  allowClear = true;
  required = true;
  disabled = false;
  currentPage = 1;
  maxItem = Constantes.maxItemsPerPage;

  constructor(
    private globalService: GlobalService,
    private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.getAllParameters();
    this.GetParameterName();
  }

  getAllParameters() {
    this.globalService.showSpinner();
    this.parametersLists = [];
    this.globalService.searchParameters().subscribe(
      parametersLists => {
        this.parametersLists = parametersLists[0]['data'];
        this.nbParameters = 0;
        this.lastPage = 0;
        this.nbParameters = parametersLists[0]['info'][0]['totalItems'];
        this.lastPage = Math.ceil(this.nbParameters / this.maxItem);
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  displayFn: any;

  GetParameterName() {
    this.searchParameterName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredParameters = [];
          this.isLoading = true;
        }),
        map((paramName: string) => paramName.toString().trim())
      )
      .subscribe((paramName: string) => {
          this.globalService.searchParametersName(paramName).subscribe(
            searchResponse => {
              if (searchResponse[0] !== undefined) {
                this.isLoading = false;
                this.parametersNameLists = searchResponse[0]['data'];
                console.log('this.parametersNameLists ==> ',this.parametersNameLists);
              }
            },
            error => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }

  searchParameters(form: NgForm, page = 1) {
    this.globalService.showSpinner();
    let name = this.searchParameterName.value;
    let etat = form.value.etat;
    let code = form.value.code;
    let master = form.value.master;
    this.parametersLists = [];
    this.globalService.searchParameters(code,page, name, etat, master).subscribe(
      (searchResponse: any) => {
        this.parametersLists = searchResponse[0]['data'];
        this.lastPage = 0;
        this.nbParameters = 0;
        this.nbParameters = searchResponse[0]['info'][0]['totalItems'];
        this.lastPage = Math.ceil(this.nbParameters / Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      (error: any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    const data = this.parametersLists.slice();
    if (!sort.active || sort.direction === '') {
      this.parametersLists = data;
      return;
    }

    this.parametersLists = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'createdAt':
          return this.compare(a.createdAt, b.createdAt, isAsc);
        case 'updatedAt':
          return this.compare(a.updatedAt, b.updatedAt, isAsc);
        case 'active':
          return this.compare(a.isActive, b.isActive, isAsc);
        case 'master':
          return this.compare(a.isMaster, b.isMaster, isAsc);
        default:
          return 0;
      }
    });
  }

  pagesCounter(i: number) {
    return new Array(i);
  }

  loadNextPage(form: NgForm, pageNumber: number) {
    this.currentPage = pageNumber;
    if (pageNumber <= this.lastPage && pageNumber > 0) {
      this.searchParameters(form, pageNumber);
    }
  }

  editParameter(parameter: any) {
    this.parameter = parameter;
  }

  deleteParameter(item: any) {
    let globalService = this.globalService;
    let parametersLists = this.parametersLists;
    let notification = this.notification;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir supprimer", item.name, "Valider", "Supprimer !", "", function (confirmed: any) {
      if (confirmed) {
        globalService.deleteParameters(item.id).subscribe(
          deletedResponse => {
            let index = parametersLists.indexOf(item);
            if (index > -1) {
              parametersLists.splice(index, 1);
              notification.showSuccess("Le paramètre ' " + item.name + " ' a été supprimé avec succès", "");
              $("html, body").animate({scrollTop: 0}, "slow");
            }
          },
          error => {
            globalService.checkApiError(error);
            notification.showError(error['error']['hydra:description'], "");
          }
        );
      } else {
        console.log('response ==> false');
      }
    });
  }

  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
}

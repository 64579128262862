import {Component, OnInit} from '@angular/core';
import {FormControl, NgForm} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { jwtDecode } from "jwt-decode";
import {GlobalService} from "../../shared/services/global.service";
import {NotificationService} from "../../shared/services/notification.service";


@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  loginFormSubmitted = false;
  isLoginFailed = false;
  formControl = new FormControl();
  required = true;
  constructor(
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private notification: NotificationService) {}

  ngOnInit(): void {
    $('.main-content').css('margin-left', '0px');
    $('#headerBloc').hide();
    $('#SideBarMenu').hide();
    $('#footerBloc').hide();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }
  // On submit button click
  onSubmit(form:NgForm)
  {
    if (form.valid)
    {
      let userName = form.value.username;
      let password = form.value.password;
    /**************************************** call api check , if ok on recupere le token ***************************/

    this.globalService.login_check(userName, password)
      .subscribe(
        response => {
          console.log('login_check = response ' , response);
          //this.globalService.hideSpinner();
          if (response['status']==200)
          {
            localStorage.clear();
            this.globalService.hideSpinner();
            let newToken=response['data']['token'];
            console.log("newToken = ",newToken);
            const tokenInfo = this.getDecodedAccessToken(newToken); // decode token
            console.log("tokenInfo = ",tokenInfo); // show decoded token object in console
            let User_email=tokenInfo['email'];
            let User_roles=tokenInfo['roles']['0'];
            let User_username=tokenInfo['username'];
            console.log("User_roles = ",User_roles);
            console.log("User_email = ",User_email);
            console.log("User_username = ",User_username);
            if (newToken.length>0)
            {
              localStorage.setItem('token',newToken);
              localStorage.setItem('User_roles',User_roles);
              localStorage.setItem('User_email',User_email);
              localStorage.setItem('User_username',User_username);
            }
            window.location.href='/links_page';
          }
          else
          {
            this.globalService.hideSpinner();
            $('#username').val('');
            $('#password').val('');
            this.notification.showError('Veuillez vérifier votre email et votre mot de passe !',"");
          }
        },
        error => {
          this.globalService.hideSpinner();
          $('#username').val('');
          $('#password').val('');
          console.log('error ==>',error);
          this.notification.showError('Veuillez vérifier votre email et votre mot de passe !',"");
        }
      );

    /**************************************** call api check , if ok on recupere le token ***************************/

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

   }
 }


}

 <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
            <h4><span class="badge bg-info">{{productPackageList.length}} Forfait<span *ngIf="productPackageList?.length >1">s</span></span></h4>
            <button  routerLink="/package/new/{{productCode}}" class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right AddPackage"  type="button">
              <i class="ft-plus mr-1"></i>Nouveau forfait
            </button>
        </div>
        <div class="card-body" *ngIf="productPackageList?.length != 0">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mb-0">
              <thead>
              <tr>
                <th>Code forfait</th>
                <th>Nom forfait</th>
                <th>Nombre de jours du forfait</th>
                <th>Nombre de nuit du forfait</th>
                <th>Etat</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of productPackageList">
                <td>{{item.package.code}}</td>
                <td>{{item.package.nameFr}}</td>
                  <td align="center">
                  {{item.package.durationDays}}
                </td>
                <td align="center">
                  {{item.package.durationNights}}
                </td>
                <td *ngIf="!item.package.isActive" align="center">
                <i class=" fa-solid fa-ban icon-danger" title="Actif = non"></i>
                </td>
                <td *ngIf="item.package.isActive"  align="center">
                  <i class=" fa-solid fa-check icon-success" title="Actif = oui"></i>
                </td>
                <td>
                  <a routerLink="/package/{{item.package.id}}/{{item.package.code}}/generalites/edit" title="Modifier"><i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit"  style="margin-right:10px !important; " title="Modifier"></i></a>
                  <!---a routerLink="/package/{{item.package.id}}/{{item.package.code}}/descriptions" title="Les descriptifs"><i class="fas fa-list btnActionDescriptions-ul" style="margin-right:10px !important; "  title="Les descriptifs"></i></a>
                  <a routerLink="/package/{{item.package.id}}/{{item.package.code}}/photos" title="Les photos"><i class="fas fa-images btnActionPhotos"  style="margin-right: 10px!important;"  title="Les photos"></i></a--->
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

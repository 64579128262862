import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionNewComponent } from './description-new/description-new.component';
import { DescriptionDetailsComponent } from './description-details/description-details.component';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {NgSelect2Module} from "ng-select2";
import {AngularEditorModule} from "@kolkov/angular-editor";
const routes: Routes = [
  {path: 'description/new/:type', component: DescriptionNewComponent, pathMatch:"full"},
  {path: 'description/new/:type/:id', component: DescriptionNewComponent},
  {path: 'description/new/:type/:id/:channelId', component: DescriptionNewComponent},
  {path: 'description/edit/:type/:detailId', component: DescriptionDetailsComponent},
  {path: 'description/edit/:type/:detailId/:channelId', component: DescriptionDetailsComponent},
  {path: 'description/edit/:type/:detailId/:channelId/:id', component: DescriptionDetailsComponent}
];
@NgModule({
  declarations: [
    DescriptionNewComponent,
    DescriptionDetailsComponent,

  ],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    FormsModule,
    SharedModule,
    NgSelect2Module,
    AngularEditorModule
  ]
})
export class DescriptionModule { }

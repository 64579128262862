<div class="content-wrapper">
  <div class="row"></div>
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Liste des archives</h6></div>
            <div class="card-header">
              <h4 class="card-title float-left product-count">{{nbProducts}} archive<span *ngIf="nbProducts >1">s</span></h4>
            </div>
            <div *ngIf="productsLists" class="card-content">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                        <form #SearchEngineForm="ngForm" (ngSubmit)="searchProducts(SearchEngineForm);">
                          <!-- ligne 1 -->
                          <div class="row" style="display: flex!important;">
                            <!-- ID -->
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label for="code">Code</label>
                                <input class="form-control" id="code" name="code" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Nom -->
                            <div class="col-md-4">
                              <div class="form-floating">
                                <mat-form-field>
                                  <label for="name">Nom</label>
                                  <input [formControl]="searchProductName"  [matAutocomplete]="auto" aria-label="State" class="form-control autocompleteInput" id="name" matInput name="name" ngModel type="text" value="">
                                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngIf="isLoading" class="is-loading">...</mat-option>
                                    <ng-container *ngIf="!isLoading">
                                      <mat-option *ngFor="let product of productsNameLists"  [value]="product.name">
                                        <span>{{product.name}}</span>
                                      </mat-option>
                                    </ng-container>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- Type -->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label for="type">Type</label>
                                <select aria-label="Floating label select example" class="form-control" id="type" name="type" ngModel [(ngModel)]="typeArchive && typeArchive" >
                                  <option selected="" value="">Tous</option>
                                  <option value="accommodation">Hôtel</option>
                                  <option value="package">Forfait</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-floating">
                                <label>&nbsp;</label>
                                <div class="w-auto d-block">
                                  <button class="btn btn-primary" type="submit">
                                    <i class="ft-search mr-1"></i>Rechercher
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
                <div class="table-responsive" *ngIf="productsLists?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row mt-2 tableList" matSort (matSortChange)="sortData($event)">
                    <thead >
                    <tr>
                      <th mat-sort-header="id" style="width: 3% !important;">Id</th>
                      <th mat-sort-header="code" style="width: 15% !important;">Code</th>
                      <th mat-sort-header="name" style="width:auto !important;">Nom</th>
                      <th mat-sort-header="created_at" style="width:12% !important;">Date de création</th>
                      <th mat-sort-header="updated_at" style="width:12% !important;">Date d'archivage</th>
                      <th mat-sort-header="is_active" style="width: 6% !important; text-align: center;">Etat</th>
                      <th style="width: 11% !important;">Désarchiver</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="productsLists?.length == 0">
                    <img class="LoaderImages" src="../assets/img/load.gif" style="position: inherit; margin: 15px;">
                    </tbody>
                    <tbody *ngIf="productsLists?.length != 0">
                    <tr *ngFor="let item of productsLists">
                      <td style="width: 3% !important;" class="text-center">{{item.id}}</td>
                      <td style="width: 15% !important; cursor: pointer;">{{item.code}}</td>
                      <td style="width: auto !important;">
                        <i class="ft-package packageIcone  mr-1" *ngIf="item.type_code == 'package'" title="Forfait"></i>
                        <i class="ft-shopping-bag hotelIcone mr-1" *ngIf="item.type_code == 'accommodation'" title="Hôtel"></i>
                        {{item.name}}
                      </td>
                      <td>{{item.created_at| date :  "dd/MM/yyyy à HH:mm"}}</td>
                      <td>{{item.updated_at| date :  "dd/MM/yyyy à HH:mm"}}</td>
                      <td *ngIf="!item.is_active" style="width: 6% !important;text-align: center;">
                        <i class=" fa-solid fa-ban icon-danger" title="Actif = non"></i>
                      </td>
                      <td *ngIf="item.is_active"  style="width: 6% !important;text-align: center;">
                        <i class=" fa-solid fa-check icon-success" title="Actif = oui"></i>
                      </td>
                      <td style="width: 11% !important;text-align: center;">
                        <i class="fa-regular fa-boxes-packing btnActionArchive" style="margin: 5px;cursor:pointer;font-size: 17px;!important;" (click)="deArchivateProduct(item);"  title="Désarchiver"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nbProducts > maxItem">
                    <div align="right" class="row BlocPaginate">
                      <div class="pagination ngx-pagination">
                        <ngb-pagination [collectionSize]="nbProducts" (pageChange)="loadNextPage(SearchEngineForm,$event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                          <ng-template ngbPaginationPrevious>Précédent</ng-template>
                          <ng-template ngbPaginationNext>Suivant</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericDescriptionListComponent } from './generic-description-list/generic-description-list.component';
import {RouterModule, Routes} from "@angular/router";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSortModule} from "@angular/material/sort";
import {NgSelect2Module} from "ng-select2";
import {SharedModule} from "../shared/shared.module";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from 'ngx-spinner';
import {LogModule} from "../log/log.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
const routes: Routes = [
  {path: 'generic-descriptions', component:GenericDescriptionListComponent}
];

@NgModule({
  declarations: [
    GenericDescriptionListComponent
  ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatInputModule,
        [RouterModule.forChild(routes)],
        MatSortModule,
        NgSelect2Module,
        SharedModule,
        NgbPaginationModule,
        NgxSpinnerModule,
        LogModule
    ]
})
export class GenericDescriptionModule { }

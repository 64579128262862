import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../../shared/services/products.service";
import {NgForm,FormControl} from '@angular/forms';
import {debounceTime, tap, map } from 'rxjs/operators';
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../../shared/services/global.service";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.css']
})
export class ArchiveListComponent implements OnInit {
  typeArchive:string='';
  nbProducts=0;
  firstPage=0;
  nextPage=0;
  lastPage=0;
  page: number = 1;
  productsLists: any = [];
  productsNameLists: any = [];
  searchProductName = new FormControl();
  filteredProducts: any;
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  currentPage=1;
  maxItem=Constantes.maxItemsPerPage;
  constructor(
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private globalService: GlobalService,
    private notification: NotificationService
  ) {}
  ngOnInit(): void {
    this.typeArchive = this.activatedRouter.snapshot.params['typeArchive'];
    this.getAllArchivedProduct();
    this.GetProductName();
  }
  getAllArchivedProduct()
  {
    this.globalService.showSpinner();
    this.productService.searchArchivedProduct(1,null,null,this.typeArchive).subscribe(
      (productsLists:any) => {
        this.productsLists = productsLists['data'];
        this.nbProducts=0;
        this.lastPage=0;
        this.nbProducts =productsLists['total'];
        this.lastPage=Math.ceil(this.nbProducts/this.maxItem);
        this.globalService.hideSpinner();
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  displayFn: any;
  GetProductName ()
  {
    this.searchProductName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredProducts = [];
          this.isLoading = true;
        }),
        map((productNameFr: string) =>productNameFr.toString().trim())
      )
      .subscribe((productNameFr:string) => {
          this.productService.searchArchivedProductsName(productNameFr).subscribe(
            searchResponse =>
            {
              if (searchResponse!== undefined)
              {
                this.isLoading = false;
                this.productsNameLists=searchResponse['data'];
              }
            },
            error => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }
  searchProducts(form: NgForm,page=1)
  {
    this.globalService.showSpinner();
    let code=form.value.code;
    let name=this.searchProductName.value;
    let type=form.value.type;
    this.productsLists = [];
    this.productService.searchArchivedProduct(page,code,name,type).subscribe(
      searchResponse =>
      {
        this.productsLists=searchResponse['data'];
        this.lastPage=0;
        this.nbProducts=0;
        this.nbProducts =searchResponse['total']; console.log('nbProducts',this.nbProducts);
        this.lastPage=Math.ceil(this.nbProducts/Constantes.maxItemsPerPage);
        this.globalService.hideSpinner();
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.productsLists.slice();
    if (!sort.active || sort.direction === '') {
      this.productsLists= data;
      return;
    }
    this.productsLists= data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'nameFr':
          return this.compare(a.nameFr, b.nameFr, isAsc);
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        case 'updated_at':
          return this.compare(a.updated_at, b.updated_at, isAsc);
        case 'created_at':
          return this.compare(a.created_at, b.created_at, isAsc);
        case 'is_active':
          return this.compare(a.is_active, b.is_active, isAsc);
        default:
          return 0;
      }
    });
  }
  pagesCounter(i: number) {
    return new Array(i);
  }
  loadNextPage(form: NgForm,pageNumber:number)
  {
    this.currentPage=pageNumber;
    if (pageNumber<=this.lastPage && pageNumber >0)
    {
      this.searchProducts(form,pageNumber);
    }
  }
  deArchivateProduct(product:any)
  {
    let This=this;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir désarchiver ",product.name,"Valider","Désarchiver !","", function (confirmed:any)
    {
      if (confirmed)
      {
        This.globalService.showSpinner();
        This.productService.EditProductArchived(product.id,false).subscribe(
          response => {
            This.globalService.hideSpinner();
            if (response['id'])
            {
              This.notification.showSuccess("L'hôtel ' "+product.name+" ' a été désarchivé avec succès","");
              This.getAllArchivedProduct();
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            This.globalService.hideSpinner();
            This.globalService.checkApiError(error);
            This.globalService.hideSpinner();
            This.notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
}




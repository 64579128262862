import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-right-side-bar',
  templateUrl: './right-side-bar.component.html',
  styleUrls: ['./right-side-bar.component.css']
})
export class RightSideBarComponent implements OnInit {
  apiToken:any=localStorage.getItem('token');
  constructor() { }
  @Input () currentRoute:string='';
  ngOnInit(): void {

  }
  goToBackOffice()
  {
    let urlBack:string=environment.MidOfficeUrl+"/?token="+this.apiToken;
    document.location.href=urlBack;
  }

}

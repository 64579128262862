<div class="modal-dialog editParameterModalDialog" role="document">
  <div class="modal-content editParameterModalContent logModalHeight">
    <div class="modal-header">
      <h4 class="modal-title" style="font-size: 18px;">Historique </h4>
      <button type="button" class="close logModalCloseBtn" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row" *ngIf="logsLists?.length == 0">
        <h4 class="noLog"><b>Le journal est vide : aucune action n'a été enregistrée.</b></h4>
      </div>
      <div class="row" *ngIf="logsLists?.length >0">
        <div  class="card-content">
          <div class="card-body">
            <div class="table-responsive" *ngIf="logsLists?.length != 0">
              <table class="table table-striped table-bordered selection-deletion-row" matSort (matSortChange)="sortData($event)">
                <thead>
                <tr>
                  <th mat-sort-header="name" style="width: 17% !important;">Utilisateur</th>
                  <th mat-sort-header="createdAt" style="width: 20% !important;">Date d'action</th>
                  <th mat-sort-header="code" style="width: 13% !important;">Objet</th>
                  <th style="width: 14% !important;">Action</th>
                  <th mat-sort-header="active" style="width: auto !important;">Détail de l'action</th>
                </tr>
                </thead>
                <tbody *ngIf="logsLists?.length != 0">
                <tr *ngFor="let log of logsLists">
                  <td style="width: 17% !important;text-transform: capitalize;">{{log.user_first_name}}</td>
                  <td style="width: 20% !important;" class="text-center">{{log.created_at| date :  "dd/MM/yyyy à HH:mm"}}</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ProductSeoContent'">SEO</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='Product'">Généralités</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ProductDetail'">Descriptions</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ProductMedia'">Photos</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ProductLocality'">Localités</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='GenericDetail'">Descriptifs génériques</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='Parameter'">Paramètres</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ProductTag'">Tag</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='PackageSegment'">Hôtel</td>
                  <td style="width: 13% !important;" *ngIf="log.entity_type=='ParameterValue'">Paramètre valeur</td>
                  <td style="width: 14% !important;">{{log.action}}</td>
                  <td  style="width: auto !important;">
                    <div *ngFor="let event of transformJson(log.event_data)" class="audit-list">
                      <span class="logEvent">{{event.key}}</span>
                      <span class="audit-event-list">
                            <span class="audit-event audit-event-from">{{event.value.from}}</span>
                            <i class="ft-arrow-right event-arrow ml-1 mr-1 logArrow"></i>
                            <span class="audit-event audit-event-to">{{event.value.to}}</span><br>
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div *ngIf="nbLogs> maxItem">
                <div align="right" class="row BlocPaginate">
                  <div class="pagination ngx-pagination">
                    <ngb-pagination [collectionSize]="nbLogs" (pageChange)="loadNextPage($event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                      <ng-template ngbPaginationPrevious>Précédent</ng-template>
                      <ng-template ngbPaginationNext>Suivant</ng-template>
                    </ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

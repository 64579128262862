import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { RightSideBarComponent } from './shared/right-side-bar/right-side-bar.component';
import { FooterComponent } from './shared/footer/footer.component';
import {ProductModule} from "./product/product.module";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {NgxPaginationModule} from "ngx-pagination";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {PackageModule} from "./package/package.module";
import { DashboardComponent } from './dashboard/dashboard.component';
import {DescriptionModule} from "./description/description.module";
import {GenericDescriptionModule} from "./generic-description/generic-description.module";
import {ToastrModule} from "ngx-toastr";
import {NgxSpinnerModule} from "ngx-spinner";
import {ParameterModule} from "./parameter/parameter.module";
import {ArchiveModule} from "./archive/archive.module";
import {LogModule} from "./log/log.module";
import {LoginModule} from "./login/login.module";
import { ProduitsManquantsComponent } from './produits-manquants/produits-manquants.component';
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RightSideBarComponent,
    FooterComponent,
    DashboardComponent,
    ProduitsManquantsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ProductModule,
    PackageModule,
    DescriptionModule,
    GenericDescriptionModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    ParameterModule,
    ArchiveModule,
    LogModule,
    LoginModule,
    NgbPaginationModule,
    MatSortModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from "../../../shared/services/global.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {Constantes} from "../../../shared/constantes";
/******************* Drag and Drop sorting ***************************************/
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import {ProductsService} from "../../../shared/services/products.service";
/******************* Drag and Drop sorting ***************************************/
@Component({
  selector: 'app-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.css']
})
export class DescriptionsComponent implements OnInit {
  ItemsUpdatedRankList: any = [];
  productDescriptions: any = [];
  channels: any = [];
  defaultChannel=Constantes.defaultChannel;
  channelId:any;
  ElementId:number =0;
  @Input() productId: number=0;
  @Input() productCode: string = '';
  constructor(private globalService: GlobalService,private productService: ProductsService,  private notificationService :NotificationService) { }
  /******************* Drag and Drop sorting ***************************************/

  GetNewItemListAfterSortWithDragDrop (event: CdkDragDrop<string[]>)
  {
    console.log('event == ', event);
    if (event.previousContainer === event.container)
    {
      moveItemInArray(this.productDescriptions, event.previousIndex, event.currentIndex);
      this.setItemsUpdatedRankList(this.productDescriptions);
    }
    else
    {
      transferArrayItem(event.previousContainer.data,this.productDescriptions,event.previousIndex,event.currentIndex);
      console.log(' transferArrayItem  productDescriptions ==> ', this.productDescriptions);
    }
  }
  /******************* Drag and Drop sorting ***************************************/
  ngOnInit(): void
  {
    this.getChannels();
    this.getProductDescriptions();
  }
  getProductDescriptions()
  {
    this.globalService.showSpinner();
    this.productDescriptions=[];
    let channelCode=$("#channels option:selected").attr("code");
    this.channelId=$("#channels").val();
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
      this.channelId=this.defaultChannel;
    }
    this.globalService.getDescriptions(channelCode,this.productId,'')
      .subscribe(
        ProductDescriptions => {
          for (let item in ProductDescriptions[0]['data']) {
            this.productDescriptions.push(ProductDescriptions[0]['data'][item]);
          }
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );

  }
  importDetailFromOtherProduct()
  {
    this.globalService.showSpinner();
    this.productDescriptions=[];
    let channelCode=$("#channels option:selected").attr("code");
    this.channelId=$("#channels").val();
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
      this.channelId=this.defaultChannel;
    }
    let fromProductId=$('#importedProductCode').val();
    console.log('fromProductId = ' ,fromProductId);
    this.globalService.importDetailFromOtherProduct(this.channelId,this.productCode,fromProductId)
      .subscribe(
        ProductDescriptions => {
          this.getProductDescriptions();
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );
  }
  getChannels() {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => { console.log('channels',channels);
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  Delete(item:any)
  {
    let globalService=this.globalService;
    let productDescriptions=this.productDescriptions;
    let notification=this.notificationService;
    this.notificationService.ConfirmAction("Êtes-vous sûr de vouloir supprimer",item.titleFr,"Valider","Supprimer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        globalService.showSpinner();
        globalService.deleteDetail('product',item.id).subscribe(
          deletedResponse => {
            let index = productDescriptions.indexOf(item);
            if (index > -1)
            {
              productDescriptions.splice(index, 1);
              globalService.hideSpinner();
              notification.showSuccess("Le détail ' "+item.titleFr+" ' a été supprimé avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            globalService.checkApiError(error);
            globalService.hideSpinner();
            notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });

  }
  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
  setItemsUpdatedRankList(items:[])
  {
    this.ItemsUpdatedRankList=[];
    items.forEach((value, index) =>
    {
      let rank=index+1;
      this.ItemsUpdatedRankList.push({"id":value['id'],"rank":rank});
    });
    this.productService.updateProductMediasDetailRank('ProductDetail','product_detail',this.ItemsUpdatedRankList)
      .subscribe(
        response => { console.log('response rank update ',response);
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
}

<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-sm-flex align-items-center justify-content-between infoBar">
            <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
              <span class="badge badge-soft-dark">{{this.action}} {{Product.code}} {{Product.nameFr}}</span>
            </h6>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                    <a routerLink="/products" class="mr-1">
                      <button class="btn btn-secondary waves-effect btn-label waves-light float-start modal-wait blocRightBottom"  type="button">
                        <i class="ft-arrow-left mr-1"></i> Liste des produits
                      </button>
                   </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section id="justified-tabs">
        <div class="row match-height">
          <div class="col-lg-12">
            <div class="card" style="height: auto;">
              <div class="card-content mb-3">
                <div class="card-body card-body-generalite">
                  <ul class="nav nav-tabs nav-justified" *ngIf="Product?.length != 0 && id && productCode">
                    <li class="nav-item">
                      <a (click)="goToUrl(id,productCode,'','product')" aria-controls="generalites" [attr.aria-expanded]="tab=='generalites'" [ngClass]="{'active': tab=='generalites'}"  class="nav-link" data-toggle="tab" href="#generalites"   id="generalites-tab">Généralités</a>
                    </li>
                    <li class="nav-item">
                      <a (click)="goToUrl(id,productCode,'packages','product')" aria-controls="packages" [attr.aria-expanded]="tab=='packages'" [ngClass]="{'active': tab=='packages'}"  class="nav-link" data-toggle="tab" href="#packages" id="packages-tab">Forfaits</a>
                    </li>
                    <li class="nav-item">
                      <a (click)="goToUrl(id,productCode,'descriptions','product')" aria-controls="descriptions" [attr.aria-expanded]="tab=='descriptions'" [ngClass]="{'active': tab=='descriptions'}"  class="nav-link" data-toggle="tab" href="#descriptions"  id="descriptions-tab">Descriptifs </a>
                    </li>
                    <li class="nav-item">
                      <a (click)="goToUrl(id,productCode,'photos','product')" aria-controls="photos" [attr.aria-expanded]="tab=='photos'" [ngClass]="{'active': tab=='photos'}"  class="nav-link" data-toggle="tab" href="#photos"  id="photos-tab">Photos</a>
                    </li>
                    <li class="nav-item">
                      <a (click)="goToUrl(id,productCode,'seo','product')" aria-controls="seo" [attr.aria-expanded]="tab=='seo'" [ngClass]="{'active': tab=='seo'}"  class="nav-link" data-toggle="tab" href="#seo"  id="seo-tab">SEO</a>
                    </li>
                  </ul>
                  <div class="tab-content" *ngIf="Product?.length != 0 && id && productCode">
                    <div [attr.aria-expanded]="tab=='generalites'" aria-labelledby="generalites-tab" [ngClass]="{'active show': tab=='generalites'}"  class="tab-pane" id="generalites"  role="tabpanel">
                      <app-generalites [productId]="id"  [productCode]="productCode" [Product]="Product"></app-generalites>
                    </div>
                    <div [attr.aria-expanded]="tab=='packages'" aria-labelledby="packages-tab" [ngClass]="{'active show': tab=='packages'}" class="tab-pane" id="packages" role="tabpanel">
                      <app-packages  [productId]="id" [Product]="Product" [productCode]="productCode"></app-packages>
                    </div>
                    <div [attr.aria-expanded]="tab=='descriptions'" aria-labelledby="descriptions-tab" [ngClass]="{'active show': tab=='descriptions'}" class="tab-pane" id="descriptions" role="tabpanel">
                      <app-descriptions  [productId]="id"  [productCode]="productCode"></app-descriptions>
                    </div>
                    <div [attr.aria-expanded]="tab=='photos'" aria-labelledby="photos-tab" [ngClass]="{'active show': tab=='photos'}" class="tab-pane" id="photos" role="tabpanel">
                      <app-photos [productId]="id" [Product]="Product"></app-photos>
                    </div>
                    <div [attr.aria-expanded]="tab=='seo'" aria-labelledby="seo-tab" [ngClass]="{'active show': tab=='seo'}" class="tab-pane" id="seo" role="tabpanel">
                    <app-seo [productId]="id" [productCode]="productCode"></app-seo>
                    </div>
                  </div>
                  <div class="tab-content" *ngIf="Product?.length == 0">
                      <img  class="LoaderImages" src="../assets/img/load.gif" alt="Loading...">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>


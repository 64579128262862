import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from "../../../shared/services/global.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../../shared/services/products.service";
import {Constantes} from "../../../shared/constantes";
import {NgForm} from "@angular/forms";
import {NotificationService} from "../../../shared/services/notification.service";
@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.css']
})
export class SeoComponent implements OnInit {
  readonly Constantes = Constantes;
  productSeoList: any = [];
  seoType: any = [];
  productSeo: any = [];
  entityCode='product';
  seoId:number=0;
  newSeo:string='';
  submitStatus:boolean=false;
  @Input() productId: number=0;
  @Input() productCode: string = '';
  url:string='';
  constructor(
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private notification: NotificationService,
    private productService:ProductsService
  ) { }
  ngOnInit(): void
  {
    this.seoId = this.activatedRouter.snapshot.params['seoId'];
    if (this.seoId && !isNaN(this.seoId))
    {
      this.getProductSeoListById();
    }
    this.getSeoType();
    this.getProductSeoList();
    //this.submitStatus=true;
    this.url='/product/'+this.productId+'/'+this.productCode+'/seo/'
  }
  getSeoType() {
    this.globalService.getParameterValue('', 'seo_type')
      .subscribe(
        seoType => {
          for (let item in seoType[0]['data']) {
            this.seoType.push(seoType[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getProductSeoList() {
    this.globalService.showSpinner();
    this.productSeoList= [];
    this.productService.getProductSeoContentsById(this.productId)
      .subscribe(
        productSeoList => {
          for (let item in productSeoList[0]['data']) {
            this.productSeoList.push(productSeoList[0]['data'][item]);
          }
          this.globalService.hideSpinner();
         },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );

  }
  getProductSeoListById() {

    this.productService.getProductSeoContentById(this.seoId)
      .subscribe(
        productSeo => {
            this.productSeo=productSeo;
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );

  }
  onSubmit(form:NgForm)
  {
    let metasType = form.value.metasType;
    let text= form.value.text;
    let seoId=null;
    if (form.valid)
    {
      this.globalService.showSpinner();
      if (this.seoId && !isNaN(this.seoId))
      {
        seoId=this.seoId;
      }
      this.productService.addEditProductSeoContent(text,metasType,this.productId,seoId)
        .subscribe(
          response => {
            this.globalService.hideSpinner();
            if (!isNaN(response['id']))
            {
              this.newSeo=response['text'];
              if (this.seoId==0)
              {
                this.notification.showSuccess("Le meta   '" +this.newSeo+ "'   a été ajouté avec succès","");
              }
              else
              {
                this.notification.showSuccess("La modification a été faite avec succès","");
              }
              this.getProductSeoList();
              setTimeout(function()
              {
                $("html, body").animate({ scrollTop: 0 }, "slow");
              }, 2200);
            }
          },
          error => {
            console.log('error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
    }

  }
  Delete(item:any,form:NgForm)
  {
    let productService=this.productService;
    let globalService=this.globalService;
    let productSeoList=this.productSeoList;
    let notification=this.notification;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir supprimer",item.text,"Valider","Supprimer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        globalService.showSpinner();
        productService.deleteProductSeoContent(item.id).subscribe(
          deletedResponse => {
            let index = productSeoList.indexOf(item);

            console.log('delete confirmed');
            if (index > -1)
            {
              productSeoList.splice(index, 1);
              globalService.hideSpinner();
              notification.showSuccess("Le meta ' "+item.text+" ' a été supprimé avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
              form.form.controls['text'].setValue('');
              form.form.controls['metasType'].setValue('');
              form.form.controls['metasType'].markAsTouched();
              form.form.controls['text'].markAsTouched();
            }
          },
          error => {
            globalService.checkApiError(error);
            globalService.hideSpinner();
            notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('delete  not confirmed');

      }
    });
  }
}

<form method="post" name="generalites" #generalitesForm="ngForm" (ngSubmit)="onSubmit(generalitesForm);">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                  <div class="col-lg-2">
                    <div class="mb-2">
                      <label for="code">Code<span class="etoile">*</span></label>
                      <input type="text" [ngClass]="{'is-invalid' : code.invalid  && (code.dirty || code.touched)}" #code="ngModel"  maxlength="{{Constantes.codeMaxlength}}" required minlength="3"  ngModel  [(ngModel)]="Product.code && Product.code"  class="form-control capitalize" id="code" name="code" placeholder="Code hôtel" readonly>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="name">Nom<span class="etoile">*</span></label>
                      <input type="text" [ngClass]="{'is-invalid' : nom.invalid  && (nom.dirty || nom.touched)}" #nom="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  [(ngModel)]="Product.nameFr && Product.nameFr" class="form-control productName" id="name" name="name" placeholder="Nom hôtel" >
                    </div>
                  </div>
                  <!-- Pays-->
                  <div class="col-lg-3">
                    <div class="form-floating mb-3">
                      <label>Pays</label>
                      <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                      <ng-select2 id="countrys"  name="countrys"  #countrys="ngModel"   ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2" [width]="value" [(ngModel)]="existedProductCountry"></ng-select2>
                    </div>
                  </div>
                  <!-- Ville-->
                  <div class="col-lg-3">
                    <div class="form-floating mb-3">
                      <label>Villes</label>
                      <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                      <ng-select2 id="citys"  name="citys"   #citys="ngModel"  ngModel  [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Villes'" [data]="listOfCityForCountry" class="form-control ng-select2" [width]="value" [(ngModel)]="existedProductCity"></ng-select2>
                    </div>
                  </div>
                </div>
                <div class="row" style="float: left;">
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label class="form-label" for="longitude">Longitude</label>
                      <input [ngClass]="{'is-invalid' : longitude.invalid  && (longitude.dirty || longitude.touched)}" #longitude="ngModel" minlength="5" pattern="^-?[0-9]+([\.,][0-9]+)*$"  ngModel class="form-control" id="longitude" name="longitude" placeholder="Longitude" type="text" [(ngModel)]="Product.longitude && Product.longitude">
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="mb-3">
                      <label for="latitude">Latitude</label>
                      <input [ngClass]="{'is-invalid' : latitude.invalid  && (latitude.dirty || latitude.touched)}" #latitude="ngModel"  minlength="5" pattern="^-?[0-9]+([\.,][0-9]+)*$"  ngModel class="form-control" id="latitude" name="latitude"   placeholder="Latitude" type="text" [(ngModel)]="Product.latitude && Product.latitude">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="mb-1">
                      <label for="minAge">Âge minimum</label>
                      <input type="number" [ngClass]="{'is-invalid' : minAge.invalid  && (minAge.dirty || minAge.touched)}" min="0" step="1" #minAge="ngModel" class="form-control" name="minAge" id="minAge" [(ngModel)]="Product.minAge && Product.minAge">
                      <span class="tinyText">Requis pour le voyage utilisé que dans le canal Veepee</span>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="mb-1">
                      <label for="tripAdvisorCode">Code TripAdvisor</label>
                      <input [ngClass]="{'is-invalid' : tripAdvisorCode.invalid  && (tripAdvisorCode.dirty || tripAdvisorCode.touched)}" #tripAdvisorCode="ngModel" pattern="[0-9]+" minlength="6" maxlength="6"   ngModel class="form-control" id="tripAdvisorCode" name="tripAdvisorCode"   placeholder="Code TripAdvisor" type="text" [(ngModel)]="Product.tripAdvisorCode && Product.tripAdvisorCode">
                      <span class="tinyText">Utilisé que dans le canal Veepee</span>
                    </div>
                  </div>
                </div>
                <div class="row" style="float: left;">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="tripAdvisorCode">URL vidéo</label>
                      <input [ngClass]="{'is-invalid' : URLVideo.invalid  && (URLVideo.dirty || URLVideo.touched)}" #URLVideo="ngModel"  minlength="3"  ngModel class="form-control" id="URLVideo" name="URLVideo"  [(ngModel)]="Product.videoUrl && Product.videoUrl"  placeholder="URL vidéo" type="text">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="URLMap">URL map</label>
                      <input [ngClass]="{'is-invalid' : URLMap.invalid  && (URLMap.dirty || URLMap.touched)}" #URLMap="ngModel"  minlength="3"  ngModel class="form-control" id="URLMap" name="URLMap" [(ngModel)]="Product.mapUrl && Product.mapUrl"  placeholder="URL Map" type="text">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="custom-switch mb-3 mt-3">
                      <input  ngModel type="checkbox" name="product_active" id="product_active" [attr.checked]="Product.isActive ? '': null" [(ngModel)]="Product.isActive && Product.isActive" class="custom-control-input form-control">
                      <label for="product_active" class="custom-control-label mr-1">
                        <span>Etat</span>
                      </label>
                    </div>
                  </div>
              </div>
          </div>
        </div>
          </div>
        <div class="col-lg-12">
          <div class="col-lg-3">
            <div class="mb-3" style="font-size: smaller;">
              <div class="d-flex justify-content-start" *ngIf="Product.createdAt">
                Création : &nbsp;<span class="badge badge-soft-info">{{Product.createdAt| date :  "dd/MM/yyyy à HH:mm"}}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="mb-3" style="font-size: smaller;">
              <div class="d-flex justify-content-start" *ngIf="Product.updatedAt">
                Modification : &nbsp;<span class="badge badge-soft-info">{{Product.updatedAt| date :  "dd/MM/yyyy à HH:mm"}}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 float-end d-flex flex-wrap gap-2 justify-content-end">
            <button type="submit"  [disabled]="generalitesForm.invalid" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom">
              <i class="ft-save"></i> Enregistrer
            </button>
          </div>
        </div>
        </div>
 </form>

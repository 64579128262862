import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {ProductsService} from "../services/products.service";
import {GlobalService} from "../services/global.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  User_email:string|null='';
  User_username:string|null='';
  nbAllProduitsManquants = 0;
  constructor(private productService: ProductsService,private globalService: GlobalService,) {}

  ngOnInit(): void
  {
    this.User_email=localStorage.getItem('User_email');
    this.User_username=localStorage.getItem('User_username');
    this.GetCountProduitsManquants();
    setInterval(() => { this.GetCountProduitsManquants(); }, 300000);
  }

  logOut()
  {
    localStorage.clear();
    document.location.href=environment.LoginUrl;
  }

  GetCountProduitsManquants()
  {
    console.log('*** recherche chaque 5 min produit non importer pour afficher la notification ****');
    this.productService.getCountProduitsManquants(null).subscribe(
      (response:any) => {
        this.nbAllProduitsManquants=response[0]['info'][0]['totalItems'];
        if (this.nbAllProduitsManquants > 0)
        {
          $('#activeNotification').show();
          $('#headerNotification').html('1');
        }
        else
        {
          $('#activeNotification').hide();
          $('#headerNotification').html('');
        }
      },
      (error:any) => {
        this.globalService.checkApiError(error);
      }
    )
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogListComponent } from './log-list/log-list.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxPaginationModule} from "ngx-pagination";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSortModule} from "@angular/material/sort";
import {NgSelect2Module} from "ng-select2";
import {SharedModule} from "../shared/shared.module";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerModule} from "ngx-spinner";

@NgModule({
  declarations: [
    LogListComponent
  ],
  exports: [
    LogListComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatInputModule,
    MatSortModule,
    NgSelect2Module,
    SharedModule,
    NgbPaginationModule,
    NgxSpinnerModule
  ]
})
export class LogModule { }

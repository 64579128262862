import {Component, Input, OnInit} from '@angular/core';
import {ProductsService} from "../../../shared/services/products.service";
import {GlobalService} from "../../../shared/services/global.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {Router} from "@angular/router";
@Component({
  selector: 'app-PackageTags',
  templateUrl: './package.tags.component.html',
  styleUrls: ['./package.tags.component.css']
})
export class PackageTagsComponent implements OnInit {
  PackageThemes: any = [];
  PackageFormules: any = [];
  PackageTags: any = [];
  PackageTagsCode: any = [];
  constructor(
    private productService: ProductsService,
    private router: Router,
    private globalService: GlobalService,
    private notification: NotificationService) {}
  @Input() packageCode: string = '';
  @Input() packageId: number = 0;
  @Input() Package: any = [];
  ngOnInit(): void {
    this.getPackageThemes();
    this.getPackageFormules();
    this.getPackageTags();
  }
  getPackageThemes()
  {
    this.globalService.getParameterValue('theme', 'product_tag')
      .subscribe(
        PackageThemes => {
          for (let item in PackageThemes[0]['data']) {
            this.PackageThemes.push(PackageThemes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageFormules()
  {
    this.globalService.getParameterValue('formule', 'product_tag')
      .subscribe(
        PackageFormules => {
          for (let item in PackageFormules[0]['data']) {
            this.PackageFormules.push(PackageFormules[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageTags() {
    //this.globalService.showSpinner();
    this.PackageTags= [];
    this.PackageTagsCode= [];
    this.productService.getProductTags(this.packageId)
      .subscribe(
        PackageTags => {
          for (let item in PackageTags[0]['data']) {
            this.PackageTags.push(PackageTags[0]['data'][item]);
            this.PackageTagsCode.push(PackageTags[0]['data'][item]['tag']['code']);
          }
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );
  }
  onChange(themeId: number,code:string,name:string,type:string)
  {
    this.globalService.showSpinner();
    let chechboxId =type+"_"+code;
    let rowId=0;
    // get rowId on this.PackageTags
      if (!$("#"+chechboxId).prop('checked'))
      {
        $("#"+chechboxId).attr("disabled", 'true');
        // call api delete
        for (let item in this.PackageTags)
        {
          if (this.PackageTags[item]['tag']['code']==code)
          {
            rowId=this.PackageTags[item]['id'];
          }
        }
        this.productService.deleteProductTag(rowId).subscribe(
          deletedResponse =>
          {
            this.globalService.hideSpinner();
            this.notification.showSuccess("Le tag ' "+name +" ' a été désélectionné avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
              this.getPackageTags();
              setTimeout(function()
              {
                $("#"+chechboxId).removeAttr("disabled");
              }, 1000);
          },
          error => {
            this.globalService.checkApiError(error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        $("#"+chechboxId).attr("disabled", 'true');
        // call api add
        this.globalService.showSpinner();
        this.productService.addProductTag(themeId,this.packageId)
          .subscribe(
            response => {
              if (response)
              {
                this.globalService.hideSpinner();
                this.notification.showSuccess("Le tag ' "+name +" '  a été sélectionné avec succès", "");
                this.getPackageTags();
                setTimeout(function()
                {
                  $("#"+chechboxId).removeAttr("disabled");
                }, 1000);
              }
            },
            error => {
              console.log('error ==>',error);
              this.globalService.hideSpinner();
              this.notification.showError(error['error']['hydra:description'],"");
            }
          );
      }
  }
}

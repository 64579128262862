<app-header></app-header>
<div class="wrapper">
  <app-right-side-bar [currentRoute]="currentRoute"></app-right-side-bar>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-overlay"></div>
      <div class="content-wrapper">
        <div class="row">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>

import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {GlobalService} from "../../shared/services/global.service";
import {NotificationService} from "../../shared/services/notification.service";
import {Constantes} from "../../shared/constantes";
@Component({
  selector: 'app-parameter-value-details',
  templateUrl: './parameter-value-details.component.html',
  styleUrls: ['./parameter-value-details.component.css']
})
export class ParameterValueDetailsComponent implements OnInit {
  defaultCodeValue=null;
  defaultNameValue='';
  defaultData1Value='';
  defaultData2Value='';
  defaultData3Value='';
  channels: any = [];
  productTypes: any = [];
  tagGroupe: any = [];
  channelId:any=0;
  productTypeId:any=0;
  defaultChannel:any=Constantes.defaultChannel;
  defaultProductTypeId:any=0;
  @Input() parameterValue: any = [];
  @Input() parameterId: number = 0;
  @Input() parameterCode: string = '';

  constructor(
    private globalService: GlobalService,
    private notification: NotificationService,
  ) { }
  ngOnInit(): void
  {
    console.log('parameterCode =', this.parameterCode);
    console.log('parameterValue = ',this.parameterValue);
    if(this.parameterCode=='product_detail')
    {
      this.getChannels();
    }

    if(this.parameterCode=='product_subtype')
    {
      this.getProductTypes();
    }

    if(this.parameterCode=='product_tag')
    {
      this.getTagGroupe();
    }

  }
  getChannels()
  {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => { console.log('channels',channels);
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }

  getProductTypes()
  {
    this.globalService.getParameterValue('', 'product_type')
      .subscribe(
        productTypes => { console.log('productTypes',productTypes);
          for (let item in productTypes[0]['data']) {
            this.productTypes.push(productTypes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }

  getTagGroupe()
  {
    this.globalService.getParameterValue('', 'tag_group')
      .subscribe(
        tagGroupe => { console.log('tagGroupe===>',tagGroupe);
          for (let item in tagGroupe[0]['data']) {
            this.tagGroupe.push(tagGroupe[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  onSubmit(form:NgForm)
  {
    this.globalService.hideSpinner();
    console.log('form = ',form);

    if(form.valid)
    {
      this.globalService.showSpinner();
      let name = form.value.name;
      let code = form.value.code;
      let data1 = form.value.data1;
      let data2 = form.value.data2;
      let data3 = form.value.data3;
      let parentId =null;
      if(this.parameterCode=='product_detail')
      {
         parentId = form.value.channels;
      }
      else if (this.parameterCode=='product_subtype')
      {
        parentId = form.value.productTypes;
      }
      else if (this.parameterCode=='product_tag')
      {
        parentId = form.value.tagGroupes;
      }
      else
      {
         parentId= null;
      }
      let parameterValueId:number=form.value.id;
      let isActive =false;
      let rank =form.value.rank;
      if (isNaN(parameterValueId) || parameterValueId==0)
      {
        parameterValueId = 0;
      }
      if ($("#isActive").prop('checked'))
      {
        isActive=true;
      }
      else
      {
        isActive=false;
      }
      this.globalService.addEditParameterValue(this.parameterId,parameterValueId, code, name, isActive, rank,data1,data2,data3,parentId)
        .subscribe(
          response => {
            console.log('response  add /edit parametres ' , response);
            this.globalService.hideSpinner();
            if (response)
            {
              if (parameterValueId && !isNaN(parameterValueId) && parameterValueId>0)
              {
                this.notification.showSuccess("La modification a été faite avec succès","");
              }
              else
              {
                this.notification.showSuccess("Le paramètre a été ajouté avec succès","");
                let link="/parameter/"+this.parameterCode+"/values";
                this.globalService.redirectToURl(link);
              }

              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            console.log('error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
    }
  }
}


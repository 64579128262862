import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from "../../../shared/services/global.service";
import {NotificationService} from "../../../shared/services/notification.service";
import {ProductsService} from "../../../shared/services/products.service";
import {ActivatedRoute} from "@angular/router";
import {Constantes} from "../../../shared/constantes";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
/******************* Drag and Drop sorting ***************************************/
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
/******************* Drag and Drop sorting ***************************************/
@Component({
  selector: 'app-PackageDescriptions',
  templateUrl: './package.descriptions.component.html',
  styleUrls: ['./package.descriptions.component.css']
})
export class PackageDescriptionsComponent implements OnInit {

 @ViewChild('pdfTable') invoiceElement!: ElementRef;
 @ViewChild('pdfTextPhotos') titlePhotosElement!: ElementRef;

  ExistedProductsPhotos: any = [];
  ExistedPhotos: any = [];
  ItemsUpdatedRankList: any = [];
  packageDescriptions: any = [];
  productDescriptions: any = [];
  ProductGenericDescriptions: any = [];
  PackageProductsID: any = [];
  packageId: number = 0;
  ElementId:number =0;
  loadingProductsDesciptions:boolean=true;
  channels: any = [];
  defaultChannel=Constantes.defaultChannel;
  channelId:any;
  @Input() packageCode: string = '';
  @Input() Package: any = [];
  @Input() existedPackageProducts: any = [];
  private q: any;
  constructor (
    private activatedRouter:ActivatedRoute,
    private productService: ProductsService,
    private globalService: GlobalService,
    private notificationService :NotificationService
  ) {}
  /******************* Drag and Drop sorting ***************************************/

  GetNewItemListAfterSortWithDragDrop (event: CdkDragDrop<string[]>)
  {
    console.log('event == ', event);
    if (event.previousContainer === event.container)
    {
      moveItemInArray(this.packageDescriptions, event.previousIndex, event.currentIndex);
      this.setItemsUpdatedRankList(this.packageDescriptions);
    }
    else
    {
      transferArrayItem(event.previousContainer.data,this.packageDescriptions,event.previousIndex,event.currentIndex);
      console.log(' transferArrayItem  productDescriptions ==> ', this.packageDescriptions);
    }
  }
  /******************* Drag and Drop sorting ***************************************/
  ngOnInit(): void
  {
    this.packageId = this.activatedRouter.snapshot.params['id'];
    this.getChannels();
    this.getPackageProductsId();
    this.getExistedPhotos();
    this.getExistedProductsPhotos();
  }
  getChannels() {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => {
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageProductsId()
  {
    this.PackageProductsID=[];
    this.productService.getPackageSegments(null,false,'', null,null,this.packageId,null,null)
      .subscribe(
        products => {
          for (let item in products[0]['data'])
          {
            this.PackageProductsID.push(products[0]['data'][item]['product']['id']);
          }
          this.getPackagesAllTypeOfDetails();
          this.getExistedProductsPhotos();
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackagesAllTypeOfDetails()
  {
    //this.globalService.showSpinner();
    this.packageDescriptions=[];
    let channelCode=$("#channels option:selected").attr("code");
    this.channelId=$("#channels").val();
    if (!channelCode)
    {
      this.channelId=this.defaultChannel;
    }
    this.productService.getPackagesAllTypeOfDetails( this.channelId,this.packageId)
      .subscribe(
        packageDescriptions => {
            this.packageDescriptions=packageDescriptions['data']['package_details'];
            this.productDescriptions=packageDescriptions['data']['accommodations_details'];
            this.ProductGenericDescriptions=packageDescriptions['data']['generic_details'];
            this.globalService.hideSpinner();
            this.loadingProductsDesciptions=false;
          console.log(' ***********************  product Descriptions ********************************  ==== ',this.productDescriptions);
          console.log(' ***********************  package Descriptions ********************************  ==== ',this.packageDescriptions);
          console.log(' ***********************  Product Generic Descriptions ********************************  ==== ',this.ProductGenericDescriptions);
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );

  }
  importDetailFromOtherProduct()
  {
    this.globalService.showSpinner();
    this.productDescriptions=[];
    let channelCode=$("#channels option:selected").attr("code");
    this.channelId=$("#channels").val();
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
      this.channelId=this.defaultChannel;
    }
    let fromProductId=$('#importedProductCode').val();
    console.log('fromProductId = ' ,fromProductId);
    this.globalService.importDetailFromOtherProduct(this.channelId,this.packageCode,fromProductId)
      .subscribe(
        ProductDescriptions => {
          this.getPackagesAllTypeOfDetails();
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );
  }
  getExistedProductsPhotos()
  {
    //this.globalService.showSpinner();
    this.ExistedProductsPhotos=[];
    let channelCode=$("#channelsPhotos option:selected").attr("code");
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
    }

    if (this.PackageProductsID && this.PackageProductsID.length > 0)
    {
      for (let item in this.PackageProductsID)
      {
        this.productService.getProductMedia(this.PackageProductsID[item],channelCode)
          .subscribe(
            ExistedProductsPhotos => {
              for (let item in ExistedProductsPhotos[0]['data']) {
                this.ExistedProductsPhotos.push(ExistedProductsPhotos[0]['data'][item]);
              }
              this.globalService.hideSpinner();
            },
            error => {
              this.globalService.checkApiError(error);
              this.globalService.hideSpinner();
            }
          );
      }
    }
console.log('this.ExistedProductsPhotos ====> ', this.ExistedProductsPhotos);
  }
  getExistedPhotos()
  {
    //this.globalService.showSpinner();
    let channelCode=$("#channelsPhotos option:selected").attr("code");
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
    }
    this.ExistedPhotos=[];
    this.productService.getProductMedia(this.packageId,channelCode)
      .subscribe(
        ExistedPhotos => {

          for (let item in ExistedPhotos[0]['data'])
          {
            this.ExistedPhotos.push(ExistedPhotos[0]['data'][item]);
          }
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );
  }
  preparePdf(): void
  {
    html2canvas(this.invoiceElement.nativeElement,{useCORS: true}).then(canvas => {

      const contentDataURL = canvas.toDataURL('image/jpg')
      let margin = 2;
      let imgWidth = 210 - 2*margin;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let doc = new jsPDF('p', 'mm');
      let position = 0;

      doc.addImage(contentDataURL, 'jpg', margin, position, imgWidth, imgHeight - (margin * 3));

      heightLeft -= pageHeight;
      console.log('heightLeft = >', heightLeft);
      console.log('pageHeight = >', pageHeight);

      while (heightLeft >= 0)
      {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'jpg', margin, position, imgWidth, imgHeight - (margin * 3));
        heightLeft -= pageHeight;
      }
      /************************************* ADD IMAGES *****************************************************/
      doc.addPage();
      console.log('position = >', position);
      let addedImageWidth=60;
      let addedImageHeight=48;
      let addedImagePositionHauteur=10;
      let addedImageLeft=6;
      let addedFirstImageLeft=6;
      let img = new Image();
      img.crossOrigin = "Anonymous";

      //cors-origin use
      //let imgurl2='https://bo.thalassoto.com/images/VPG/Relax-Otel-petit-dejeuner.jpg';

      img.src = 'assets/img/Relax-Otel-piscine-exterieure.jpg';
      doc.setTextColor(251, 149, 119);
      doc.setFontSize(10);
      doc.setFont("sans-serif", "bold");
      doc.text('Photos', margin+3, 6,);

      doc.addImage(img, "JPEG", margin+addedFirstImageLeft+(addedImageWidth*0), addedImagePositionHauteur, addedImageWidth, addedImageHeight, "", 'FAST');
      doc.addImage(img, "JPEG", margin+(addedImageLeft*2)+(addedImageWidth*1), addedImagePositionHauteur, addedImageWidth, addedImageHeight, "", 'FAST');
      doc.addImage(img, "JPEG", margin+(addedImageLeft*3)+(addedImageWidth*2), addedImagePositionHauteur, addedImageWidth, addedImageHeight, "", 'FAST');

      doc.addImage(img, "JPEG", margin+addedFirstImageLeft+(addedImageWidth*0), addedImageHeight+(addedImagePositionHauteur*1.5), addedImageWidth, addedImageHeight, "", 'FAST');
      doc.addImage(img, "JPEG", margin+(addedImageLeft*2)+(addedImageWidth*1), addedImageHeight+(addedImagePositionHauteur*1.5), addedImageWidth, addedImageHeight, "", 'FAST');
      doc.addImage(img, "JPEG", margin+(addedImageLeft*3)+(addedImageWidth*2), addedImageHeight+(addedImagePositionHauteur*1.5), addedImageWidth, addedImageHeight, "", 'FAST');

      /************************************* ADD IMAGES *****************************************************/

      let pdfName=this.Package.nameFr;
      doc.save(pdfName);
    });
  }
  createPDF(){
    return new Promise(resolve => {
      $('#pdfTable').show();
      this.preparePdf();
      setTimeout(()=>{
        resolve('resolved');
      },3000);
      $('#pdfTable').hide();
    });
  }
  genererPDF()
  {
   this.globalService.showSpinner();
    this.createPDF().then(()=>{this.globalService.hideSpinner();})
  }
  Delete(item:any)
  {
    let globalService=this.globalService;
    let productDescriptions=this.packageDescriptions;
    let notification=this.notificationService;
    this.notificationService.ConfirmAction("Êtes-vous sûr de vouloir supprimer",item.title_fr,"Valider","Supprimer !","", function (confirmed:any)
    {
      if (confirmed)
      { globalService.showSpinner();
        globalService.deleteDetail('product',item.id).subscribe(
          deletedResponse => {
            let index = productDescriptions.indexOf(item);
            if (index > -1)
            {
              productDescriptions.splice(index, 1);
              globalService.hideSpinner();
              notification.showSuccess("Le détail ' "+item.title_fr+" ' a été supprimé avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            globalService.checkApiError(error);
            globalService.hideSpinner();
            notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  ShowLogs(ElementId: number)
  {
    this.ElementId = ElementId;
  }
  setItemsUpdatedRankList(items:[])
  {
    this.ItemsUpdatedRankList=[];
    items.forEach((value, index) =>
    {
      let rank=index+1;
      this.ItemsUpdatedRankList.push({"id":value['id'],"rank":rank});
    });
    this.productService.updateProductMediasDetailRank('ProductDetail','product_detail',this.ItemsUpdatedRankList)
      .subscribe(
        response => { console.log('response rank update ',response);
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
}

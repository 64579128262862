import {Component, OnInit} from '@angular/core';
import {GlobalService} from "../../shared/services/global.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Constantes} from "../../shared/constantes";
import {NgForm} from "@angular/forms";
import {NotificationService} from "../../shared/services/notification.service";
import {ProductsService} from "../../shared/services/products.service";
import {Location} from "@angular/common";
@Component({
  selector: 'app-descriptions',
  templateUrl: './description-details.component.html',
  styleUrls: ['./description-details.component.css']
})
export class DescriptionDetailsComponent implements OnInit{
  readonly Constantes = Constantes;
  detailId: number = 0;
  type: string = '';
  bodyInvalid:boolean=false;
  description: any = [];
  Product: any = [];
  productFamillesDetail: any = [];
  /************************** CKEDITOR  *******************************/
  CKEDITOR: any;
  /************************** CKEDITOR  *******************************/
  newDetailName:string="";
  channels: any = [];
  channelId:any=0;
  id: number|any;
  defaultChannel :any =Constantes.defaultChannel;
  GlobalService=this.globalService;
  /*********** for generic description ********************/
  listOfCountry :any = [];
  listOfCityForCountry :any = [];
  oclub: any = [];
  isLoading = false;
  value='resolve';
  allowClear=true;
  required=true;
  requiredCountry=true;
  disabled=false;
  localiteParentIDOfDetail=0;
  /*********** for generic description ********************/
  constructor(
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private productService: ProductsService,
    private notification: NotificationService,
    private location: Location,
  ) { }
  ngOnInit(): void
  {
    /************************** CKEDITOR  *******************************/
    delete CKEDITOR.instances["#body"];
    CKEDITOR.replace('body', {
      customConfig : '/assets/js/cke_custom_basic_dev_config_full.js',
      height : '350px'
    });
    /************************** CKEDITOR  *******************************/
   this.detailId = this.activatedRouter.snapshot.params['detailId'];
   this.type = this.activatedRouter.snapshot.params['type'];
   this.id = this.activatedRouter.snapshot.params['id'];
   this.channelId = this.activatedRouter.snapshot.params['channelId'];
   if (!this.detailId || isNaN(this.detailId))
    {
      this.router.navigate(['/' + this.type]);
    }
    /*********** for product info : get code and name ********/
    if (this.type && this.type !='generic-descriptions' && (this.id || !isNaN(this.id)))
    {
      this.getProduct();
    }
    /*********** for generic description ********************/
   /*********** for generic description ********************/
    if (this.type && this.type =='generic-descriptions')
    {
      this.getCountry();
      this.getOclubList();
    }
    /*********** for generic description ********************/
    this.getProductFamillesDetail(this.channelId);
    this.geDescription();
    this.getChannels();
  }
  getChannels() {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => {
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getProductFamillesDetail(channelId:number)
  {
    console.log('defaultChannel',this.defaultChannel);
    console.log('channelId',channelId);
    this.productFamillesDetail=[];
    this.globalService.showSpinner();
    this.globalService.getParameterValue('', 'product_detail',true,'',channelId)
      .subscribe(
        productFamillesDetail => {
          this.globalService.hideSpinner();
          this.productFamillesDetail.push({'id':'','text':'famille'});
          for (let item in productFamillesDetail[0]['data'])
          {
            this.productFamillesDetail.push({'id':productFamillesDetail[0]['data'][item]['id'],'text':productFamillesDetail[0]['data'][item]['nameFr']});
          }
          if (channelId == this.defaultChannel)
          {
            // ajout la possiblité de rien choisir dans la famille si canal= generic
            this.productFamillesDetail.unshift({'id':0,'text':'Aucun'});
            console.log('this.productFamillesDetail =+>',this.productFamillesDetail);
          }

        },
        error => {
          this.globalService.hideSpinner();
          this.globalService.checkApiError(error);
        }
      );
  }
  /*********** for product info : get code and name ***********/
  getProduct()
  {
    this.globalService.showSpinner();
    this.productService.getProduct(this.id).subscribe(
      product => {
        this.Product = product;
      },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }
  /*********** for generic description ********************/
  getCountry() {
    this.globalService.getCountry()
      .subscribe(
        Localities => { console.log('==> Country ==>  ',Localities);
          for (let countryItem in Localities[0]['data'])
          {
            if (Localities[0]['data'][countryItem]['type']['code']== 'country')
            {
              this.listOfCountry.push({'id':Localities[0]['data'][countryItem]['id'],'text':Localities[0]['data'][countryItem]['nameFr']+' ('+Localities[0]['data'][countryItem]['code']+')'});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getCityOfCountry(mainParentId:any)
  {
    this.globalService.getCityOfCountryByID(mainParentId)
      .subscribe(
        CityOfCountry => {  console.log('CityOfCountry ==> ',CityOfCountry);
          this.listOfCityForCountry=[];
          for (let cityItem in CityOfCountry[0]['data'])
          {
            if (CityOfCountry[0]['data'][cityItem]['type']['code']== 'city')
            {
              this.listOfCityForCountry.push({'id':CityOfCountry[0]['data'][cityItem]['id'],'text':CityOfCountry[0]['data'][cityItem]['nameFr']});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getOclubList() {
    this.globalService.getParameterValue('oclub_group', '')
      .subscribe(
        oclub => {
          for (let item in oclub[0]['data']) {
            this.oclub.push(oclub[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  /*********** for generic description ********************/
  geDescription()
  {
    this.globalService.showSpinner();
    this.globalService.getOneDescriptionById(this.detailId,this.type)
      .subscribe(
        Description => {
          this.description = Description; console.log(' this.description===>', this.description);
          if (!this.description["type"])
          {
            this.description.type = {id: null};
          }

          /************* si oclub, le bouton checkbox sera coché***********/
          if (this.description["oclub"])
          {
            $(".checkboxOclub").prop('checked', true);
            this.isOclub();
          }
          console.log('this.description ',this.description )
          /************************** CKEDITOR  *******************************/
          CKEDITOR.instances["body"].setData(this.description.textFr);
          /************************** CKEDITOR  *******************************/

          if (this.type == 'generic-descriptions')
          {

            if (!this.description['localityParent'])
            {
              // si pays uniquement sans ville
              console.log('il ya un parent');
              this.localiteParentIDOfDetail=this.description.locality.id;
              this.getCityOfCountry(this.localiteParentIDOfDetail);
            }
            else
            {console.log('pas de parent');
              // si ville et pays
              this.localiteParentIDOfDetail=this.description.localityParent.id;
              this.getCityOfCountry(this.localiteParentIDOfDetail);
            }

          }

          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );

  }
  onSubmit(form:NgForm)
  {
    /************************** CKEDITOR  *******************************/
    let bodyContent= CKEDITOR.instances["body"].getData();
    /************************** CKEDITOR  *******************************/
    console.log('bodyContent.length ==>',bodyContent.length);
    if (bodyContent.length<15)
    {
      this.bodyInvalid=true;
    }
    else
    {
      this.bodyInvalid=false;
    }
    if (form.valid && bodyContent.length > 15 && this.type.length)
    {
      this.globalService.showSpinner();
      let titleFr = form.value.title;
      let typeId=form.value.family;
      let rank=form.value.rank;
      let objectIdentity=null;
      let oclub=null;
      let entity='product';
      let channelId =form.value.channels;
      if (this.type == 'generic-descriptions')
      {
        entity='locality';
        oclub=form.value.oclub
        /************************** locality manager *****************************/

        let countrys=form.value.countrys;
        let citys=form.value.citys;
        console.log('citys = ',citys);
        console.log('countrys = ',countrys);
        if (citys !== null && citys.length>0)
        {
          objectIdentity=citys;
        }
        else
        {
          objectIdentity=countrys;
        }
        /************************** locality manager *****************************/
      }
      if (typeId == 0)
      {
        typeId = null;
      }

      if ($(".checkboxOclub").is(':checked'))
      {
        console.log ('radio oclub is checked');
      }
      else
      {
        oclub=null;
      }


      this.globalService.addEditDetails(entity, objectIdentity, titleFr, bodyContent, typeId, rank,oclub,this.detailId,channelId)
        .subscribe(
          response => {
            if (response)
            {
              let location=this.location;
              this.globalService.hideSpinner();
              this.notification.showSuccess("La modification a été faite avec succès","");
             $("html, body").animate({ scrollTop: 0 }, "slow");
              setTimeout(function()
              {
                $("html, body").animate({ scrollTop: 0 }, "slow");
              }, 2200);
              setTimeout(function()
              {
                location.back();
              }, 2200);
            }
          },
          error => {
            console.log('error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
    }

  }

  isOclub()
  {
    $('.oclubBloc').toggle();
    $('.citysBloc').toggle();
    $('.countrysBloc').toggle();
    /*********** gestion oClube/pays : required or not : l'un des deux est obligatoire ************/

    if ($(".checkboxOclub").is(':checked'))
    {
      console.log ('radio oclub is checked');
      this.requiredCountry=false;
    }
    else
    {
      console.log ('radio oclub is UNchecked');
      this.requiredCountry=true;
    }
    this.setOclubDefaultValue();
    /*********** gestion oClube/pays : required or not : l'un des deux est obligatoire ************/
  }

  setOclubDefaultValue()
  {
    let text1 = 'Tous';
    $("#oclub option").filter(function() {
      return $(this).text() == text1;
    }).prop('selected', true);
  }
}

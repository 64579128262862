<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
      <div class="col-12">
        <div class="d-sm-flex align-items-center justify-content-between infoBar">
          <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
            <span class="badge badge-soft-dark">Nouveau produit</span>
          </h6>
        </div>
      </div>
    </div>
      <section id="justified-tabs">
        <div class="row match-height">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle mb-1" *ngIf="name.value?.length>0" [innerHTML]="name.value"></h4>
                <form method="post" name="productNew" #productNewForm="ngForm" (ngSubmit)="onSubmit(productNewForm);">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-2">
                              <div class="mb-3">
                                <label for="code">Code produit<span class="etoile">*</span></label>
                                <input type="text" [ngClass]="{'is-invalid' : code.invalid  && (code.dirty || code.touched)}" #code="ngModel"  maxlength="{{Constantes.codeMaxlength}}" required minlength="3"  ngModel  class="form-control capitalize" id="code" name="code" placeholder="Code produit">
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="name">Nom<span class="etoile">*</span></label>
                                <input type="text" [ngClass]="{'is-invalid' : name.invalid  && (name.dirty || name.touched)}" #name="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  class="form-control productName" id="name" name="name" placeholder="Nom produit" >
                              </div>
                            </div>
                            <!-- Pays-->
                            <div class="col-lg-3">
                              <div class="form-floating mb-3">
                                <label>Pays</label>
                                <img *ngIf="listOfCountry?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
                                <ng-select2 id="countrys"  name="countrys"  #countrys="ngModel"   ngModel *ngIf="listOfCountry?.length != 0" [disabled]="disabled"  [allowClear]="allowClear" [placeholder]="'Pays'" (valueChanged)="getCityOfCountry($event)" [data]="listOfCountry" class="form-control ng-select2" [width]="value" ></ng-select2>
                              </div>
                            </div>
                            <!-- Ville-->
                            <div class="col-lg-3">
                              <div class="form-floating mb-3">
                                <label>Villes</label>
                                <img *ngIf="listOfCityForCountry?.length == 0" class="LoaderImages hideThis" src="../assets/img/load.gif">
                                <ng-select2 id="citys"  name="citys"   #citys="ngModel"   ngModel  [disabled]="disabled" [allowClear]="allowClear" [placeholder]="'Villes'" [data]="listOfCityForCountry" class="form-control ng-select2" [width]="value" ></ng-select2>
                              </div>
                            </div>
                          </div>
                          <div class="row" style="float: left;">
                            <div class="col-lg-2">
                              <div class="mb-3">
                                <label class="form-label" for="longitude">Longitude</label>
                                <input [ngClass]="{'is-invalid' : longitude.invalid  && (longitude.dirty || longitude.touched)}" #longitude="ngModel"  minlength="5" pattern="^-?[0-9]+([\.,][0-9]+)*$"  ngModel class="form-control" id="longitude" name="longitude" placeholder="Longitude" type="text" >
                              </div>
                            </div>
                            <div class="col-lg-2">
                              <div class="mb-3">
                                <label for="latitude">Latitude</label>
                                <input [ngClass]="{'is-invalid' : latitude.invalid  && (latitude.dirty || latitude.touched)}" #latitude="ngModel" minlength="5"  pattern="^-?[0-9]+([\.,][0-9]+)*$" ngModel class="form-control" id="latitude" name="latitude" placeholder="Latitude" type="text" >
                              </div>
                            </div>
                            <div class="col-lg-3">
                              <div class="mb-1">
                                <label for="minAge">Âge minimum</label>
                                <input type="number" min="0" step="1" class="form-control" name="minAge" id="minAge" ngModel #minAge="ngModel">
                                <span class="tinyText">Requis pour le voyage utilisé que dans le canal Veepee</span>
                              </div>
                            </div>
                            <div class="col-lg-5">
                              <div class="mb-1">
                                <label for="tripAdvisorCode">Code TripAdvisor</label>
                                <input [ngClass]="{'is-invalid' : tripAdvisorCode.invalid  && (tripAdvisorCode.dirty || tripAdvisorCode.touched)}" #tripAdvisorCode="ngModel"  pattern="[0-9]+" minlength="6" maxlength="6"   ngModel class="form-control" id="tripAdvisorCode" name="tripAdvisorCode"   placeholder="Code TripAdvisor" type="text">
                                <span class="tinyText">Utilisé que dans le canal Veepee</span>
                              </div>
                            </div>
                          </div>
                          <div class="row" style="float: left;">
                            <div class="col-lg-5">
                              <div class="mb-3">
                                <label for="tripAdvisorCode">URL vidéo</label>
                                <input [ngClass]="{'is-invalid' : URLVideo.invalid  && (URLVideo.dirty || URLVideo.touched)}" #URLVideo="ngModel"  minlength="3"  ngModel class="form-control" id="URLVideo" name="URLVideo"   placeholder="URL vidéo" type="text">
                              </div>
                            </div>
                            <div class="col-lg-5">
                              <div class="mb-3">
                                <label for="tripAdvisorCode">URL map</label>
                                <input [ngClass]="{'is-invalid' : URLMap.invalid  && (URLMap.dirty || URLMap.touched)}" #URLMap="ngModel"  minlength="3"  ngModel class="form-control" id="URLMap" name="URLMap"   placeholder="URL Map" type="text">
                              </div>
                            </div>
                            <div class="col-lg-2">
                              <div class="custom-switch mb-3 mt-3">
                                <input  ngModel type="checkbox" name="product_active" id="product_active"  [(ngModel)]="checked"  class="custom-control-input form-control">
                                <label for="product_active" class="custom-control-label mr-1">
                                  <span>Etat</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 float-end mt-1">
                    <div class="d-flex flex-wrap gap-2 justify-content-end">
                      <button routerLink="/products" class="btn btn-info waves-effect btn-label waves-light modal-wait blocRightBottom mr-2" type="button">
                        <i class="ft-arrow-left"></i> Annuler
                      </button>
                      <button type="submit" [disabled]="productNewForm.invalid" class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom">
                        <i class="ft-save"></i> Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

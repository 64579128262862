import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ArchiveListComponent} from "./archive-list/archive-list.component";
import {RouterModule, Routes} from "@angular/router";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatSortModule} from "@angular/material/sort";
import {NgSelect2Module} from "ng-select2";
import {SharedModule} from "../shared/shared.module";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FileUploadModule} from "ng2-file-upload";
import {NgxSpinnerModule} from "ngx-spinner";

const routes: Routes = [
  {path: 'archives', component:ArchiveListComponent,pathMatch:"full"},
  {path: 'archives/:typeArchive', component: ArchiveListComponent}
];
@NgModule({
  declarations: [ArchiveListComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatInputModule,
    [RouterModule.forChild(routes)],
    MatSortModule,
    NgSelect2Module,
    SharedModule,
    NgbPaginationModule,
    FileUploadModule,
    NgxSpinnerModule
  ]
})
export class ArchiveModule { }

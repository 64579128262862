<div class="content">
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-sm-flex align-items-center justify-content-between infoBar">
            <h6 class="mb-sm-0 text-white ml-2 title-header headerGlobalTitle">
              <span class="badge badge-soft-dark">Nouveau forfait</span>
            </h6>
          </div>
        </div>
      </div>
      <section id="justified-tabs">
        <div class="row match-height">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <form method="post" name="generalites" #generalitesForm="ngForm" (ngSubmit)="onSubmit(generalitesForm);" >
                  <div class="content" >
                    <div class="page-content" >
                      <div class="container-fluid">
                        <div class="row"  >
                          <div class="invoice-title">
                            <div class="mb-0">
                              <h4 class="btn gradient-purple-bliss shadow-z-1-hover blocTitle" *ngIf="name.value?.length>0" [innerHTML]="name.value"></h4>
                            </div>
                          </div>
                          <div class="col-xl-8">
                            <div class="card">
                              <div class="card-body">
                                <!-- bloc 1 -->
                                <div class="row">
                                  <!-- code Package -->
                                  <div class="col-lg-4">
                                    <div class="mb-3">
                                      <label for="code">Code forfait<span class="etoile">*</span></label>
                                      <input type="text" [ngClass]="{'is-invalid' : code.invalid  && (code.dirty || code.touched)}" #code="ngModel"  maxlength="{{Constantes.codeMaxlength}}" required minlength="3"  ngModel  [(ngModel)]="Package.code && Package.code"  class="form-control capitalize" id="code" name="code" placeholder="Code forfait">
                                    </div>
                                  </div>
                                  <!-- Nom Package -->
                                  <div class="col-lg-8">
                                    <div class="mb-3">
                                      <label for="name">Nom<span class="etoile">*</span></label>
                                      <input type="text" [ngClass]="{'is-invalid' : name.invalid  && (name.dirty || name.touched)}" #name="ngModel"  maxlength="{{Constantes.nameMaxlength}}" required minlength="3"  ngModel  [(ngModel)]="Package.nameFr && Package.nameFr" class="form-control productName" id="name" name="name" placeholder="Nom Ffrfait" >
                                    </div>
                                  </div>
                                </div>
                                <!-- .bloc 1 -->
                                <!-- bloc 2 -->
                                <div class="row">
                                  <!-- Nombre de nuits -->
                                  <div class="col-lg-3">
                                    <div class="mb-3">
                                      <label for="durationNights">Nombre de nuits<span class="etoile">*</span></label>
                                      <input type="number" [ngClass]="{'is-invalid' : durationNights.invalid  && (durationNights.dirty || durationNights.touched)}" #durationNights="ngModel" min="0" step="1" required  ngModel  [(ngModel)]="Package.durationNights && Package.durationNights" class="form-control numberZero" (change)="durationTransformFormat($event);" id="durationNights" name="durationNights" placeholder="Nbr de nuits" >
                                    </div>
                                  </div>
                                  <!-- Nombre de jours -->
                                  <div class="col-lg-3">
                                    <div class="mb-3">
                                      <label for="durationDays">Nombre de jours<span class="etoile">*</span></label>
                                      <input type="number" [ngClass]="{'is-invalid' : durationDays.invalid  && (durationDays.dirty || durationDays.touched)}" #durationDays="ngModel" min="0" step="1" required  ngModel  [(ngModel)]="Package.durationDays && Package.durationDays" class="form-control numberZero" id="durationDays" (change)="durationTransformFormat($event);" name="durationDays" placeholder="Nbr de jours" >
                                    </div>
                                  </div>
                                </div>
                                <!-- .bloc 2 -->
                                <!-- bloc 3 -->
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="mb-3">
                                      <label >Introduction</label>
                                      <div [ngClass]="{'isInvalid' : bodyInvalid}">
                                        <textarea ngModel  id="body" name="body" placeholder="Introduction..." ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row" style="clear: both;">
                                  <div class="col-lg-6">
                                    <div class="custom-switch mb-3">
                                      <input ngModel  type="checkbox" name="product_active"  id="product_active" [attr.checked]="Package.isActive ? '': null"  [(ngModel)]="checked" class="custom-control-input form-control">
                                      <label for="product_active" class="custom-control-label mr-1">
                                        <span>Etat</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-lg-3">
                                    &nbsp;
                                  </div>
                                  <div class="col-lg-3">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <!-- end card body -->
                            </div>
                            <!-- end card -->
                          </div>
                          <div class="col-lg-4">
                            <div class="card">
                              <div class="card-body addProductOverFlow">
                                <div class="row">
                                  <div *ngIf="existedPackageProducts.length ==0" class="alert alert-danger alert-dismissible mb-2 warningZone" role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                      <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
                                    </button>
                                    <span>Veuillez affecter au moins un produit !</span>
                                  </div>
                                  <div class="col-lg-11 mb-2">
                                    <label>Produits liés</label>
                                  </div>
                                  <div class="col-lg-1 mb-2">
                                    <a *ngIf="addNew==false" class="btn btn-floating halfway-fab btn-light btnAddCircle" style="margin-top: -5px !important;" (click)="addNewProduct()"><i class="ft-plus"></i></a>
                                    <a *ngIf="addNew==true" class="btn btn-floating halfway-fab btn-light btnAddCircle" (click)="hideNewProduct()"><i class="ft-minus"></i></a>
                                  </div>
                                  <div >
                                    <div class="col-lg-12" *ngIf="addNew">
                                      <div class="mb-1">
                                        <mat-form-field>
                                          <input ngModel  [formControl]="searchProductName" placeholder="Code ou nom de l'hôtel" [matAutocomplete]="auto" aria-label="State" class="form-control autocompleteInput"  matInput type="text" value="">
                                          <mat-autocomplete #auto="matAutocomplete" [displayWith]="productDisplayFormat" (optionSelected)="productsOptionSelected($event.option.value)">
                                            <mat-option *ngIf="isLoading" class="is-loading">...</mat-option>
                                            <ng-container *ngIf="!isLoading">
                                              <mat-option *ngFor="let product of products" [value]="product">
                                                <span>{{product.nameFr}} ({{product.code}})</span>
                                              </mat-option>
                                            </ng-container>
                                          </mat-autocomplete>
                                        </mat-form-field>
                                      </div>
                                    </div>
                                    <div class="existedProduct col-lg-12" *ngIf="existedPackageProducts?.length != 0">
                                      <button *ngFor="let existedProduct of existedPackageProducts" class="btn btn-danger blockLocalityBadge" type="button">
                                        <span id="newProductBadge_{{existedProduct.code}}">
                                          <a routerLink="/product/{{existedProduct.id}}/{{existedProduct.code}}" class="clearLinkStyle"  target="_blank" title="Editer">
                                            {{existedProduct.nameFr}} <span class="ProductTypebadges">({{existedProduct.code}})</span></a> &nbsp;<span class="badge badge-light productBadge" style="cursor: pointer !important;" (click)="removeThisProduct(existedProduct.code)"><i class="fa-solid fa-xmark closeproductBadge"></i></span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <!-- end card body -->
                            </div>
                          </div>
                          <!-- end col -->
                        </div>
                        <div class="col-lg-12 float-end">
                          <div class="d-flex flex-wrap gap-2 justify-content-end">
                            <button routerLink="/packages" class="btn btn-info waves-effect btn-label waves-light modal-wait blocRightBottom mr-2" type="button">
                              <i class="ft-arrow-left"></i> Annuler
                            </button>
                            <button [disabled]="generalitesForm.invalid || existedPackageProducts?.length == 0"  class="btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit">
                              <i class="ft-save"></i> Enregistrer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>


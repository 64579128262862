import {NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import {ProductListComponent } from './product-list/product-list.component';
import {NgxPaginationModule} from "ngx-pagination";
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from "@angular/forms";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatInputModule} from "@angular/material/input";
import {MatSortModule} from '@angular/material/sort';
import {ProductDetailsComponent } from './product-details/product-details.component';
import {RouterModule, Routes} from "@angular/router";
import { GeneralitesComponent } from './product-details/generalites/generalites.component';
import { DescriptionsComponent } from './product-details/descriptions/descriptions.component';
import { PhotosComponent } from './product-details/photos/photos.component';
import { SeoComponent } from './product-details/seo/seo.component';
import { NgSelect2Module } from 'ng-select2';
import { ProductNewComponent } from './product-new/product-new.component';
import {SharedModule} from "../shared/shared.module";
import {PackagesComponent} from "./product-details/packages/packages.component";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FileUploadModule} from "ng2-file-upload";
import { NgxSpinnerModule } from 'ngx-spinner';
import {LogModule} from "../log/log.module";
import { DragDropModule } from '@angular/cdk/drag-drop';
const routes: Routes = [
  {path: 'product/new', component: ProductNewComponent,pathMatch:"full"},
  {path: 'product/:id/:code', component: ProductDetailsComponent},
  {path: 'product/:id/:code/:tab/:action', component: ProductDetailsComponent},
  {path: 'product/:id/:code/:tab', component: ProductDetailsComponent},
  {path: 'product/:id/:code/:tab/:seoId', component: ProductDetailsComponent},
  {path: 'products', component:ProductListComponent}
];


@NgModule({
  declarations: [
    ProductListComponent,
    ProductDetailsComponent,
    GeneralitesComponent,
    PackagesComponent,
    DescriptionsComponent,
    PhotosComponent,
    SeoComponent,
    ProductNewComponent


  ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatInputModule,
        [RouterModule.forChild(routes)],
        MatSortModule,
        NgSelect2Module,
        SharedModule,
        NgbPaginationModule,
        FileUploadModule,
        NgxSpinnerModule,
        LogModule,
        DragDropModule
    ]
})
export class ProductModule { }

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastr: ToastrService) { }
  showSuccess(message: string | undefined, title: string | undefined){
    this.toastr.success(message, title,{ "closeButton": true,timeOut: 60000,tapToDismiss:false})
  }
  showError(message: string | undefined, title: string | undefined){
    this.toastr.error(message, title,{ "closeButton": true,timeOut: 60000,tapToDismiss:false})
  }
  hideNotification()
  {
    this.toastr.clear();
  }
  ConfirmAction(title:string|null,text:string|null,confirmButtonText:string|null,confirmedTitle:string|null,confirmedText:string|null,callback:any)
  {
    swal.fire({
      title: ''+title,
      text: '" '+text+' "',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: ''+confirmButtonText,
      cancelButtonText:'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    }).then
    (
      function (result)
      {
        if (result.value)
        {
          /*swal.fire({
            icon: "success",
            title: ''+confirmedTitle,
            text: ''+confirmedText,
            showConfirmButton: false,
            showCloseButton: true,
            customClass:
              {
                confirmButton: 'btn btn-success'
              },
         }); */
          callback(result && result.value == true);
        }
        else
        {
          callback(result && result.value == false);
        }
      }
    );
  }
}

<div class="content-wrapper">
  <section id="minimal-statistics-bg">
    <div class="row">
      <div class="col-12 mb-4 card gradient-king-yna card-shadow" style="color: white">
        <div class="content-header mb-1 mt-2">Tableau de bord</div>
        <p class="content-sub-header mb-1" style="color: white">Une vue d'ensemble en temps réel.</p>
      </div>
    </div>
    <div class="row">
      <a routerLink="/products">
      <div class="col-xl-4 col-lg-6 col-12 padding15">
        <div class="card card-inverse btn-outline-info dashboardCard">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold info">{{nbProduct}}</h3>
                  <span class="font-large-1 font-weight-bold info">Produit<span  class="info"  *ngIf="nbProduct >1">s</span></span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-shopping-bag font-large-2 float-right info "></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
      <a routerLink="/archives/accommodation">
      <div class="col-xl-4 col-lg-6 col-12 padding15">
        <div class="card card-inverse btn-outline-danger dashboardCard">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold danger">{{nbProductArchived}}</h3>
                  <span class="font-large-1 font-weight-bold danger">Produit<span  class="danger"  *ngIf="nbProductArchived >1">s</span> archivé<span  class="danger"  *ngIf="nbProductArchived >1">s</span></span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-archive font-large-2 float-right danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
      <a routerLink="/produits-manquants">
      <div class="col-xl-4 col-lg-6 col-12 padding15">
        <div class="card card-inverse btn-outline-primary dashboardCard nonImporteBloc">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold primary nonImporteText">{{nbProduitsManquants}}</h3>
                  <span class="font-large-1 font-weight-bold primary nonImporteText" >Produit<span  class="primary nonImporteText"  *ngIf="nbProduitsManquants >1">s</span> non importé<span  class="primary nonImporteText"  *ngIf="nbProduitsManquants >1">s</span></span><br><span class="font-small-3 primary nonImporteText">(non saisis)</span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-layers font-large-2 float-right primary nonImporteText"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
    </div>
    <div class="row">
      <a routerLink="/packages">
      <div class="col-xl-4 col-lg-6 col-12 padding15">
        <div class="card card-inverse btn-outline-success dashboardCard packageBloc" style="background-color: transparent;">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold success">{{nbPackage}}</h3>
                  <span class="font-large-1 font-weight-bold success" >Forfait<span  class="success"  *ngIf="nbPackage >1">s</span></span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-package font-large-2 float-right success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
      <a routerLink="/archives/package">
      <div class="col-xl-4 col-lg-6 col-12 padding15">
        <div class="card card-inverse btn-outline-warning border-gold dashboardCard">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold warning gold">{{nbPackageArchived}}</h3>
                  <span class="font-large-1 font-weight-bold warning gold" >Forfait<span class="warning gold"  *ngIf="nbPackageArchived >1">s</span> archivé<span  class="warning gold"  *ngIf="nbPackageArchived >1">s</span></span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-archive font-large-2 float-right warning gold"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
      <a routerLink="/produits-manquants">
      <div class="col-xl-4 col-lg-6 col-12padding15">
        <div class="card card-inverse btn-outline-secondary dashboardCard">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="card-text font-large-1 font-weight-bold secondary">{{nbPackageManquants}}</h3>
                  <span class="font-large-1 font-weight-bold secondary">Forfait<span  class="secondary"  *ngIf="nbPackageManquants >1">s</span> non importé<span  class="secondary"  *ngIf="nbPackageManquants >1">s</span></span><br><span class="font-small-3 secondary">(non saisis)</span>
                </div>
                <div class="media-right align-self-center">
                  <i class="ft-layers font-large-2 float-right secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </a>
    </div>
    <div class="row mb-5 h-25" style="color:white;">&nbsp;</div>
  </section>
</div>



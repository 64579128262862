  <div class="row">
    <div class="col-lg-2 blocCanal">
        <label class="form-label" for="channels"><b>Canal</b></label>
        <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
      <select ngModel *ngIf="channels?.length != 0" (ngModelChange)="getProductDescriptions()"  [(ngModel)]="defaultChannel" aria-label="Floating label select example" class="form-control" id="channels" name="channels">
        <option *ngFor="let channel of channels" class="textCapitalise" [attr.code]="channel.code" value="{{channel.id}}">{{channel.nameFr}}</option>
      </select>
    </div>
    <div class="col-lg-4 blocImportedDetails">
      <input class="form-control importedProductCode" id="importedProductCode" placeholder="Code produit" name="importedProductCode" type="text" value="">
      <button class="btn btn-primary waves-effect btn-label waves-light float-end mr-2 float-right importedProductCode zIndex99" type="button" (click)="importDetailFromOtherProduct()">
        <i class="fa-solid fa-file-import mr-1 iconeDetails"></i>Importer description
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4><span class="badge bg-info">{{productDescriptions.length}} descriptif<span *ngIf="productDescriptions?.length >1">s</span></span></h4>
          <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right AddPackage" routerLink="/description/new/product/{{productId}}/{{channelId}}" type="button">
            <i class="ft-plus mr-1"></i>Nouveau descriptif
          </button>
        </div>
        <div class="card-body" *ngIf="productDescriptions?.length != 0">
          <div class="table-responsive">
            <table class="table table-striped mb-0">
              <thead class="table-light">
              <tr>
                <th class="col-xl-2">Famille</th>
                <th class="col-xl-3">Titre</th>
                <th class="col-xl-6">Description</th>
                <!---th class="col-xl-1">Ordre</th---->
                <th class="col-xl-1">Actions</th>
              </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="GetNewItemListAfterSortWithDragDrop($event)" >
              <tr *ngFor="let description of productDescriptions" cdkDrag>
                <td>
                  {{description?.type && description.type.nameFr}}
                </td>
                <td>
                  {{description.titleFr}}
                </td>
                <td>
                  <div class="bodyDetail" [innerHTML]="description.textFr|safeHtml"></div>
                </td>
                <!----td align="center"><span class="ordre">{{description.rank}}</span></td---->
                <td>
                  <a routerLink="/description/edit/product/{{description.id}}/{{channelId}}/{{productId}}"><i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit" style="margin-right:10px; " title="Modifier"></i></a>
                  <i class="ft-x ml-1 cursor-pointer ft-delete-2 btnActionDelete"  (click)="Delete(description);"  style="color:red;" title="Supprimer"></i>
                  <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(description.id);" data-toggle="modal" data-target="#editLogModal"></i>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal log -->
  <div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <app-log-list [ElementId]="ElementId" [entityType]="'ProductDetail'"></app-log-list>
  </div>


import {Component, Input, OnInit} from '@angular/core';
import {ProductsService} from "../../shared/services/products.service";
import {GlobalService} from "../../shared/services/global.service";
import {FormControl, NgForm} from "@angular/forms";
import {debounceTime, map, tap} from "rxjs/operators";
import {Constantes} from "../../shared/constantes";
import {NotificationService} from "../../shared/services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-Package-new',
  templateUrl: './package-new.component.html',
  styleUrls: ['./package-new.component.css']
})
export class PackageNewComponent implements OnInit {
  readonly Constantes = Constantes;
  PackageSubTypes: any = [];
  PackagePensions: any = [];
  parentCode = 'package';
  checked = true;
  defaulDurationNights:any;
  defaulDurationDays:any;
  GeneralitesForm = new FormControl();
  addNew:boolean=false;
  selectedPackageProducts: any = [];
  products: any = [];
  formControl = new FormControl();
  value = 'resolve';
  allowClear = true;
  required = true;
  disabled = false;
  productName = 'fra';
  isLoading = false;
  filteredProducts: any;
  errorMsg: string = '';
  searchProductName =  new FormControl();
  bodyInvalid:boolean=false;
  productCode: string = '';
  /************************** CKEDITOR  *******************************/
  CKEDITOR: any;
  /************************** CKEDITOR  *******************************/
  constructor(
    private router:Router,
    private productService: ProductsService,
    private globalService: GlobalService,
    private notification: NotificationService,
    private activatedRouter:ActivatedRoute
  ) { }

  @Input() packageCode: string = '';
  @Input() packageId: number = 0;
  @Input() Package: any = [];
  @Input() existedPackageProducts: any = [];
  ngOnInit(): void
  {
    /************************** CKEDITOR  *******************************/
    delete CKEDITOR.instances["#body"];
    CKEDITOR.replace('body', {
      customConfig : '/assets/js/cke_custom_basic_dev_config_full.js',
      height : '350px'
    });
    /************************** CKEDITOR  *******************************/
    this.productCode = this.activatedRouter.snapshot.params['id'];
    if (this.productCode && this.productCode.length >0)
    {
      this.autoAddExistedProductOnAutosuggest();
    }
    this.getPackagePensions();
    this.getPackageSubTypes();
    this.getproductsByName();
  }
  autoAddExistedProductOnAutosuggest ()
  {
    /****************** add new package from exsited product **********************/
    this.productService.searchProductsCodeName(this.productCode ,'accommodation').subscribe(
      searchResponse => {
        if (searchResponse)
        {
          this.existedPackageProducts.push({'id':searchResponse[0]['id'],'code':searchResponse[0]['code'],'nameFr':searchResponse[0]['namefr']});
        }
      },
      error => {
        this.globalService.checkApiError(error);
      }
    )
    /****************** add new package from exsited product **********************/
  }
  getPackagePensions() {
    this.globalService.getParameterValue('', 'meal_plan_type')
      .subscribe(
        PackagePensions => {
          for (let item in PackagePensions[0]['data']) {
            this.PackagePensions.push(PackagePensions[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageSubTypes() {
    this.globalService.getParameterValue(this.parentCode, 'product_subtype')
      .subscribe(
        PackageSubTypes => {
          for (let item in PackageSubTypes[0]['data']) {
            this.PackageSubTypes.push(PackageSubTypes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getproductsByName() {
    this.searchProductName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredProducts = [];
          this.isLoading = true;
        }),
        map(productNameFr => productNameFr.toString().trim())
      )
      .subscribe((productCODE: string) => {
          this.productService.searchProductsCodeName(productCODE,'accommodation').subscribe(
            searchResponse => {
              if (searchResponse) {
                this.isLoading = false;
                this.products = searchResponse.map(function(row:any) {
                  return { id : row.id, code : row.code, nameFr : row.namefr }
                })
              }
            },
            error => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }
  /* save product on select*/
  productsOptionSelected(product: any): boolean {
    let newCode='';
    /********* delete doublons if existe ***********/
    for (let item in this.existedPackageProducts)
    {
      if (this.existedPackageProducts[item]['code']==product['code'])
      {
        this.existedPackageProducts.splice(item, 1);
      }
    }
    /********** save new product on first element of  existedPackageProducts *********/
    this.existedPackageProducts.unshift(product);
    newCode=product['code'].toString().trim();
    /****** empty uatosuggest new product **********/
    if (event && this.searchProductName) {
      let newProducts: any = [];
      $(".autocompleteInput").val('');
    }
    /**************** set border color green  for new product *********/
    setTimeout(function(){ $('#newProductBadge_'+newCode).parent().addClass('newAdd') }, 400);
    return true;
  }
  removeThisProduct(code: string) {

    for (let i in this.existedPackageProducts) {
      if (this.existedPackageProducts[i]['code'] == code) {
        this.existedPackageProducts.splice(i, 1);
        break;
      }
    }
  }
  productDisplayFormat(product: any): string {
    if (product) {
      return product.nameFr + ' [' + product.code + ']';
    } else {
      return '';
    }
  }
  addNewProduct()
  {
    this.addNew=true;
  }
  hideNewProduct() {
    this.addNew=false;
  }
  onSubmit(form:NgForm)
  {
    let bodyContent = CKEDITOR.instances["body"].getData();
    let entity='product';
    /*if ((bodyContent) && (bodyContent.length >0 && bodyContent.length < 3))
    {
      this.bodyInvalid = true;
    }
    else
    {
      this.bodyInvalid = false;
    }*/
    console.log('save : form', form);
    console.log('bodyInvalid', this.bodyInvalid);
    if(form.valid)
    {
      this.globalService.showSpinner();
      //autocompleteInput
        let nameFr = form.value.name;
        nameFr=nameFr.trim();
        let code = form.value.code;
        let subTypeId=null
        let pensionId=null
        let durationDays=form.value.durationDays;
        let durationNights=form.value.durationNights;
        let typeId=localStorage.getItem('package');
        let isActive =true;
        if ($("#product_active").prop('checked'))
        {
          isActive=true;
        }
        else
        {
          isActive=false;
        }
        if (typeId!=null)
        {
          this.productService.addEditProductGeneralities(nameFr,code,null,null,null,null,typeId,subTypeId,pensionId,null,isActive,null,null,null,bodyContent,durationDays,durationNights)
            .subscribe(
              response => {
                this.globalService.hideSpinner();
                if (!isNaN(response['id']))
                {
                  console.table('new package response', response);
                  this.addPackageSegment(response['id'],response['nameFr'],response['code']);
                }
              },
              error => {
                this.globalService.hideSpinner();
                console.log('error ==>',error);
                this.notification.showError(error['error']['hydra:description'],"");
              }
            );
        }

    }
  }
  addPackageSegment(packageId:number,newPackageName:string,newPackageCode:string)
  {
    let router=this.router;
    if(this.existedPackageProducts.length>0 && packageId)
    {
      for (let item = 0; item <= this.existedPackageProducts.length; item++)
      {
        this.globalService.showSpinner();
        this.notification.hideNotification();
        this.productService.addPackageSegment(this.existedPackageProducts[item]['id'],packageId)
          .subscribe(
            response =>
            {
              this.globalService.hideSpinner();
              this.notification.hideNotification();
              if((item+1) == this.existedPackageProducts.length)
              {
                this.globalService.hideSpinner();
                if (!isNaN(response['id']))
                {
                  console.table('package segment response', response);

                  this.notification.showSuccess("Le forfait   '" +newPackageName+ "'   a été ajouté avec succès","");
                  setTimeout(function()
                  {
                    $("html, body").animate({ scrollTop: 0 }, "slow");
                  }, 2200);
                  setTimeout(function()
                  {
                    router.navigateByUrl("/package/"+packageId+"/"+newPackageCode);
                  }, 2200);
                }

              }
            },
            error => {
              console.log('add Product Segment error ==>',error);
              this.globalService.hideSpinner();
              this.notification.showError(error['error']['hydra:description'],"");
            }
          );
      }
    }
  }

  durationTransformFormat(event: any)
  {

    let duration=event.target.value;
    if(!isNaN(duration) && duration > 0 && duration < 10)
    {
      let newVal = '0' + duration;

      if (event.target.name=="durationNights")
      {
        this.defaulDurationNights=newVal;
      }
      if (event.target.name=="durationDays")
      {
        this.defaulDurationDays=newVal;
      }

    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";

@NgModule({
  declarations: [SafeHtmlPipe,ReplacePipe],
  imports: [CommonModule],
  exports: [
    SafeHtmlPipe,
    ReplacePipe
  ]
})
export class SharedModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication/authentication.component';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {LogModule} from "../log/log.module";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";
import {jwtDecode} from "jwt-decode";
import { LinksPageComponent } from './links-page/links-page.component';


const routes: Routes = [
  {path: 'login', component:AuthenticationComponent, pathMatch:"full"},
  {path: 'links_page', component:LinksPageComponent, pathMatch:"full"}

];
@NgModule({
  declarations: [
    AuthenticationComponent,
    LinksPageComponent
  ],
  imports: [
    CommonModule,
    [RouterModule.forChild(routes)],
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    LogModule,
    NgbAlertModule
  ]
})
export class LoginModule { }

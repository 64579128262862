import {Component, Input, OnInit, EventEmitter} from '@angular/core';
import {GlobalService} from "../../../shared/services/global.service";
import {FileUploader} from 'ng2-file-upload';
import {Constantes} from "../../../shared/constantes";
import {ProductsService} from "../../../shared/services/products.service";
import {NotificationService} from "../../../shared/services/notification.service";
/******************* Drag and Drop sorting ***************************************/
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
/******************* Drag and Drop sorting ***************************************/
@Component({
  selector: 'app-PackagePhotos',
  templateUrl: './package.photos.component.html',
  styleUrls: ['./package.photos.component.css']
})
export class PackagePhotosComponent implements OnInit {
  ItemsUpdatedRankList: any = [];
  ExistedProductsPhotos: any = [];
  ExistedPhotos: any = [];
  loadingProductsPhotos:boolean=true;
  NewAddPhotos: any = [];
  uploadMessageList: any = [];
  PackageProductsID: any = [];
  modalPhotoShow:boolean=true;
  loadExistedPhotos:boolean=true;
  imgSrc:string='';
  currentUrl:string='';
  public previewPath: any;
  someSubscription: any;
  maxFileSizeHtml=Constantes.maxFileSizeHtml;
  @Input() Product: any = [];
  @Input() packageCode: string = '';
  @Input() Package: any = [];
  @Input() existedPackageProducts: any = [];
  @Input() packageId: number = 0;
  uploader: FileUploader = new FileUploader(Constantes.FileUploaderConfig);
  channels: any = [];
  defaultChannel=Constantes.defaultChannel;
  constructor(
    private productService:ProductsService,
    private globalService: GlobalService,
    private notification: NotificationService
  )
  {
    /************************** if upload error *******************************/
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      console.log('enter upload failed error');
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'L\'image téléchargée  "' + item.name + '\" fait ' + this.globalService.formatBytes(item.size,null) + ', cela dépasse la taille maximale autorisée de ' + this.globalService.formatBytes(Constantes.maxFileSize,null);
          this.uploadMessageList.push({'message':message});
          $('.alerteUploadPhoto').show();
          break;
        default:
          message = 'Une erreur s’est produite lors de la tentative d\'uploader la photo :  '+item.name;
          this.uploadMessageList.push({'message':message});
          break;
      }
    };
    /************************** if upload error *******************************/
  }
  /******************* Drag and Drop sorting ***************************************/

  GetNewItemListAfterSortWithDragDrop (event: CdkDragDrop<string[]>)
  {
    console.log('event == ', event);
    if (event.previousContainer === event.container)
    {
      moveItemInArray(this.ExistedPhotos, event.previousIndex, event.currentIndex);
      this.setItemsUpdatedRankList(this.ExistedPhotos);
    }
    else
    {
      transferArrayItem(event.previousContainer.data,this.ExistedPhotos,event.previousIndex,event.currentIndex);
      console.log(' transferArrayItem  productDescriptions ==> ', this.ExistedPhotos);
    }
  }
  /******************* Drag and Drop sorting ***************************************/
  ngOnInit(): void
  {
    this.NewAddPhotos=[];
    this.getChannels();
    this.getPackageProductsId();
    this.getExistedPhotos();
    this.getExistedProductsPhotos();
  }
  getChannels() {
    this.globalService.getParameterValue('', 'business_channel')
      .subscribe(
        channels => {
          for (let item in channels[0]['data']) {
            this.channels.push(channels[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getPackageProductsId() {
    this.productService.getPackageSegments(null,false,'', null,null,this.packageId,null,null)
      .subscribe(
        products => {
          for (let item in products[0]['data'])
          {
            this.PackageProductsID.push(products[0]['data'][item]['product']['id']);
          }
          this.getExistedProductsPhotos();
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getExistedProductsPhotos()
  {
    //this.globalService.showSpinner();
    this.ExistedProductsPhotos=[];
    let channelCode=$("#channelsPhotos option:selected").attr("code");
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
    }
    if (this.PackageProductsID && this.PackageProductsID.length > 0)
    {
      for (let item in this.PackageProductsID)
      {
        this.productService.getProductMedia(this.PackageProductsID[item],channelCode)
        .subscribe(
          ExistedProductsPhotos => {
            for (let item in ExistedProductsPhotos[0]['data']) {
              this.ExistedProductsPhotos.push(ExistedProductsPhotos[0]['data'][item]);
            }
            this.globalService.hideSpinner();
          },
          error => {
            this.globalService.checkApiError(error);
            this.globalService.hideSpinner();
          }
        );
      }
    }
    this.loadingProductsPhotos=false;
  }
  importMediasFromOtherProduct()
  {}
  onFileSelected(event: any|EventEmitter<File[]>)
  {
    this.NewAddPhotos=this.globalService.onFileSelected(event);
  }
  alerteUploadPhotoClose ()
  {
    $('.alerteUploadPhoto').hide();
  }
  remove(index:number|null)
  {
    this.NewAddPhotos.splice(index,1);
  }
  addNewPhoto()
  {
    let rank=null;
    let keywords=null;
    let gallery=false;
    let panoramic=false;
    let channelID=$("#channelsPhotos option:selected").val();
    let NewAddPhotosLength= this.NewAddPhotos.length;
    let NewAddPhotosRealLength= this.NewAddPhotos.length;
    if (NewAddPhotosRealLength>0)
    {
      NewAddPhotosRealLength=NewAddPhotosRealLength-1;
    }
    for (let item = 0; item <= NewAddPhotosRealLength; item++)
    {
      //rank
      rank=$('#rank_'+item).val();
      this.NewAddPhotos[item]['rank']=rank;
      //keywords
      keywords=$('#keywords_'+item).val();
      this.NewAddPhotos[item]['keywords']=keywords;
      //gallery
      if ($('#gallery_'+item).prop('checked'))
      {
        gallery=true;
      }
      else
      {
        gallery=false;
      }
      this.NewAddPhotos[item]['gallery']=gallery;

      //panoramic
      if ($('#panoramic_'+item).prop('checked'))
      {
        panoramic=true;
      }
      else
      {
        panoramic=false;
      }
      this.NewAddPhotos[item]['panoramic']=panoramic;

      /***********************************************  CALL API ADD PHOTO  ******************************************************************/
      this.globalService.showSpinner();
      let name=this.NewAddPhotos[item]['name'];
      this.productService.addEditProductMedia(this.NewAddPhotos[item]['Base64'],this.NewAddPhotos[item]['name'],this.NewAddPhotos[item]['width'],this.NewAddPhotos[item]['height'],this.NewAddPhotos[item]['size'],this.NewAddPhotos[item]['rank'],this.packageId,this.NewAddPhotos[item]['panoramic'],this.NewAddPhotos[item]['gallery'],null,null,channelID,this.NewAddPhotos[item]['keywords']).subscribe(
        response =>
        {
          if (response.status=200)
          {

            this.notification.showSuccess("La photo' "+ name +" ' a été ajoutée avec succès","");
            let index=this.NewAddPhotos.indexOf(item);
            this.remove(index);
            this.ExistedPhotos=[];
            this.ExistedPhotos.length=0;
            this.globalService.hideSpinner();
            let This = this;
            /************************* refrech component data ********************************/
            if((item+1) == NewAddPhotosLength)
            {
              setTimeout(function () {
                let documentHeight: any = $(document).height();
                let windowHeight: any = $(window).height();
                let bottomPosition = (documentHeight * 1) - (windowHeight * 1);
                if ($(document).height() && $(window).height()) {
                  This.getExistedPhotos();
                  $("html, body").animate({scrollTop: bottomPosition}, "slow");
                }
              }, 1500);
            }
            /************************* refrech component data ********************************/
          }
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
          this.notification.showError(error['error']['hydra:description'],"");
        }
      );
    }
    $('#photosUploaded').val('');
    this.NewAddPhotos=[];
    this.NewAddPhotos.length=0;
    this.uploader.queue.length=0;
    this.uploader.clearQueue();
    this.uploader.destroy();
    /***********************************************  CALL API ADD PHOTO  ******************************************************************/
  }
  updatePhoto(photoId:number,name:string)
  {
    this.globalService.showSpinner();
    let rank:any;
    let keywords:any|null='';
    let panoramic:boolean=false;
    let gallery:boolean=false;
    let isList:boolean=false;
    let channelID=$("#channelsPhotos option:selected").val();
    if ($('#panoramic_'+photoId).prop('checked'))
    {
      panoramic=true;
    }

    if ($('#gallery_'+photoId).prop('checked'))
    {
      gallery=true;
    }

    if ($('#isList_'+photoId).prop('checked'))
    {
      isList=true;
    }
    rank=$('#rank_'+photoId).val();
    //keywords
    keywords=$('#keywords_'+photoId).val();

    this.productService.addEditProductMedia(null,null,null,null,null,rank,this.packageId,panoramic,gallery,isList,photoId,channelID,keywords).subscribe(
      response =>
      {
        if (response.status=200)
        {
          this.globalService.hideSpinner();
          this.notification.showSuccess("La photo ' "+ name +" ' a été modifiée avec succès","");
          $("html, body").animate({ scrollTop: 0 }, "slow");
        }
      },
      error => {

        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
        this.notification.showError(error['error']['hydra:description'],"");
      }
    );
  }
  DeletePhoto(item:any)
  {
    let globalService=this.globalService;
    let productService=this.productService;
    let ExistedPhotos=this.ExistedPhotos;
    let notification=this.notification;
    this.notification.ConfirmAction("Êtes-vous sûr de vouloir supprimer",item.name,"Valider","Supprimer !","", function (confirmed:any)
    {
      if (confirmed)
      {
        globalService.showSpinner();
        productService.deleteProductMedia(item.id).subscribe(
          deletedResponse => {
            globalService.hideSpinner();
            let index = ExistedPhotos.indexOf(item);
            if (index > -1)
            {
              ExistedPhotos.splice(index, 1);
              globalService.hideSpinner();
              notification.showSuccess("Le détail ' "+item.name+" ' a été supprimé avec succès","");
              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            globalService.hideSpinner();
            globalService.checkApiError(error);
            notification.showError(error['error']['hydra:description'],"");
          }
        );
      }
      else
      {
        console.log('response ==> false');
      }
    });
  }
  zoom(event:any)
  {
    let target = event.target || event.srcElement || event.currentTarget;
    let srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
    this.modalPhotoShow=false;
  }
  closeModalPhoto()
  {
    this.modalPhotoShow=true;
  }
  getExistedPhotos()
  {
    //this.globalService.showSpinner();
    let channelCode=$("#channelsPhotos option:selected").attr("code");
    if (!channelCode)
    {
      channelCode=Constantes.defaultChannelCode;
    }
    this.ExistedPhotos=[];
    this.productService.getProductMedia(this.packageId,channelCode)
      .subscribe(
        ExistedPhotos => {

          for (let item in ExistedPhotos[0]['data'])
          {
            this.ExistedPhotos.push(ExistedPhotos[0]['data'][item]);
          }
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );
    this.loadExistedPhotos=false;
  }
  setItemsUpdatedRankList(items:[])
  {
    this.ItemsUpdatedRankList=[];
    items.forEach((value, index) =>
    {
      let rank=index+1;
      this.ItemsUpdatedRankList.push({"id":value['id'],"rank":rank});
    });
    this.productService.updateProductMediasDetailRank('ProductMedia','product_medias',this.ItemsUpdatedRankList)
      .subscribe(
        response => { console.log('response rank update ',response);
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
}

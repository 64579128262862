import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../shared/services/products.service";
import {GlobalService} from "../../shared/services/global.service";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  id:number=0;
  action:string='';
  productCode='';
  tab:string='generalites';
  Product: any = [];
  constructor
  (
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRouter.snapshot.params['id'];
    this.action= this.activatedRouter.snapshot.params['action'];
    this.productCode = this.activatedRouter.snapshot.params['code'];
    this.tab = this.activatedRouter.snapshot.params['tab'];

    if (!this.id || isNaN(this.id))
    {
     this.router.navigate(['/products']);
    }
    if (!this.tab)
    {
      this.tab='generalites';
    }
    if (!this.action)
    {
      this.action='Création';
    }
    else if (this.action=='edit')
    {
      this.action='Modification';
    }
    this.getProduct();
  }

  getProduct()
  {
    this.globalService.showSpinner();
    this.productService.getProduct(this.id).subscribe(
      product => {
        this.Product = product;
        },
      error => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )
  }

  goToUrl(id:number|null,code:string|null,tab:string|null,type:string|null)
  {
    let Link="/"+type+"/"+id+"/"+code+"/"+tab;
    this.globalService.redirectToURl(Link);
  }

}

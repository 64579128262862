import {Component, Input, OnInit} from '@angular/core';
import {FormControl, NgForm} from "@angular/forms";
import {ProductsService} from "../../../shared/services/products.service";
import {GlobalService} from "../../../shared/services/global.service";
import {debounceTime, map, tap} from "rxjs/operators";
import {Constantes} from "../../../shared/constantes";
import {NotificationService} from "../../../shared/services/notification.service";

@Component({
  selector: 'app-generalites',
  templateUrl: './generalites.component.html',
  styleUrls: ['./generalites.component.css']
})
export class GeneralitesComponent implements OnInit {
  readonly Constantes = Constantes;
  existedProductLocalities: any = [];
  existedProductCountry: any;
  existedProductCity: any;
  selectedProductLocalities: any = [];
  deleteProductLocalityStatus:boolean=false;
  newDetailName="";
  ProductSubTypes: any = [];
  ProductPensions: any = [];
  localities: any = [];
  listOfCountry :any = [];
  listOfCityForCountry :any = [];
  formControl = new FormControl();
  value = 'resolve';
  allowClear = true;
  required = true;
  disabled = false;
  localityName = 'fra';
  parentCode = 'accommodation';
  isLoading = false;
  filteredLocalities: any;
  errorMsg: string = '';
  searchLocalityName =  new FormControl();
  addNew:boolean=false;
  constructor(
    private notification: NotificationService,
    private productService: ProductsService,
    private globalService: GlobalService
  ) {}
  @Input() productCode: string = '';
  @Input() productId: number = 0;
  @Input() Product: any = [];
  ngOnInit() {
    console.log('this.Product. ==>',this.Product);
    this.getProductLocalities();
    this.getCountry();
    //this.getProductPensions();
    //this.getProductSubTypes();
    this.getlocalitiesByName();

  }
  getProductPensions() {
    this.globalService.getParameterValue('', 'meal_plan_type')
      .subscribe(
        ProductPensions => {
          for (let item in ProductPensions[0]['data']) {
            this.ProductPensions.push(ProductPensions[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }

  getProductSubTypes() {
    this.globalService.getParameterValue(this.parentCode, 'product_subtype')
      .subscribe(
        ProductSubTypes => {
          for (let item in ProductSubTypes[0]['data']) {
            this.ProductSubTypes.push(ProductSubTypes[0]['data'][item]);
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }

  getCountry() {
    this.globalService.getCountry()
      .subscribe(
        Localities => { console.log('==> Country ==>  ',Localities);
          for (let countryItem in Localities[0]['data'])
          {
            if (Localities[0]['data'][countryItem]['type']['code']== 'country')
            {
              this.listOfCountry.push({'id':Localities[0]['data'][countryItem]['id'],'text':Localities[0]['data'][countryItem]['nameFr']+' ('+Localities[0]['data'][countryItem]['code']+')'});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }
  getCityOfCountry(mainParentId:any)
  {
    this.globalService.getCityOfCountryByID(mainParentId)
      .subscribe(
        CityOfCountry => {  console.log('CityOfCountry ==> ',CityOfCountry);
          this.listOfCityForCountry=[];
          for (let cityItem in CityOfCountry[0]['data'])
          {
            if (CityOfCountry[0]['data'][cityItem]['type']['code']== 'city')
            {
              this.listOfCityForCountry.push({'id':CityOfCountry[0]['data'][cityItem]['id'],'text':CityOfCountry[0]['data'][cityItem]['nameFr']});
            }
          }
        },
        error => {
          this.globalService.checkApiError(error);
        }
      );
  }

  getProductLocalities() {
    for (let item in this.Product['productLocalities'])
    {
      if (this.Product['productLocalities'][item]['locality']['type']['code']=='country')
      {
        this.existedProductCountry=this.Product['productLocalities'][item]['locality']['id'];
      }
      if (this.Product['productLocalities'][item]['locality']['type']['code']=='city')
      {
        this.existedProductCity=this.Product['productLocalities'][item]['locality']['id'];
      }
      this.getCityOfCountry(this.existedProductCountry);
    }



  }

  getlocalitiesByName() {
    this.searchLocalityName.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredLocalities = [];
          this.isLoading = true;
        }),
        map(localityNameFr => localityNameFr.toString().trim())
      )
      .subscribe((localityNameFr: string) => {
          this.globalService.getlocalitiesByName(localityNameFr).subscribe(
            searchResponse => {
              if (searchResponse[0] !== undefined) {
                this.isLoading = false;
                this.localities=searchResponse[0]['data'];
              }
            },
            error => {
              this.globalService.checkApiError(error);
            }
          )

        }
      );

  }

  localityDisplayFormat(locality: any): string {
    if (locality) {
      return locality.nameFr + ' [' + locality.code + ']';
    } else {
      return '';
    }
  }

  /* save locality on select*/
  localitiesOptionSelected(locality: any): boolean {
    let newCode='';
    /********* delete doublons if existe ***********/
    for (let item in this.existedProductLocalities)
    {
      if (this.existedProductLocalities[item]['code']==locality['code'])
      {
        this.existedProductLocalities.splice(item, 1);
      }
    }
    /********** save new locality on first element of  existedProductLocalities *********/
    this.existedProductLocalities.unshift(locality);
    newCode=locality['code'].toString().trim();
    /****** empty uatosuggest new locality **********/
    if (event && this.searchLocalityName) {
      let newLocalities: any = [];
      $(".autocompleteInput").val('');
    }
    /**************** set border color green  for new locality *********/
    setTimeout(function(){ $('#newLocalityBadge_'+newCode).parent().addClass('newAdd') }, 400);
     return true;
  }

  removeThisLocality(code: string) {

    for (let i in this.existedProductLocalities) {
      if (this.existedProductLocalities[i]['code'] == code) {
        this.existedProductLocalities.splice(i, 1);
        break;
      }
    }
  }

  onSubmit(form:NgForm)
  {
    if(form.valid)
    {
      this.globalService.showSpinner();
      let pensionId=null;
      let subTypeId=null;
      let nameFr = form.value.name;
      nameFr=nameFr.trim();
      let code = form.value.code;
      //subTypeId=$("#sub_type option:selected").attr("id");
      pensionId=$("#pensions option:selected").attr("id");
      let tripAdvisorCode=form.value.tripAdvisorCode;
      let minAge=form.value.minAge;
      let longitude=form.value.longitude;
      let latitude=form.value.latitude;
      let URLVideo=form.value.URLVideo;
      let URLMap=form.value.URLMap;
      let countrys=form.value.countrys;
      let citys=form.value.citys;
      let typeId=localStorage.getItem('accommodation');
      let isActive =false;
      if ($("#product_active").prop('checked'))
      {
        isActive=true;
      }
      else
      {
        isActive=false;
      }
      if (this.productId)
      {
        this.productService.addEditProductGeneralities(nameFr,code,tripAdvisorCode,minAge,longitude,latitude,typeId,subTypeId,null,this.productId,isActive,URLVideo,URLMap,null,null,null,null)
          .subscribe(
            response =>
            {
              if (!isNaN(response['id']))
              {
                /***********************  if update product is OK : update this Product Localities  ***********************/
                this.editProductLocality(countrys,citys);
                /***********************  display confirmation alert ***************************/
              }
            },
            error => {
              console.log('error ==>',error);
              this.globalService.hideSpinner();
              this.notification.showError(error['error']['hydra:description'],"");
            }
          );
      }
    }

  }
  editProductLocality(countrys:number,citys:number)
  {
    let This=this;
    let updatedProductLocalities=[];
    if (countrys && countrys!=null && countrys>0)
    {
      updatedProductLocalities.push(countrys);
    }
    if (citys && citys!=null && citys>0)
    {
      updatedProductLocalities.push(citys);
    }

          this.globalService.showSpinner();
          this.notification.hideNotification();
          this.productService.editProductLocality(updatedProductLocalities,this.productId)
        .subscribe(
          response =>
          {
            this.globalService.hideSpinner();
            this.notification.hideNotification();
              setTimeout(function () {
                This.notification.showSuccess("La modification a été faite avec succès",'');
              }, 2000);
          },
          error => {
            console.log('add Product Locality error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
  }
}



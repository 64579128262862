import {Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {ProductsService} from "../../../shared/services/products.service";
import {GlobalService} from "../../../shared/services/global.service";
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  productPackageList: any = [];
  @Input() productId: number=0;
  @Input() productCode: string = '';
  @Input() Product: any = [];
  constructor(private productService: ProductsService,private globalService: GlobalService,) { }
  ngOnInit(): void {
    this.getProductPackageList();
  }
  getProductPackageList()
  {
    this.globalService.showSpinner();
    this.productService.getPackageSegments(null,false,'', this.productId,null,null,null,null)
      .subscribe(
        productPackageList => {
          console.log('productPackageList==>',productPackageList);
          for (let item in productPackageList[0]['data']) {
            this.productPackageList.push(productPackageList[0]['data'][item]);
          }
          this.globalService.hideSpinner();
        },
        error => {
          this.globalService.checkApiError(error);
          this.globalService.hideSpinner();
        }
      );

  }
}

import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {GlobalService} from "../../shared/services/global.service";
import {NotificationService} from "../../shared/services/notification.service";
@Component({
  selector: 'app-parameter-details',
  templateUrl: './parameter-details.component.html',
  styleUrls: ['./parameter-details.component.css']
})
export class ParameterDetailsComponent implements OnInit {
  defaultCodeValue=null;
  defaultNameValue='';
  @Input() parameter: any = [];
  constructor(
    private globalService: GlobalService,
    private notification: NotificationService,
    ) { }
  ngOnInit(): void
  { }
  onSubmit(form:NgForm)
  {
    this.globalService.hideSpinner();
    console.log('form = ',form);
    if(form.valid)
    {
      this.globalService.showSpinner();
      let name = form.value.name;
      let code = form.value.code;

      let parametersId:number=form.value.id;
      let isActive =false;
      let isMaster =false;
      if (isNaN(parametersId) || parametersId==0)
      {
        parametersId = 0;
      }
      if ($("#isActive").prop('checked'))
      {
        isActive=true;
      }
      else
      {
        isActive=false;
      }
      if ($("#isMaster").prop('checked'))
      {
        isMaster=true;
      }
      else
      {
        isMaster=false;
      }
      this.globalService.addEditParameters(parametersId, code, name, isActive, isMaster)
        .subscribe(
          response => {
            console.log('response  add /edit parametres ' , response);
            this.globalService.hideSpinner();
            if (response)
            {
              if (parametersId && !isNaN(parametersId) && parametersId>0)
              {
                this.notification.showSuccess("La modification a été faite avec succès","");
              }
              else
              {
                this.notification.showSuccess("Le paramètre a été ajouté avec succès","");
                this.globalService.redirectToURl('/parameters');
              }

              $("html, body").animate({ scrollTop: 0 }, "slow");
            }
          },
          error => {
            console.log('error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
    }
  }
}

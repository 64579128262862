import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSortModule} from '@angular/material/sort';
import {RouterModule, Routes} from "@angular/router";
import { NgSelect2Module } from 'ng-select2';
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {SharedModule} from "../shared/shared.module";
import {PackageListComponent} from "./package-list/package-list.component";
import {PackageDetailsComponent} from "./package-details/package-details.component";
import {PackagePhotosComponent} from "./package-details/photos/package.photos.component";
import {PackageDescriptionsComponent} from "./package-details/descriptions/package.descriptions.component";
import {PackageGeneralitesComponent} from "./package-details/generalites/package.generalites.component";
import {PackageTagsComponent} from "./package-details/tags/package.tags.component";
import {PackageNewComponent} from "./package-new/package-new.component";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {FileUploadModule} from "ng2-file-upload";
import { NgxSpinnerModule } from 'ngx-spinner';
import {AngularEditorModule} from "@kolkov/angular-editor";
import {LogModule} from "../log/log.module";
import {ProductDetailsComponent} from "../product/product-details/product-details.component";
import {DragDropModule} from "@angular/cdk/drag-drop";

const routes: Routes = [
  {path: 'package/new', component: PackageNewComponent,pathMatch:"full"},
  {path: 'package/new/:id', component: PackageNewComponent},
  {path: 'package/:id/:code', component: PackageDetailsComponent},
  {path: 'package/:id/:code/:tab/:action', component: PackageDetailsComponent},
  {path: 'package/:id/:code/:tab', component: PackageDetailsComponent},
  {path: 'package/:id/:code/:tab/:tagId', component: PackageDetailsComponent},
  {path: 'packages', component:PackageListComponent}
];


@NgModule({
  declarations: [
    PackageDescriptionsComponent,
    PackageGeneralitesComponent,
    PackageTagsComponent,
    PackageListComponent,
    PackagePhotosComponent,
    PackageDetailsComponent,
    PackageNewComponent

  ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        FormsModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatInputModule,
        [RouterModule.forChild(routes)],
        MatSortModule,
        NgSelect2Module,
        SharedModule,
        NgbPaginationModule,
        FileUploadModule,
        NgxSpinnerModule,
        AngularEditorModule,
        LogModule,
        DragDropModule
    ]
})
export class PackageModule { }

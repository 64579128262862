import {Component, OnInit} from '@angular/core';
import {ProductsService} from "../shared/services/products.service";
import {Sort} from '@angular/material/sort';
import {GlobalService} from "../shared/services/global.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../shared/services/notification.service";
@Component({
  selector: 'app-produits-manquants',
  templateUrl: './produits-manquants.component.html',
  styleUrls: ['./produits-manquants.component.css']
})
export class ProduitsManquantsComponent implements OnInit {
  page: number = 1;
  productsLists: any = [];
  filteredProducts: any;
  isLoading = false;
  errorMsg: string='';
  value='resolve';
  allowClear=true;
  required=true;
  disabled=false;
  constructor(
    private activatedRouter:ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private globalService: GlobalService,
    private notification: NotificationService
  ) {}
  ngOnInit(): void {
    this.getProduitsManquants();
  }
  getProduitsManquants(page=1)
  {
    this.globalService.showSpinner();
    this.productService.getProduitsManquants(page).subscribe(
      (productsLists:any) => { console.log('productsLists =========>',productsLists);
        this.productsLists = productsLists;
        this.globalService.hideSpinner();
      },
      (error:any) => {
        this.globalService.checkApiError(error);
        this.globalService.hideSpinner();
      }
    )

  }
  displayFn: any;
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.productsLists.slice();
    if (!sort.active || sort.direction === '') {
      this.productsLists= data;
      return;
    }
    this.productsLists= data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        default:
          return 0;
      }
    });
  }

  importer(type:string,code:string)
  {

   if (type=='product')
   {
     this.productService.importerProduitManquant(code)
       .subscribe(
         response => {
           if (response)
           {
             this.globalService.hideSpinner();

             let responseStatus=response['status'];
             let responseMessage=response['message'];
             if (responseStatus <210)
             {
               this.notification.showSuccess("Le produit "+code+" a été importé avec succès","");
             }
             else
             {
               this.notification.showError(responseMessage,"");
             }

             $("html, body").animate({ scrollTop: 0 }, "slow");
             this.getProduitsManquants();
           }
         },
         error => {
           console.log('error ==>',error);
           this.globalService.hideSpinner();
           this.notification.showError(error['error']['hydra:description'],"");
         }
       );
   }

    if (type=='package')
    {

      this.productService.importerPackageManquant(code)
        .subscribe(
          response => {
            if (response)
            {
              this.globalService.hideSpinner();
              let responseStatus=response['status'];
              let responseMessage=response['message'];
              if (responseStatus <210)
              {
                this.notification.showSuccess("Le forfait "+code+" a été importé avec succès","");
              }
              else
              {
                this.notification.showError(responseMessage,"");
              }
              $("html, body").animate({ scrollTop: 0 }, "slow");
              this.getProduitsManquants();
            }
          },
          error => {
            console.log('error ==>',error);
            this.globalService.hideSpinner();
            this.notification.showError(error['error']['hydra:description'],"");
          }
        );
    }

  }

}





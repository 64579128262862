<div class="row">
  <div class="col-lg-2 blocCanal">
    <label class="form-label" for="channelsPhotos"><b>Canal</b></label>
    <img *ngIf="channels?.length == 0" class="LoaderImages" src="../assets/img/load.gif">
    <select ngModel *ngIf="channels?.length != 0" (ngModelChange)="getExistedPhotos(); getExistedProductsPhotos();"  [(ngModel)]="defaultChannel" aria-label="Floating label select example" class="form-control" id="channelsPhotos" name="channelsPhotos">
      <option *ngFor="let channel of channels" class="textCapitalise" [attr.code]="channel.code" value="{{channel.id}}">{{channel.nameFr}}</option>
    </select>
  </div>
  <div class="col-lg-4 blocImportedDetails">
    <input class="form-control importedProductCode" id="importedProductCode" placeholder="Code forfait" name="importedProductCode" type="text" value="">
    <button class="btn btn-primary waves-effect btn-label waves-light float-end mr-2 float-right importedProductCode zIndex99" type="button" (click)="importMediasFromOtherProduct()">
      <i class="fa-solid fa-file-import mr-1"></i>Importer photos
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4><!---span class="badge bg-info">Ajouter une ou plusieurs photos</span---></h4>
        <div class="col-lg-6 d-flex justify-content-end float-right" style="margin-top: -28px;">
          <div><i class="fas fa-info-circle" style="color: orange;"></i> Poids max : <strong>{{maxFileSizeHtml}}</strong></div>
        </div>
      </div>
      <div *ngIf="uploadMessageList.length" class="alert alert-danger alert-dismissible mb-2 alerteUploadPhoto" role="alert">
        <button type="button" class="close" (click)="alerteUploadPhotoClose();" >
          <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
        </button>
        <span *ngFor="let item of uploadMessageList" class="float-left w-100">{{item.message}}</span>
      </div>
      <div class="card-content ">
        <div class="card-body">
          <form action="#" class="dropzone dropzone-area" id="dpz-multiple-files">
            <div class="dz-message">Veuillez cliquez ici pour déposer les photos.
              <div class="col-lg-12" style="position: relative;">
                <input type="file" class="imageUploader"  id="photosUploaded" #fileInput multiple ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event);" />
                <span class="custom-file-control"></span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- liste des photos -->
<div class="card">
  <div class="card-body">
    <h4 class="card-title" *ngIf="uploader.queue?.length != 0 || NewAddPhotos?.length != 0 || ExistedPhotos?.length != 0 || ExistedProductsPhotos?.length != 0">Liste des photos</h4>
    <h4 style="margin-top: 12px; margin-bottom: 18px;"><span class="badge bg-info boxShadow2">{{ExistedProductsPhotos.length}} photo<span *ngIf="ExistedProductsPhotos?.length >1">s</span> liés <span *ngIf="PackageProductsID.length==1">à</span><span *ngIf="PackageProductsID.length>1">aux</span> {{PackageProductsID.length}} hôtel<span *ngIf="PackageProductsID.length >1">s</span></span></h4>
    <div class="col-lg-12 float-end mb-3 mr-0 pr-0" *ngIf="NewAddPhotos?.length != 0">
      <div class="d-flex flex-wrap gap-2 justify-content-end">
        <button (click)="addNewPhoto()" class="btn btn-outline-success waves-effect btn-label waves-light modal-wait blocRightBottom newAdd" type="submit">
          <i class="ft-save"></i>&nbsp;
          <span *ngIf="NewAddPhotos && NewAddPhotos.length > 1">Enregistrer les photos ajoutées</span>
          <span *ngIf="NewAddPhotos && NewAddPhotos.length == 1">Enregistrer la photo ajoutée</span>
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table mb-0" *ngIf="uploader.queue?.length != 0 || NewAddPhotos?.length != 0 || ExistedPhotos?.length != 0 || ExistedProductsPhotos?.length != 0">
        <thead class="table-light">
        <tr>
          <th class="col-lg-2" style="width: 120px; max-width: 120px;">Nom</th>
          <th class="col-lg-3">Titre</th>
          <th class="col-lg-2">Aperçu</th>
          <th class="col-lg-1">Création</th>
          <th class="col-lg-1">Dimensions</th>
          <th class="col-lg-1">Poids</th>
          <th class="col-lg-1">Type</th>
          <th class="col-lg-1">Action</th>
        </tr>
        </thead>
        <!----- Nouvelles photos ajoutées -------->
        <tbody class="newAdd" *ngIf="uploader.queue?.length != 0 && NewAddPhotos?.length != 0">
        <tr *ngFor="let item of NewAddPhotos;let index = index">
          <td style="width: 120px; max-width: 120px;">{{item.name}}</td>
          <td>
            <input type="text" class="form-control" name="keywords_{{index}}" id="keywords_{{index}}">
          </td>
          <td>
              <img alt="{{item.name}}" [src]="item.url" class="photoView newAdd">
          </td>
          <td></td>
          <td>{{item.width}}x{{item.height}}</td>
          <td>{{ item.size/1024 | number:'.2' }} KO</td>
          <td style="font-size: smaller;">
            <span class="badge bg-light-info mb-1 mr-2"><input name="gallery_{{index}}" id="gallery_{{index}}" type="checkbox" val="gallery"> Gallerie</span><br>
            <span class="badge bg-light-info mb-1 mr-2"><input name="panoramic_{{index}}" id="panoramic_{{index}}" type="checkbox" val="panoramic" > Panorama</span>
          </td>
          <td>
            <i class="fas fa-trash-alt waves-effect waves-light sa-warning fa-1x" (click)="remove(index);"  style="color:red; cursor: pointer" title="Supprimer cette photo"></i>
          </td>
          <input style="visibility: hidden;" type="hidden" class="form-control" name="rank_{{index}}" id="rank_{{index}}" inputmode="numeric" pattern="\d*" [min]="1">
        </tr>
        </tbody>
        <tbody *ngIf="uploader.queue?.length != 0"></tbody>
        <!----- Photos récupéres depuis les hotels-------->
        <tbody *ngIf="ExistedProductsPhotos?.length != 0" class="packageProductsOwner">
        <tr *ngFor="let item of ExistedProductsPhotos">
          <td style="width: 120px; max-width: 120px;">
            <span title="{{item.name}}" *ngIf="item.name?.length >13">{{item.name.substr(1,11)}}...</span>
            <span title="{{item.name}}" *ngIf="item.name?.length <=13">{{item.name}}</span>
          </td>
          <td>
            {{item.keyWords}}
          </td>
          <td>
              <img alt="" src="{{item.mediaURI}}" title="Click to zoom"  (click)="zoom($event)" class="photoView cursor-pointer" data-toggle="modal" data-target="#myModal">
          </td>
          <td>{{item.createdAt| date :  "dd/MM/yyyy"}}<!---span *ngIf="item.updatedAt"><br><i class="fa-regular fa-calendar-pen iconDate" title="Date de modification"></i> : {{item.updatedAt| date :  "dd/MM/yyyy"}}</span---></td>
          <td>{{item.width}}x{{item.height}}</td>
          <td>{{item.weight}} Ko</td>
          <td style="font-size: smaller;">
            <span class="badge bg-light-primary mb-1 mr-2"><input name="list" id="isList_{{item.id}}" disabled  type="radio" val="list" [attr.checked]="item.isList ? 'checked': null"> Liste</span><br>
            <span class="badge bg-light-info mb-1 mr-2"><input name="gallery_{{item.id}}" id="gallery_{{item.id}}" disabled  type="checkbox" val="gallery" [attr.checked]="item.isGallery ? 'checked': null"> Gallerie</span><br>
            <span class="badge bg-light-info mb-1 mr-2"><input name="panoramic_{{item.id}}" id="panoramic_{{item.id}}" disabled  type="checkbox" val="panoramic" [attr.checked]="item.isPanoramic ? 'checked': null"> Panorama</span>
          </td>
          <td>&nbsp;</td>
        </tr>
        </tbody>
        <tbody *ngIf="loadingProductsPhotos"><tr><td colspan="7"><img style="height: 23px; top: 23px;" src="../assets/img/load.gif"></td> </tr></tbody>
        <!----- Photos récupéres depuis le package-------->
        <tbody *ngIf="loadExistedPhotos">
        <img class="LoaderImages" style="top: 114px; left: 27px;" src="../assets/img/load.gif">
        </tbody>
        <tbody *ngIf="ExistedPhotos?.length != 0" cdkDropList (cdkDropListDropped)="GetNewItemListAfterSortWithDragDrop($event)">
        <tr *ngFor="let item of ExistedPhotos;let index = index" cdkDrag>
          <td style="width: 120px; max-width: 120px;">
            <span title="{{item.name}}" *ngIf="item.name?.length >13">{{item.name.substr(1,11)}}...</span>
            <span title="{{item.name}}" *ngIf="item.name?.length <=13">{{item.name}}</span>
          </td>
          <td>
            <input type="texte" class="form-control" name="keywords_{{item.id}}" id="keywords_{{item.id}}" ngModel="{{item.keyWords}}">
          </td>
          <td>
            <img alt="" src="{{item.mediaURI}}" title="Click to zoom" (click)="zoom($event)" class="photoView cursor-pointer" data-toggle="modal" data-target="#myModal">
          </td>
          <td>{{item.createdAt| date :  "dd/MM/yyyy"}}<!---span *ngIf="item.updatedAt"><br><i class="fa-regular fa-calendar-pen iconDate" title="Date de modification"></i> : {{item.updatedAt| date :  "dd/MM/yyyy"}}</span---></td>
          <td>{{item.width}}x{{item.height}}</td>
          <td>{{item.weight/1024|number:'1.2-2'}} Ko</td>
          <td style="font-size: smaller;" class="cursorVisible">
            <span class="badge bg-light-primary mb-1 mr-2"><input name="list" id="isList_{{item.id}}" type="radio" val="list" [attr.checked]="item.isList ? 'checked': null"> Liste</span><br>
            <span class="badge bg-light-info mb-1 mr-2"><input name="gallery_{{item.id}}" id="gallery_{{item.id}}" type="checkbox" val="gallery" [attr.checked]="item.isGallery ? 'checked': null"> Gallerie</span><br>
            <span class="badge bg-light-info mb-1 mr-2"><input name="panoramic_{{item.id}}" id="panoramic_{{item.id}}" type="checkbox" val="panoramic" [attr.checked]="item.isPanoramic ? 'checked': null"> Panorama</span>
          </td>
          <td class="cursorVisible">
            <i class="fas fa-save fa-1x cursor-pointer" data-photo="42" style="margin-right:10px; color:black;"  title="Enregistrer" (click)="updatePhoto(item.id,item.name)"></i>
            <i class="ft-x ml-1 cursor-pointer ft-delete-2 btnActionDelete" data-photo="42"  style="color:red;" title="Supprimer cette photo" (click)="DeletePhoto(item)" ></i>
          </td>
          <input style="visibility: hidden;" type="hidden" class="form-control" name="rank_{{item.id}}" id="rank_{{item.id}}" ngModel="{{item.rank}}" inputmode="numeric" pattern="\d*" [min]="1">
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal modalPhoto" tabindex="-1" role="dialog" id="myModal" [hidden]="modalPhotoShow">
  <div class="modal-dialog modalPhotoDialog" role="document">
    <div class="modal-content w-100">
      <div class="modal-body p-0 modalZoomSize">
        <button type="button" class="close float-right modalCloseBtn" (click)="closeModalPhoto()" data-dismiss="modal" aria-label="Close">
          <i class="fa-solid fa-close font-large-1"></i>
        </button>
        <img src="{{imgSrc}}" class="modal-hover-opacity w-100"/>
      </div>
    </div>
  </div>
</div>

<div class="content-wrapper">
  <div class="row"></div>
  <div class="row">
    <section id="selection-n-deletion">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="headerGlobal"><h6 class="text-white ml-2 title-header headerGlobalTitle">Liste des paramètres</h6></div>
            <div class="card-header">
              <h4 class="card-title float-left product-count">{{nbParameters}} paramètre<span *ngIf="nbParameters>1">s</span></h4>
                <button class="btn btn-success waves-effect btn-label waves-light float-end mr-2 float-right" type="button" (click)="editParameter(null);" data-toggle="modal" data-target="#editParameterModal">
                  <i class="ft-plus mr-1"></i>Nouveau paramètre </button>
            </div>
            <div *ngIf="parametersLists" class="card-content ">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body pb-0">
                        <form #SearchEngineForm="ngForm" (ngSubmit)="searchParameters(SearchEngineForm,1);">
                          <!-- ligne 1 -->
                          <div class="row" style="display: flex!important;">
                            <!-- Code -->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label for="code">Code</label>
                                <input class="form-control" id="code" name="code" ngModel type="text" value="">
                              </div>
                            </div>
                            <!-- Nom -->
                            <div class="col-md-4">
                              <div class="form-floating mb-3">
                                <mat-form-field>
                                  <label for="name">Nom</label>
                                  <input [formControl]="searchParameterName"  [matAutocomplete]="auto" aria-label="State" class="form-control autocompleteInput" id="name" matInput name="name" ngModel type="text" value="">
                                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngIf="isLoading" class="is-loading">...</mat-option>
                                    <ng-container *ngIf="!isLoading">
                                      <mat-option *ngFor="let item of parametersNameLists"  [value]="item.name">
                                        <span>{{item.name}}</span>
                                      </mat-option>
                                    </ng-container>
                                  </mat-autocomplete>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- Etat -->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label for="etat">Etat </label>
                                <select aria-label="Floating label select example" class="form-control" id="etat" name="etat" ngModel>
                                  <option selected="" value="">Tous</option>
                                  <option value="true">Actif</option>
                                  <option value="false">Inactif</option>
                                </select>
                              </div>
                            </div>
                            <!-- Master -->
                            <div class="col-md-2">
                              <div class="form-floating mb-3">
                                <label for="master">Master</label>
                                <select aria-label="Floating label select example" class="form-control" id="master" name="master" ngModel>
                                  <option selected="" value="">Tous</option>
                                  <option value="true">Oui</option>
                                  <option value="false">Non</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-2">
                            <div class="form-floating">
                              <label for="search">&nbsp;</label>
                              <button id="search" class="btn btn-primary w-auto d-block" type="submit">
                                <i class="ft-search mr-1"></i>Rechercher </button>
                            </div>
                          </div>
                          </div>
                        </form>
                      </div>
                      <!-- end card body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
                <div class="table-responsive" *ngIf="parametersLists?.length != 0">
                  <table class="table table-striped table-bordered selection-deletion-row" matSort (matSortChange)="sortData($event)">
                    <thead>
                    <tr>
                      <th mat-sort-header="id" style="width:5% !important;">ID</th>
                      <th mat-sort-header="code" style="width: 15% !important;">Code</th>
                      <th mat-sort-header="name" style="width: auto !important;">Nom</th>
                      <th mat-sort-header="createdAt" style="width: 12% !important;">Date de création</th>
                      <th mat-sort-header="updatedAt" style="width: 13% !important;">Date de modification</th>
                      <th mat-sort-header="active" style="width: 7% !important;">Etat</th>
                      <th mat-sort-header="master" style="width: 7% !important;">Master</th>
                      <th style="width: 14% !important;">Actions</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="parametersLists?.length != 0">
                    <tr *ngFor="let parameter of parametersLists">
                      <td style="width: 5% !important;font-weight: 500;">{{parameter.id}}</td>
                      <td style="width: 15% !important;">{{parameter.code}}</td>
                      <td style="width: auto !important;">{{parameter.name}}</td>
                      <td style="width: 12% !important;" class="text-center">{{parameter.createdAt| date :  "dd/MM/yyyy à HH:mm"}}</td>
                      <td style="width: 12% !important;" class="text-center">{{parameter.updatedAt| date :  "dd/MM/yyyy à HH:mm"}}</td>
                      <td *ngIf="!parameter.isActive" style="width: 7% !important;">
                        <i class=" fa-solid fa-ban icon-danger" title="Actif = non"></i>
                      </td>
                      <td *ngIf="parameter.isActive"  style="width: 7% !important;">
                        <i class=" fa-solid fa-check icon-success" title="Actif = oui"></i>
                      </td>
                      <td *ngIf="!parameter.isMaster" style="width: 7% !important;">
                        <i class=" fa-solid fa-dash icon-danger" title="Master = non"></i>
                      </td>
                      <td *ngIf="parameter.isMaster"  style="width: 7% !important;">
                        <i class="fa-solid fa-lightbulb-on icon-success"  title="Master = oui"></i>
                      </td>
                      <td style="width: 14% !important;" class="actionMobile">
                          <i class="ft-edit-2 ml-2 cursor-pointer ng-star-inserted btnActionEdit cursor-pointer m-0" title="Editer" (click)="editParameter(parameter);" data-toggle="modal" data-target="#editParameterModal"></i>
                        <a routerLink="/parameter/{{parameter.code}}/values" title="Liste valeur">
                          <i class="fas fa-list btnActionDescriptions" style="margin: 5px; color:black;" title="Liste valeur"></i>
                        </a>
                        <i class="ft-x ml-1 cursor-pointer ft-delete-2 btnActionDelete" data-descriptif="4" (click)="deleteParameter(parameter)" style="color:red;" title="Supprimer"></i>
                        <i class="fa-light fa-memo-pad cursor-pointer ml-1 btnLog" title="Journal d'actions" (click)="ShowLogs(parameter.id);" data-toggle="modal" data-target="#editLogModal"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div *ngIf="nbParameters> maxItem">
                    <div align="right" class="row BlocPaginate">
                      <div class="pagination ngx-pagination">
                        <ngb-pagination [collectionSize]="nbParameters" (pageChange)="loadNextPage(SearchEngineForm,$event) " [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
                          <ng-template ngbPaginationPrevious>Précédent</ng-template>
                          <ng-template ngbPaginationNext>Suivant</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- Modal parameters -->
<div class="modal fade in editParameterModal" id="editParameterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <app-parameter-details [parameter]="parameter"></app-parameter-details>
</div>
<!-- Modal log -->
<div class="modal fade in editLogModal" id="editLogModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <app-log-list [ElementId]="ElementId" [entityType]="'Parameter'"></app-log-list>
</div>
